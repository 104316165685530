import React from "react";
import ComparePlansHeader from "./ComparePlansHeader";
import ComparePlansContent from "./ComparePlansContent";

export const ComparePlans = () => {

    return (
        <div className="compare-plans">
            <ComparePlansHeader/>
            <>
                <ComparePlansContent/>
            </>
        </div>
    )
}