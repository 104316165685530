import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithProfileIdAndAssetId} from "../../../routes/types";
import AssetsViewContext from "../../common/AssetsViewContext";
import {assetsApiClient} from "../../AssetsApiClient";
import {BankAccountSearchResults} from "./BankingAccountSearchResults";
import {BankingAccountReadModel, BankingAccountWriteModel} from "../../models/BankingAccount";
import {BankingAccountSearch} from "./BankingAccountSearch";
import {useAppSelector} from "../../../store/hooks";
import {
    selectProfile,
    selectResourceCode
} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {ProfileType} from "../../../ClientManagement/models/ProfileResponse";
import {AlertBanner} from "../../../components";
import {EmptyStateContainer} from "../../../components";
import LoadingIndicator from "../../../pages/LoadingIndicator";

export function AddBankingAccount() {
    const history = useHistory();
    const {profileId} = useParams<RouteWithProfileIdAndAssetId>();
    const viewType = useContext(AssetsViewContext);
    const [bankingAccounts, setBankingAccounts] = useState<BankingAccountReadModel[]>([]);
    const [searchedBankAccount, setSearchedBankAccount] = useState<BankingAccountReadModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const componentUnmounted = useRef(false);
    const resourceCode = useAppSelector(selectResourceCode);
    const profile = useAppSelector(selectProfile);
    const isUlekPresent: boolean = resourceCode?.length > 0;
    const [isExternalAccSearchFailed, setIsExternalAccSearchFailed] = useState<boolean>(false);
    const [externalAccountNumber, setExternalAccountNumber] = useState<string>("");

    useEffect(() => {
        return () => {
            componentUnmounted.current = true;
        }
    }, []);

    useEffect(() => {
        let isProfile: boolean = profile?.type === ProfileType.PROFILE;
        if (profileId) {
            assetsApiClient.getBankingAccounts(profileId, resourceCode, isProfile)
                .then(bankingResponse => {
                    setBankingAccounts(bankingResponse);
                    setSearchedBankAccount(bankingResponse);
                })
                .catch(error => {
                    console.error('Could not fetch banking accounts', error.message)
                }).finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }

    }, [profileId]);

    const handleClickOnCancel = () => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}`);
    };

    const handleSearch = async (search: string) => {
        const searchAccount = bankingAccounts?.filter(bankingAccount => bankingAccount.accountNumber === search);
        if (searchAccount && searchAccount.length > 0) {
            setSearchedBankAccount(searchAccount);
        } else {
            const externalBankAccount = await assetsApiClient.getBankingAccountByAccNumber(profileId, search).catch(error => {
                setIsExternalAccSearchFailed(true);
                setExternalAccountNumber("");
            });
            if (externalBankAccount && externalBankAccount.length > 0) {
                setBankingAccounts([...externalBankAccount, ...bankingAccounts]);
                setSearchedBankAccount([...externalBankAccount, ...searchedBankAccount]);
                setIsExternalAccSearchFailed(false);
                setExternalAccountNumber(externalBankAccount[0].accountNumber);
            } else {
                setIsExternalAccSearchFailed(true);
                setExternalAccountNumber("");
            }
        }
    }

    const handleSearchCleared = () => {
        setSearchedBankAccount(bankingAccounts);
        setIsExternalAccSearchFailed(false);
        setExternalAccountNumber("");
    }
    const onSave = (accountsToSave: BankingAccountWriteModel[]) => {
        if (accountsToSave) {
            assetsApiClient.postBankingAccounts(profile.id, accountsToSave)
                .then(response => {
                    if (response.status === 200) {
                        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
                    }
                });
        }
    }

    if (isLoading) {
        return <LoadingIndicator className="banking-accounts-loading-indicator"/>;
    }

    function navigateToProfileSettingsPage() {
        history.push(`/Profile/${profileId}/ClientProfile/ProfileSettings`);
    }

    return (
        <div className="banking-account">
            <DataEntryHeader
                title="Add Northern Trust Bank Account"
                secondaryButtonText="Cancel"
                onSecondaryButtonClick={handleClickOnCancel}
                hidePrimaryButton
            />
            <div className="banking-account-container">
                <div className="search-bar-container marginbottom-lg">
                    <BankingAccountSearch
                        onSearch={handleSearch}
                        onSearchCleared={handleSearchCleared}
                    />
                </div>
                <AlertBanner showAlert={!isUlekPresent} type={"warning"} className={"marginbottom-sm"}>
                    <div aria-label="No Ulek linked" role="info" className="banking-no-ulek-alert">
                            <span>
                                Profile is not linked to Partner Workstation.
                            </span>

                        {profile.type === ProfileType.PROFILE && <span
                            className="banking-no-ulek-alert_hover"
                            onClick={navigateToProfileSettingsPage}>
                                        LINK IN PROFILE SETTINGS</span>}
                    </div>
                </AlertBanner>
                <AlertBanner showAlert={isExternalAccSearchFailed} type={"info"} className={"marginbottom-sm"}
                             icon={"info"}
                             message={"This Northern Trust bank account cannot be integrated to GPS because the account is not valid."}
                />
                {
                    searchedBankAccount && searchedBankAccount.length > 0 &&
                    <BankAccountSearchResults bankingAccounts={searchedBankAccount} onSave={onSave}
                                              externalAccountNumber={externalAccountNumber}/>
                }
                {
                    isUlekPresent && !searchedBankAccount.length && bankingAccounts.length > 0 &&
                    <EmptyStateContainer className="no-bank-accounts" title={'No matching accounts were found.'}
                                         size="large"/>
                }

                <AlertBanner showAlert={isUlekPresent && !bankingAccounts?.length} type={"info"}
                             className={"marginbottom-sm"}
                             icon={"info"}
                             message={"No Northern Trust bank accounts associated with Partner Workstation profile. Search by account number."}
                />
            </div>
        </div>
    );
}