import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../components";
import {formatNumberRoundedToWholeNumber} from "../../utils/format";


export class ExcessAssetsDisplayTableForComparePlans extends TableDisplay<number, TableRow<number>> {
    private readonly excessAssets: number;

    constructor(columnCounter: ColumnCounter, excessAssets: number) {
        super(columnCounter);
        this.excessAssets = excessAssets;
    }

    protected get headerLabel(): string {
        return "Excess Assets";
    }

    protected get headerValues(): TableCell<number>[] {
        return [{
            originalValue: this.excessAssets,
            renderedValue: formatNumberRoundedToWholeNumber(this.excessAssets),
            isBlank: false
        }];
    }

    get rows(): TableRow<number>[] {
        return [{
            accentColor: '',
            children: [],
            label: "",
            uniqueIdentifier: '',
            values: [{
                originalValue: this.excessAssets,
                renderedValue: this.excessAssets?.toString(),
                isBlank: false
            }]
        }];
    }
}