import React, {useEffect, useState} from "react";
import {StackedBarChart} from "../../components";
import {COLOR_GOALS, COLOR_NT_GREY, COLOR_NT_GREY_050, COLOR_NT_ORANGE_800} from "../../constants/colors";
import ChartStatusLine from "./ChartStatusLine";
import ExcessAssetsVerticalLine from "./ExcessAssetsVerticalLine";
import {AssetRelianceAssetType, AssetRelianceBarChartData, AssetRelianceStack,} from "../models/AssetRelianceResponse";
import {Col, Row} from "react-grid-system";
import {getRefWidth, hasPositiveExcessAssets} from "./AssetRelianceUtil";
import _ from "lodash";
import {roundUpToNext, truncateCurrency} from "../../utils/format";
import BarChartLegend, {
    barChartLegend
} from "../../components/Legend/BarChartLegend";
import AssetBarChartColumn from "./BarChart/AssetBarChartColumn";
import AssetBarChartInvestableColumn from "./BarChart/AssetBarChartInvestableColumn";
import {BarchartUnderlay} from "./BarChart/BarChartUnderlay";


type AssetRelianceBarchartProps = {
    allAssetsStack: AssetRelianceStack;
    additionalAssetStacks: AssetRelianceStack[];
    titleCellWidth: number;
    totalGoals: number;
    totalNetAssets: number;
    stackContainerWidth: number;
}

const CHART_WIDTH: number = 96;

const AssetRelianceBarchartContainer: React.FC<AssetRelianceBarchartProps> = ({
                                                                                  allAssetsStack,
                                                                                  additionalAssetStacks = [],
                                                                                  titleCellWidth,
                                                                                  totalGoals,
                                                                                  totalNetAssets,
                                                                                  stackContainerWidth
                                                                              }) => {

    const [charts, setCharts] = useState<AssetRelianceStack[]>([]);
    const [allAssetsColWidth, setAllAssetsColWidth] = useState<number>(0);

    useEffect(() => {
        setCharts([allAssetsStack, ...additionalAssetStacks])

    }, [allAssetsStack.excessAssets, totalGoals]);

    const minimumValueToApplyGraphRounding = 1_000_000;

    const totalStacksCount = additionalAssetStacks.length + 1;
    let barchartMaxValue = totalGoals >= totalNetAssets ? totalGoals : totalNetAssets;
    charts.forEach((stack, index) => {
        let columnMaxValue = AssetBarChartColumn.getColumnMaxValueFromStack(stack);
        if(stack.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
            columnMaxValue = AssetBarChartInvestableColumn.getColumnMaxValueFromStack(stack);
        }
        if(columnMaxValue > barchartMaxValue)
            barchartMaxValue = columnMaxValue;
    });
    const chartUnderlayMaxValue = (barchartMaxValue >= minimumValueToApplyGraphRounding)
        ? roundUpToNext(barchartMaxValue, 5)
        : 1_000_000;

    return (
        <>
            <Row style={{justifyContent: "flex-end"}}>
                <div className="asset-reliance-barchart-legend-label">
                    <BarChartLegend legend={barChartLegend.NET_ASSETS}
                                    label={"ASSETS"}/>
                </div>
                <div className="asset-reliance-barchart-legend-label">
                    <BarChartLegend legend={barChartLegend.GOALS}
                                    label={"GOALS"}/>
                </div>
                <div className="asset-reliance-barchart-legend-label">
                    {hasPositiveExcessAssets(allAssetsStack.excessAssets) ?
                        <BarChartLegend legend={barChartLegend.EXCESS_ASSETS}
                                        label={"EXCESS ASSETS"}/>
                        : <BarChartLegend legend={barChartLegend.ASSET_SHORTFALL}
                                          label={"ASSET SHORTFALL"}/>}

                </div>
            </Row>
            <Row>
                <Col className="asset-reliance-chart-container">
                    <BarchartUnderlay totalStacksCount={totalStacksCount}
                                      width={allAssetsColWidth}
                                      titleCellWidth={titleCellWidth}
                                      stackContainerWidth={stackContainerWidth}
                                      maxValue={chartUnderlayMaxValue}
                                      numberOfColumns={6}
                                      minimumColumn={1}
                                      rightBorderDivider={false}
                    />
                    <Row data-testid="AssetRelianceBarchartRow"
                         className="asset-reliance-barchart-row"
                         style={{height: "100%"}}>
                        <Col width={titleCellWidth} style={{minWidth: titleCellWidth}}/>
                        {charts.map((stack, index) => {

                            const sequenceNumber = index + 1;
                            if (!stack) return <EmptyStackBarchart sequenceNumber={sequenceNumber} key={sequenceNumber}/>;

                            const isAssetSufficient: boolean =  hasPositiveExcessAssets(allAssetsStack.excessAssets);
                            const assetBarChart = stack.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET ?
                                new AssetBarChartInvestableColumn(stack, isAssetSufficient, chartUnderlayMaxValue) :
                                new AssetBarChartColumn(stack, isAssetSufficient, chartUnderlayMaxValue);

                            const columnHeightPercentage = (assetBarChart.getColumnMaxValue() / chartUnderlayMaxValue * 100).toFixed(2);

                            return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                                <Col md={stackContainerWidth}
                                     key={sequenceNumber}
                                     style={{height: `${columnHeightPercentage}%`, alignSelf: "flex-end"}}
                                     ref={(col: Col & HTMLDivElement | null) => {
                                         const width = getRefWidth(col);
                                         setAllAssetsColWidth(width);
                                     }}
                                >
                                    <ChartStatusLine label=''
                                                     width={`${allAssetsColWidth}px`}
                                                     verticalOffset={assetBarChart.getGoalsPositionVerticalOffset()}
                                                     lineColor={COLOR_GOALS}
                                                     marginLeft={0}
                                                     textColor={COLOR_NT_ORANGE_800}
                                                     id={"barChartOverlay"}
                                                     isAssetReliance={true}
                                    />
                                    <div data-testid={"AssetRelianceStackBarchart"} className="barchart-container">
                                        <StackedBarChart
                                            showLabels={false}
                                            chartWidth={CHART_WIDTH}
                                            data={assetBarChart.getChartItems()}
                                        />
                                        <ExcessAssetsVerticalLine color={assetBarChart.getPillColor()}
                                                                  bottomPercentage={assetBarChart.getVerticalLineMargins().bottomMargin}
                                                                  excessAssets={stack.excessAssets}
                                                                  topPercentage={assetBarChart.getVerticalLineMargins().topMargin}
                                        classname={"asset-reliance-asset-line-container"}/>
                                    </div>
                                </Col>
                            </React.Fragment>
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    )
}


type BarchartProps = Omit<AssetRelianceBarChartData, 'totalGoals'> & {
    totalGoals: number;
    totalNetAssets: number;
    sequenceNumber: number;
};

const GRID_COLUMN_OFFSET = 5;

const EmptyStackBarchart: React.FC<Pick<BarchartProps, 'sequenceNumber'>> = ({sequenceNumber}) => {
    return <div
        style={{gridColumnStart: (GRID_COLUMN_OFFSET + sequenceNumber)}}
    >
        <StackedBarChart
            key={sequenceNumber}
            showLabels={false}
            chartWidth={CHART_WIDTH}
            data={[]}
            noDataColor={COLOR_NT_GREY_050}
        />
    </div>;
};


export default AssetRelianceBarchartContainer;