import React, {ReactElement} from "react";
import {TableDisplay, TableRow} from "./TableDisplay";
import {CellClassParameters, TableRowComponent} from "./TableRowComponent";
import {Mapping as ClassNamesMapping} from "classnames";

export type AccordionTableHeaderOnlyProps<ValueType, TR extends TableRow<ValueType> = TableRow<ValueType>> = {
    accordionTableId: string;
    additionalCellClasses?: (params: CellClassParameters) => ClassNamesMapping[];
    collapsedAccentColor: string;
    ariaLabel: string;
    titleCellWidth: number;
    tableDisplayData: TableDisplay<ValueType, TR>;
    onTitleCellMouseEnter?: (row: TR, rowIndex?: number) => void;
    onTitleCellMouseLeave?: (row: TR) => void;
    valueColumnSize?: number;
};

export const AccordionTableHeaderOnly = <ValueType, TR extends TableRow<ValueType>>({
                                                                                        accordionTableId,
                                                                                        additionalCellClasses,
                                                                                        collapsedAccentColor,
                                                                                        ariaLabel,
                                                                                        titleCellWidth,
                                                                                        tableDisplayData,
                                                                                        onTitleCellMouseEnter,
                                                                                        onTitleCellMouseLeave,
                                                                                        valueColumnSize,
                                                                                    }: AccordionTableHeaderOnlyProps<ValueType, TR>) => {
    return (
        <TableRowComponent
            row={{
                accentColor: collapsedAccentColor,
                label: tableDisplayData.header.label,
                subtitle: tableDisplayData.header.subtitle,
                values: tableDisplayData.header.values,
                uniqueIdentifier: 'header-row',
                children: [],
            }}
            additionalCellClasses={additionalCellClasses}
            isSummaryRow={true}
            ariaLabel={ariaLabel}
            isExpanded={false}
            titleCellWidth={titleCellWidth}
            valueColumnSize={valueColumnSize}
        />
    );
};
