import React, {useMemo} from "react";
import {Col, Container, Row} from "react-grid-system";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import {StrategyListHeader} from "./StrategyListHeader";
import {COLOR_ENABLED_WPO_STRATEGY, COLOR_LIABILITIES} from "../../constants/colors";
import {AccordionItemWithActionMenu, DropdownItem, Icon} from "../../components";
import {StrategyAccordionItemHeader} from "./StrategyAccordionItemHeader";
import {TableActionDropdownMenu} from "../../components/Table/TableActionDropdownMenu";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {wealthPOApiClient} from "../WealthPOApiClient";
import {StrategyDetailsSectionInfo, StrategyType} from "../WealthPOTypes";
import {convertStrategyTypeToString} from "../WealthPOUtils";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {useAppSelector} from "../../store/hooks";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

interface StrategyDetailsProps {
    refreshStrategiesSummary: () => void;
    strategyDetailsList: StrategyDetailsSectionInfo[];
    isContinued: boolean
    isInReportPreview: boolean;
}

const StrategyDetailsSection: React.FC<StrategyDetailsProps> = ({
                                                                    refreshStrategiesSummary,
                                                                    strategyDetailsList,
                                                                    isContinued,
                                                                    isInReportPreview
                                                                }: StrategyDetailsProps) => {
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const enableOrDisableStrategy = (strategyDetails: StrategyDetailsSectionInfo) => {
        const requestBody = {
            ...strategyDetails,
            enabled: !strategyDetails.enabled
        }

        wealthPOApiClient.editStrategyEnabledStatus(id, requestBody)
            .then(() => refreshStrategiesSummary());
    }

    const editStrategy = (strategyId: string, strategyType: StrategyType) => {
        if (strategyType === StrategyType.STATE_OF_RESIDENCY) {
            history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/EditStateOfResidency/${strategyId}`);
        } else if (strategyType === StrategyType.ANNUAL_GIFTING) {
            history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/EditAnnualGifting/${strategyId}`);
        } else if (strategyType === StrategyType.GIFT_TO_GRANTOR_TRUST) {
            history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/EditGiftToGrantorTrust/${strategyId}`);
        } else if (strategyType === StrategyType.TWO_YEAR_GRAT) {
            history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/EditTwoYearGRAT/${strategyId}`);
        }
    }

    const deleteStrategy = (type: StrategyType, strategyId: string) => {
        if (type === StrategyType.STATE_OF_RESIDENCY) {
            wealthPOApiClient.deleteStateOfResidencyStrategy(id, strategyId)
                .then(() => refreshStrategiesSummary())
        } else if ([
                StrategyType.ANNUAL_GIFTING,
                StrategyType.GIFT_TO_GRANTOR_TRUST,
                StrategyType.TWO_YEAR_GRAT
            ].includes(type)) {
            wealthPOApiClient.deleteStrategy(id, strategyId)
                .then(() => refreshStrategiesSummary())
        }
    }

    const memoizedStrategyDetailsList = useMemo(() => {
        return strategyDetailsList.map(strategyDetails =>
            <AccordionItemWithActionMenu
                key={strategyDetails.id!}
                uuid={strategyDetails.id!}
                accentColor={strategyDetails.enabled ? COLOR_ENABLED_WPO_STRATEGY : COLOR_LIABILITIES}
                HeaderComponent={() => StrategyAccordionItemHeader(
                    {
                        description: strategyDetails.description,
                        type: convertStrategyTypeToString(strategyDetails.type),
                        amountToBeneficiaries: strategyDetails.type === StrategyType.STATE_OF_RESIDENCY
                            ? "––"
                            : (strategyDetails.amountToBeneficiaries ?? 0),
                        impactToEstEstateTax: strategyDetails.impactToEstEstateTax ?? 0,
                        enabled: strategyDetails.enabled,
                        isInReportPreview: isInReportPreview
                    }
                )}
                expandable={false}
                actionMenu={
                    <div data-testid="strategy-action-menu">
                        <TableActionDropdownMenu
                            ariaLabel={'Strategy Action Menu'}
                        >
                            {releaseToggles!.enableWPOStrategyEnabledStatusChange &&
                                <DropdownItem
                                    itemText={`${strategyDetails.enabled ? "Disable" : "Enable"} Strategy`}
                                    value={`${strategyDetails.enabled ? "Disable" : "Enable"} Strategy`}
                                    onClick={() => enableOrDisableStrategy(strategyDetails)}
                                />
                            }
                            <DropdownItem
                                itemText="Edit Strategy"
                                value="Edit Strategy"
                                onClick={() => editStrategy(strategyDetails.id!, strategyDetails.type)}
                            />
                            <DropdownItem
                                itemText="Delete Strategy"
                                value="Delete Strategy"
                                disabled={isProfileWithProposalsOrArchived}
                                onClick={() => deleteStrategy(strategyDetails.type,
                                    strategyDetails.id!)}
                            >
                                <Icon name="delete" className="delete-trash-icon"
                                      data-testid="delete-trash-icon"/>
                            </DropdownItem>
                        </TableActionDropdownMenu>
                    </div>
                }
            />)
    }, [strategyDetailsList]);

    return (
        <section className={"strategy-summary-details-section"}>
            <Container className={"summary-details-card"}>
                <Row>
                    <Col>
                        <h4>{isContinued
                            ? "Wealth Planning Strategies Sandbox (continued)"
                            : "Wealth Planning Strategies Sandbox"}
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={"placeholder-card"}></div>
                    </Col>
                </Row>
                <Row data-testid='strategy-list' style={{margin: "0px"}}>
                    <Col style={{padding: 0}}>
                        <AccordionWithHeader
                            accordionId={'strategy-list-accordion'}
                            allItems={[]}
                            columnTitles={['Name', 'Strategy', 'Future Value of Gift', 'Impact to  Est. Estate Tax']}
                            renderHeader={true}
                            HeaderComponent={() => <StrategyListHeader/>}
                        >
                            {
                                memoizedStrategyDetailsList
                            }
                        </AccordionWithHeader>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default StrategyDetailsSection;

