import {createAssetBarChartData} from "../AssetSummary/BarChart/AssetBarChartUtils";
import {AssetBarChartFooter} from "../AssetSummary/BarChart/AssetBarChartFooter";
import {BarChartSidebar, EmptyStateContainer} from "../../components";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    InEstateAssetTotals,
    resetClientAssets,
    selectActiveFormAsset,
    selectClientAssets,
    setClientAssets
} from "../clientAssetsSlice";
import {InvestorGroupType, MemberGroup, MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {LegalEntityReadModel} from "../models/Ownership";
import {assetsApiClient} from "../AssetsApiClient";
import AssetSummary from "../AssetSummary/AssetSummary";
import CurrentNetWorth from "../CurrentNetWorth/CurrentNetWorth";
import {NO_OP} from "../../constants/common";
import CustomModal from "../../components/Modal/Custom/CustomModal";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {FamilyRelationshipType} from "../../ClientManagement/models/FamilyRelationshipType";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {SECOND_TO_DIE, SECOND_TO_DIE_ID} from "../models/LifeInsurance";
import {useAppInsights} from "../../AppInsights";
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {FamilyTreeType} from "../../ClientManagement/models/FamilyTreeType";
import {RouteWithId} from "../../routes/types";
import AssetsViewContext from "./AssetsViewContext";
import DeleteInvestmentProgramConfirmationModal from "./DeleteInvestmentProgramConfirmationModal";
import {InvestmentProgram} from "../models/InvestmentProgram";
import {Button, Modal} from "xps-react";
import DataEntrySideDrawer from "src/components/DataEntry/DataEntrySideDrawer";
import AddOptionsAndGrants from "../EquityCompensation/AddOptionsAndGrants";
import LegalAgreementHoldings from "../InvestmentProgram/LegalAgreementHoldings";
import HoldingsRouter from "../StandaloneAccount/HoldingsRouter";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import {GeneralInflow} from "../models/GeneralInflow";
import {mapMemberOwnershipToWriteModel} from "../Ownership/mappers";
import DeletePartiallyOwnedInvestmentAccountConfirmationModal
    from "./DeletePartiallyOwnedInvestmentAccountConfirmationModal";
import PartiallyOwnedInvestmentAccountHoldings
    from "../PartiallyOwnedInvestment/EditPartiallyOwnedInvestment/PartiallyOwnedInvestmentAccountHoldings";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import HeldAwayAccountHoldings from "../InvestmentProgram/HeldAwayAccountHoldings";
import {PartiallyOwnedLegalAgreement} from "../models/PartiallyOwnedInvestmentAccount";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {emptyErrorModalData} from "../../components/Modal/Error/ErrorModal";
import {shortenName} from "./textUtils";
import BankingAccountHoldings from "../BankingAccount/BankingAccountHoldings";
import {BankingAccountsSummary} from "../models/BankingAccounts";


type AssetsViewProps = {
    inEstateTotals: InEstateAssetTotals,
    familyTree?: FamilyTreeType,
    showDeathBenefit?: boolean,
    toggleShowDeathBenefit?: () => void
}

const initialDeleteModalProps = {
    showDeleteModal: false,
    modalTitle: "Asset",
    assetDescription: "",
    assetId: "",
    handleDelete: NO_OP
} as DeleteModalProps;

export const AssetsView = ({
                               inEstateTotals,
                               familyTree,
                               showDeathBenefit = false,
                               toggleShowDeathBenefit,
                           }: AssetsViewProps) => {

    const viewType = useContext(AssetsViewContext);
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const appInsights = useAppInsights();
    const {approvedProfile, profile, isLoading: isLoadingProfile} = useProfileAndProposals(id);

    const isAssetSummary = viewType === "AssetSummary"
    const activeProfileId = profile?.id;
    const displayName = approvedProfile.displayName;

    const clientAssets = useAppSelector(selectClientAssets);
    const activeFormAsset = useAppSelector(selectActiveFormAsset);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [isLoadingAssetView, setIsLoadingAssetView] = useState(true);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const [legalEntities, setLegalEntities] = useState<LegalEntityReadModel[]>();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [openDrawer, updateOpenDrawer] = useState(false);
    const [openHoldings, updateOpenHoldings] = useState(false);
    const [openOptionsAndGrant, setOpenOptionsAndGrant] = useState(false);
    const [deleteModalProps, setDeleteModalProps] = useState<DeleteModalProps>(initialDeleteModalProps);
    const [showLegalEntityDeleteError, setShowLegalEntityDeleteError] = useState<boolean>(false);
    const [openIPConfirmation, setOpenIPConfirmation] = useState(false);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [refreshHoldingsError, setRefreshHoldingsError] = useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false)
    const showStandardDeleteConfirmationModal = deleteModalProps.showDeleteModal && deleteModalProps.assetType !== 'partially owned investment account';
    const showPartiallyOwnedInvestmentDeleteConfirmationModal = deleteModalProps.showDeleteModal && deleteModalProps.assetType === 'partially owned investment account';

    useEffect(() => {
        if (isLoadingProfile) return;

        appInsights.startTrackPage(viewType);
        loadAssets();
    }, [isLoadingProfile, activeProfileId, openDrawer,approvedProfile]);

    useEffect(() => {
        return function cleanup() {
            dispatch(resetClientAssets());
        }
    }, []);

    useEffect(() => {
        const pathArray = history.location.pathname.split("/");
        const isHoldingsPage = pathArray.includes("Holdings")
            || pathArray.includes("LegalAgreementHoldings")
            || pathArray.includes("PartiallyOwnedInvestmentAccountHoldings")
            || pathArray.includes("HeldAwayAccountHoldings");

        updateOpenHoldings(isHoldingsPage);
        if (!isHoldingsPage) {
            const onDifferentPage = !history.location.pathname.endsWith(viewType);
            updateOpenDrawer(onDifferentPage);
        }
        setOpenOptionsAndGrant(pathArray.includes("AddOptionsAndGrants"));
    }, [history.location.pathname]);

    const loadAssets = () => {
        setIsLoadingAssetView(true);
        Promise.all([
            assetsApiClient.getAssetsSummary(activeProfileId),
            clientManagementApiClient.getInvestorGroup(activeProfileId),
            assetsApiClient.getLegalEntities(activeProfileId),
            clientManagementApiClient.getMemberGroup(activeProfileId)
        ])
            .then(([assetsResponse,
                       investorGroupResponse,
                       legalEntitiesResponse,
                       memberGroupResponse]) => {

                if (!releaseToggles?.enableHeldAwayAccountForAsset && assetsResponse?.investmentProgram)
                    assetsResponse.investmentProgram.heldAwayAccounts = [];

                dispatch(setClientAssets(assetsResponse));
                setInvestorGroup(investorGroupResponse);
                setLegalEntities(legalEntitiesResponse);
                setMemberGroup(handleMemberGroupResponse(memberGroupResponse, familyTree));
            })
            .catch(error => {
                openErrorModal(error);
            })
            .finally(() => {
                setIsLoadingAssetView(false);
                appInsights.stopTrackPage(viewType, window.location.href, {
                    profileId: activeProfileId,
                });
            });
    }

    const openErrorModal = (error: Error | any) => {
        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: activeProfileId,
            time: new Date(),
            errorDetail: `Failed to load assets (${error.status})`,
            operationId: error.headers.get('trace-id')
        });
    };

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleOnClickButtonGenericErrorModal = () => {
        closeErrorModal();
        loadAssets();
    };

    const handleOnRequestCloseGenericErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true); //TODO remove this once we determine what needs to be shown if the error modal is closed
    }

    if (genericError.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleOnClickButtonGenericErrorModal}
                onRequestClose={handleOnRequestCloseGenericErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        );
    }

    /*
    TODO remove this and the showTempErrorMessage state variable once a future story defines what should be displayed
    if the error modal is closed by clicking the X button.
    */
    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-assets-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        );
    }

    if ((isLoadingProfile || !clientAssets || !investorGroup || !legalEntities || !memberGroup)) {
        return <LoadingIndicator/>;
    }

    const getLegalAgreementIds = (investmentProgram: InvestmentProgram | null) => {
        return (investmentProgram?.legalAgreements ?? []).map(legalAgreement => legalAgreement.id);
    };

    const clearLegalAgreementCollateral = (legalAgreementIds: string[]) => {
        const personalLiabilities = clientAssets.personalLiabilities
            .map(personalLiability => ({...personalLiability}));

        personalLiabilities.forEach(personalLiability => {
            if (legalAgreementIds.includes(personalLiability.collateralId ?? '')) {
                personalLiability.collateralId = null;
                personalLiability.collateral = null;
            }
        })

        return personalLiabilities;
    };

    const onRemoveInvestmentProgram = async () => {
        const response = await assetsApiClient.deleteInvestmentProgram(activeProfileId)

        if (response.status === 200) {
            const deletedLegalAgreementIds = getLegalAgreementIds(clientAssets.investmentProgram);
            const personalLiabilities = clearLegalAgreementCollateral(deletedLegalAgreementIds);

            dispatch(setClientAssets({...clientAssets, personalLiabilities, investmentProgram: null}));
        }
        setOpenIPConfirmation(false);
    }

    const onRemovePartiallyOwnedInvestmentAccount = async (partiallyOwnedInvestmentAccountId: string) => {
        const response = await assetsApiClient.deletePartiallyOwnedInvestmentAccount(activeProfileId, partiallyOwnedInvestmentAccountId)

        if (response.status === 200) {
            dispatch(setClientAssets({
                ...clientAssets, partiallyOwnedLegalAgreements: clientAssets.partiallyOwnedLegalAgreements.filter(
                    partiallyOwnedInvestmentAccount =>
                        partiallyOwnedInvestmentAccount.id !== partiallyOwnedInvestmentAccountId
                )
            }));
        }
        hideDeleteModal();
    }

    const onRefreshHoldings = async () => {
        const legalAgreementIdsBefore = getLegalAgreementIds(clientAssets.investmentProgram);
        let investmentProgram = null;
        let partiallyOwnedLegalAgreements: PartiallyOwnedLegalAgreement[] = [];
        let bankingAccounts: BankingAccountsSummary[] = [];

        if (refreshHoldingsError.isOpen) {
            setRefreshHoldingsError({...refreshHoldingsError, isOpen: false});
        }

        Promise.all([
            assetsApiClient.refreshHoldings(activeProfileId)
        ]).then(([
                     refreshHoldingsResponse,
                 ]) => {

            if (!releaseToggles?.enableHeldAwayAccountForAsset && refreshHoldingsResponse?.investmentProgramSummaryWithHoldingsResponse)
                refreshHoldingsResponse.investmentProgramSummaryWithHoldingsResponse.heldAwayAccounts = [];

            investmentProgram = refreshHoldingsResponse.investmentProgramSummaryWithHoldingsResponse;
            partiallyOwnedLegalAgreements = refreshHoldingsResponse.partiallyOwnedLegalAgreementWithHoldings;
            bankingAccounts = refreshHoldingsResponse.bankingAccounts || [];

            if (investmentProgram || partiallyOwnedLegalAgreements.length > 0 || bankingAccounts.length > 0) {
                const legalAgreementIdsAfter = getLegalAgreementIds(investmentProgram);
                const deletedLegalAgreementIds = legalAgreementIdsBefore
                    .filter(legalAgreementId => !legalAgreementIdsAfter.includes(legalAgreementId));
                const personalLiabilities = clearLegalAgreementCollateral(deletedLegalAgreementIds);
                dispatch(setClientAssets({
                    ...clientAssets, personalLiabilities,
                    investmentProgram: investmentProgram ? investmentProgram : null,
                    partiallyOwnedLegalAgreements: partiallyOwnedLegalAgreements.length > 0 ? partiallyOwnedLegalAgreements : [],
                    bankingAccounts: bankingAccounts.length > 0 ? bankingAccounts : []
                }));
            } else {
                const deletedLegalAgreementIds = getLegalAgreementIds(clientAssets.investmentProgram);
                const personalLiabilities = clearLegalAgreementCollateral(deletedLegalAgreementIds);
                dispatch(setClientAssets({
                    ...clientAssets,
                    personalLiabilities,
                    investmentProgram: null,
                    partiallyOwnedLegalAgreements: [],
                    bankingAccounts: []
                }));
            }
        }).catch((error) => {
            setRefreshHoldingsError({
                ...emptyErrorModalData,
                isOpen: true,
                header: 'Unable to Refresh Holdings',
                message: (
                    <>
                        <p>Holdings details failed to refresh. Please try again.</p>
                        <p>If the problem persists, contact Technical Support at GPIITsupport@ntrs.com and provide the
                            following details:</p>
                    </>
                ),
                operationId: error.operationId,
                profileId: activeProfileId,
                time: new Date(),
                errorDetail: `Failed to refresh holdings (${error.status})`
            })
            console.error('Could not fetch holdings data', error.message);
        });
    }

    const handleReviewGeneralInflowDisclosures = (generalInflow: GeneralInflow) => {
        const {
            description,
            type,
            trustInflowType,
            memberOwnerships,
            grossAnnualFlow,
            taxRate,
            netAnnualFlow,
            yearsUntilFlow,
            yearsOfFlow,
            startDate,
            endDate,
            isInflowWillFundLifestyleGoal,
            isHighRisk,
            willAdjustWithInflation,
            inflowReserveLength,
            isDisclosureReviewed
        } = generalInflow;

        assetsApiClient.putGeneralInflow({
            id: generalInflow.id,
            profileId: profile.id,
            description,
            type,
            trustInflowType,
            memberOwnerships: memberOwnerships.map(memberOwner => mapMemberOwnershipToWriteModel(memberOwner)),
            grossAnnualFlow,
            taxRate,
            netAnnualFlow,
            yearsUntilFlow,
            yearsOfFlow,
            startDate,
            endDate,
            isInflowWillFundLifestyleGoal,
            isHighRisk,
            willAdjustWithInflation,
            inflowReserveLength,
            isDisclosureReviewed
        }).then(response => {
            if (response.status === 200) {
                const generalInflows = {
                    ...clientAssets.generalInflows,
                    data: clientAssets.generalInflows.data.map(inflow => ({
                        ...inflow
                    }))
                };
                const index = generalInflows.data.findIndex(inflow => inflow.id === generalInflow.id);
                generalInflows.data[index].isDisclosureReviewed = generalInflow.isDisclosureReviewed;

                dispatch(setClientAssets({...clientAssets, generalInflows, investmentProgram: null}));
            }
        });
    };

    const handleArrangeAssets = () => {
        history.push(`/Profile/${activeProfileId}/ClientProfile/AssetSummary/ArrangeAssets`)
    }

    const hideDeleteModal = () => {
        setDeleteModalProps({
            ...deleteModalProps,
            showDeleteModal: false
        });
    }

    async function onDeleteLegalEntity(legalEntityId: string) {
        try {
            await assetsApiClient.deleteLegalEntity(activeProfileId, legalEntityId);
            setLegalEntities(await assetsApiClient.getLegalEntities(activeProfileId));
        } catch (e) {
            setShowLegalEntityDeleteError(true)
        }
    }

    return <article className="asset-summary-page layout-split-left">
        <BarChartSidebar
            data={createAssetBarChartData(inEstateTotals, isAssetSummary ? activeFormAsset : null)}
            noDataText='No assets have been captured yet.'
            displayName={displayName}
            title={isAssetSummary ? 'Your Assets' : 'Current Net Worth'}>
            <AssetBarChartFooter
                clientAssets={clientAssets}
                inEstateAssetsTotals={inEstateTotals}
                activeFormAsset={isAssetSummary ? activeFormAsset : null}
                investorGroup={investorGroup}
                title={isAssetSummary ? 'Total Net Assets' : 'Current Net Worth'}
                showDeathBenefit={showDeathBenefit}
                isAssetSummary={isAssetSummary}
            />
        </BarChartSidebar>
        {isAssetSummary ?
            <AssetSummary
                legalEntities={legalEntities}
                clientAssets={clientAssets}
                investorGroup={investorGroup}
                openDrawer={openDrawer}
                openHoldings={openHoldings}
                isLoading={isLoadingAssetView}
                profileId={profile.id}
                onRemoveAssetClick={setDeleteModalProps!}
                onRemoveInvestmentProgram={() => setOpenIPConfirmation(true)}
                onDeleteLegalEntity={onDeleteLegalEntity}
                onRefreshInvestmentProgramHoldings={onRefreshHoldings}
                onSaveReviewTrustDisclosure={handleReviewGeneralInflowDisclosures}
                memberGroup={memberGroup}
                onArrangeAssets={handleArrangeAssets}
            /> :
            <CurrentNetWorth
                profileId={profile.id}
                clientAssets={clientAssets}
                investorGroup={investorGroup}
                memberGroup={memberGroup}
                openDrawer={openDrawer}
                legalEntities={legalEntities}
                isLoading={isLoadingAssetView}
                showDeathBenefit={showDeathBenefit}
                toggleShowDeathBenefit={toggleShowDeathBenefit}
                onRemoveAssetClick={setDeleteModalProps!}
                onRemoveInvestmentProgram={() => setOpenIPConfirmation(true)}
                onRefreshInvestmentProgramHoldings={onRefreshHoldings}
                onDeleteLegalEntity={onDeleteLegalEntity}
            />
        }

        <CustomModal
            isOpen={showStandardDeleteConfirmationModal}
            title={`Delete this ${deleteModalProps.modalTitle}?`}
            content={
                deleteModalProps.content ??
                <>
                    <div className="font-md">
                        {`The ${shortenName(deleteModalProps.assetDescription, 50)} ${deleteModalProps.assetType?.toLowerCase()} and all associated data will be deleted permanently.`}
                    </div>
                </>
            }
            onClickConfirm={async () => {
                const apiDeleteFn = getApiDeleteFunction(deleteModalProps.assetType!);
                const response = await apiDeleteFn(profile.id, deleteModalProps.assetId);
                const responseStatus = response.status;

                if (responseStatus === 200) {
                    dispatch(setClientAssets(await assetsApiClient.getAssetsSummary(activeProfileId)));
                }
                await wealthManagementApiClient.deleteAssetRelianceStack(profile.id, deleteModalProps.assetId)

                hideDeleteModal();
            }}
            onClickCancel={() => hideDeleteModal()}
            confirmText={`Delete ${deleteModalProps.modalTitle}`}
            cancelText={`Cancel`}
        />

        <Modal
            header="Unable to Delete Entity"
            label="modal-label"
            id={'delete-legal-entity-error'}
            size='small'
            isOpen={showLegalEntityDeleteError}
            showCloseButton={false}
            footer={<Button
                className={'marginright-12'}
                destructive={false}
                kind={'primary'}
                onClick={() => setShowLegalEntityDeleteError(false)}
                size="medium">
                EXIT
            </Button>}>
            <span className={'font-md'}>This entity has linked accounts. To delete this entity, please change asset ownership or delete assets, then try again.</span>
        </Modal>

        <DeleteInvestmentProgramConfirmationModal
            isOpen={openIPConfirmation}
            investmentProgram={clientAssets.investmentProgram}
            onConfirm={onRemoveInvestmentProgram}
            onCancel={() => setOpenIPConfirmation(false)}
        />

        <DeletePartiallyOwnedInvestmentAccountConfirmationModal
            isOpen={showPartiallyOwnedInvestmentDeleteConfirmationModal}
            partiallyOwnedInvestmentAccount={clientAssets.partiallyOwnedLegalAgreements.filter(
                partiallyOwnedAccount => partiallyOwnedAccount.id === deleteModalProps.assetId)[0]}
            onConfirm={onRemovePartiallyOwnedInvestmentAccount}
            onCancel={() => hideDeleteModal()}
        />

        <GenericErrorModal
            errorModalData={refreshHoldingsError}
            onClickButton={onRefreshHoldings}
            onRequestClose={() => setRefreshHoldingsError({...refreshHoldingsError, isOpen: false})}
            buttonText="Retry"
            buttonProps={
                {
                    primary: true,
                    className: 'full-width center-align',
                    iconPosition: 'left',
                    iconName: 'refresh'
                }
            }
            showAlertIcon={false}
        />

        <DataEntrySideDrawer isOpen={openHoldings} size="full" formLayout="split"
                             scrollableRegionId='holdings-side-drawer'>
            <Switch>
                <Route path={`/Profile/:profileId/ClientProfile/${viewType}/StandaloneAccount/:assetId/Holdings`}
                       component={HoldingsRouter}/>
                <Route exact path={`/Profile/:profileId/ClientProfile/${viewType}/LegalAgreementHoldings/:assetId`}
                       component={LegalAgreementHoldings}/>
                <Route exact
                       path={`/Profile/:profileId/ClientProfile/${viewType}/PartiallyOwnedInvestmentAccountHoldings/:assetId`}
                       component={PartiallyOwnedInvestmentAccountHoldings}/>
                <Route exact path={`/Profile/:profileId/ClientProfile/${viewType}/HeldAwayAccountHoldings/:assetId`}
                       component={HeldAwayAccountHoldings}/>
                <Route exact path={`/Profile/:profileId/ClientProfile/${viewType}/BankingAccount/:assetId/Holdings`}
                       component={BankingAccountHoldings}/>
            </Switch>
        </DataEntrySideDrawer>
        <DataEntrySideDrawer size="full" formLayout="split" isOpen={openOptionsAndGrant}>
            <Switch>
                <Route
                    path={`/Profile/:profileId/ClientProfile/${viewType}/EquityCompensation/:assetId/AddOptionsAndGrants`}
                    component={AddOptionsAndGrants}/>
            </Switch>
        </DataEntrySideDrawer>
    </article>
}

export type DeleteModalProps = {
    showDeleteModal: boolean,
    modalTitle: "Asset" | "Liability"
    assetDescription: string,
    assetType?: AssetTypeString,
    assetId: string,
    content?: ReactElement
}

type AssetTypeString =
    'standalone account'
    | 'personal asset'
    | 'social security'
    | 'general inflow'
    | 'liability'
    | 'life insurance'
    | 'equity compensation'
    | 'partially owned investment account'
    | 'banking account'
    | 'held away account';

export function getApiDeleteFunction(assetType: AssetTypeString): (profileId: string, assetId: string) => Promise<Response> {
    if (assetType === 'general inflow') {
        return assetsApiClient.deleteGeneralInflow;
    }

    if (assetType === 'social security') {
        return assetsApiClient.deleteSocialSecurity;
    }

    if (assetType === 'personal asset') {
        return assetsApiClient.deletePersonalAsset;
    }

    if (assetType === 'liability') {
        return assetsApiClient.deletePersonalLiability;
    }

    if (assetType === 'life insurance') {
        return assetsApiClient.deleteLifeInsurance;
    }

    if (assetType === 'equity compensation') {
        return assetsApiClient.deleteEquityCompensation;
    }

    if (assetType === 'banking account') {
        return assetsApiClient.deleteBankingAccount;
    }

    return assetsApiClient.deleteAccount;
}

function handleMemberGroupResponse(memberGroup: MemberGroup, familyTree: FamilyTreeType | undefined) {
    let additionalMembers: MemberGroupMember[] = [...memberGroup.additionalMembers];
    let isSecondToDiePresent = false;
    if ((familyTree !== null && familyTree!.primaryContact !== null && familyTree!.primaryContact.family.length > 0)) {
        isSecondToDiePresent = familyTree!.primaryContact.family.some(member =>
            ((member.type === FamilyRelationshipType.SPOUSE || member.type === FamilyRelationshipType.EX_SPOUSE)
                && (member.fromMember.lifeStatus === LifeStatus.Living)));
    }
    if (isSecondToDiePresent) {
        additionalMembers.push({
            id: SECOND_TO_DIE_ID,
            firstName: SECOND_TO_DIE,
            lastName: "",
            lifeStatus: LifeStatus.Living,
            deceasedDate: null,
            age: 0,
            relationshipType: null
        });
    }
    return {...memberGroup, additionalMembers: [...additionalMembers]};
}