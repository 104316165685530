import {Route, Switch} from "react-router-dom";
import Agenda from "src/Agenda/Agenda";
import AssetSummary from "src/Assets/AssetSummary";
import CurrentVsProposed from "src/ClientManagement/AssetAllocation/CurrentVsProposed";
import ReviseAssetAllocation from "src/ClientManagement/AssetAllocation/ReviseAssetAllocation/index";
import AssetReliance from "src/ClientManagement/AssetReliance/AssetReliance";
import EditAssetReliance from "src/ClientManagement/AssetReliance/Edit/EditAssetReliance";
import {EditableFamilyTree} from "src/ClientManagement/FamilyTree/FamilyTree";
import {QuickSlidesPDF} from "src/components/QuickSlides/QuickSlidesPDF";
import ScrollableContainer from "src/components/ScrollableContainer/ScrollableContainer";
import Goals from "src/Goals/controller/Goals";
import PortfolioReserve from "src/Goals/PortfolioReserve/PortfolioReserve";
import WealthTransferPlanning from "src/WealthTransfer";
import PlanSummary from "src/Plans/PlanSummary/PlanSummary";
import {ComparePlans} from "../../../Plans/CompareScenarios/ComparePlans";
import CreateReport from "src/Reporting/CreateReport/CreateReport";
import EditFamilyTreeReport from "src/Reporting/CreateReport/EditReportPages/EditFamilyTreeReport";
import AdvisorDocuments from "src/Resources/AdvisorDocuments/AdvisorDocuments";
import IntroDeck from "src/Resources/IntroDeck/IntroDeck";
import CurrentNetWorth from "src/Assets/CurrentNetWorth";
import NetWorthOverTime from "../../AssetAllocation/NetWorthOverTime";
import {
    CurrentNetWorthReport
} from "../../../Reporting/DetailedEmailReports/CurrentNetWorthReport/CurrentNetWorthReport";
import ClientMeetingDocuments from "../../../Resources/ClientMeetingDocuments/ClientMeetingDocuments";
import WealthTransferReport from "../../../Reporting/DetailedEmailReports/WealthTransferReport/WealthTransferReport";
import ProfileSettings, {ProfileSettingsType} from "../../ProfileSettings/ProfileSettings";
import React from "react";
import AssetTitling from "src/AssetTitling";
import {EditableNetWorthOverTime} from "../../../Reporting/NetWorthOverTimeReport/EditableNetWorthOverTime";
import WealthPlanOptimizer from "../../../WealthPlanOptimizer";
import {EditableGoalFundingReport} from "../../../Reporting/GoalFundingReport/EditableGoalFundingReport";
import {
    EditableAssetRelianceReport
} from "../../../Reporting/DetailedEmailReports/AssetRelianceReport/EditableAssetRelianceReport";
import EstateFlowChartMain from "../../../EstateFlowchart/index";
import RevocableTrustForm from "../../../EstateFlowchart/RevocableTrustForm";


const Routes = () => {
    return (
        <ScrollableContainer id={'client-profile-scroll-container'}>
            <Switch>
                <Route path={"/Profile/:profileId/ClientProfile/ProfileSettings"} component={ProfileSettings}/>
                <Route path={"/Profile/:proposalId/ClientProfile/ProposalSettings/:profileId"}>
                    <ProfileSettings settingsType={ProfileSettingsType.PROPOSAL}/>
                </Route>
                <Route path="/Profile/:id/ClientProfile/Agenda" component={Agenda}/>
                <Route
                    path="/Profile/:id/ClientProfile/FamilyTree"
                    component={EditableFamilyTree}
                />
                <Route
                    path="/Profile/:id/ClientProfile/AssetSummary"
                    component={AssetSummary}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CurrentNetWorth"
                    component={CurrentNetWorth}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CurrentNetWorthReport"
                    component={CurrentNetWorthReport}
                />
                <Route path="/Profile/:id/ClientProfile/Goals" component={Goals}/>
                <Route
                    path="/Profile/:id/ClientProfile/PlanSummary"
                    component={PlanSummary}
                />
                <Route
                    path="/Profile/:id/ClientProfile/ComparePlans"
                    component={ComparePlans}
                />
                <Route
                    path="/Profile/:id/ClientProfile/AssetReliance"
                    component={AssetReliance}
                />
                <Route
                    path="/Profile/:id/ClientProfile/NetWorthOverTime"
                    component={NetWorthOverTime}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CreateReport/EditNetWorthOverTime"
                    component={EditableNetWorthOverTime}
                />
                <Route
                    path="/Profile/:id/ClientProfile/PortfolioReserve"
                    component={PortfolioReserve}
                />
                <Route
                    path="/Profile/:id/ClientProfile/GDWMIntro"
                    component={IntroDeck}
                />
                <Route
                    path="/Profile/:id/ClientProfile/Resources/:menuTitle/:subMenuLabel/:documentLabel/:currPage"
                    component={AdvisorDocuments}
                />
                <Route
                    path="/Profile/:id/ClientProfile/Resources/:menuTitle/:documentLabel/:currPage"
                    component={ClientMeetingDocuments}
                />
                <Route
                    path="/Profile/:id/ClientProfile/WealthTransferReport"
                    component={WealthTransferReport}
                />
                <Route
                    path="/Profile/:id/ClientProfile/ExternalDocuments"
                    component={() => (
                        <QuickSlidesPDF
                            compId="external-documents"
                            isOpen={true}
                            pdf={`${window.location.protocol}//${window.location.host}/Revise Asset Allocation.pdf`}
                        />
                    )}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CreateReport"
                    component={CreateReport}
                    exact={true}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CreateReport/EditFamilyTree"
                    component={EditFamilyTreeReport}
                />
                <Route
                    path="/Profile/:id/ClientProfile/EditAssetReliance"
                    component={EditAssetReliance}
                />
                <Route
                    path="/Profile/:id/ClientProfile/AssetAllocation/CurrentVsProposed"
                    component={CurrentVsProposed}
                />
                <Route
                    path="/Profile/:id/ClientProfile/AssetAllocation/ReviseAssetAllocation"
                    component={ReviseAssetAllocation}
                />
                <Route
                    path="/Profile/:id/ClientProfile/WealthTransferPlanning"
                    component={WealthTransferPlanning}
                />
                <Route
                    path="/Profile/:id/ClientProfile/WealthPlanOptimizer"
                    component={WealthPlanOptimizer}
                />
                <Route
                    path="/Profile/:id/ClientProfile/AssetTitling"
                    component={AssetTitling}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CreateReport/EditGoalFunding"
                    component={EditableGoalFundingReport}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CreateReport/EditAssetRelianceWithFutureValue"
                    component={EditableAssetRelianceReport}
                />
                <Route
                    path="/Profile/:id/ClientProfile/CreateReport/EditAssetRelianceDetailedWithFutureValue"
                    component={EditableAssetRelianceReport}
                />
                <Route
                    path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust"
                    component={RevocableTrustForm}
                />
                <Route
                    path="/Profile/:id/ClientProfile/EstateFlowchart"
                    component={EstateFlowChartMain}
                />
            </Switch>
        </ScrollableContainer>
    );
}

export default Routes;

