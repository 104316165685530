import React, {useCallback, useEffect, useMemo, useState} from "react";
import HeaderTickerTabs from "./HeaderTickerTabs";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {meetingApiClient} from "../Meeting/MeetingApiClient";
import {selectActiveMeeting, selectShowMeetingControls, setActiveMeeting} from "../Meeting/meetingSlice";
import {links} from "./ClientProfileLinks";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Button, Dropdown, DropdownItem} from "../../components";
import {selectReleaseToggles} from "src/ReleaseToggles/releaseTogglesSlice";
import {selectAdviceLibrarySubMenus, selectPracticeDocumentsSubMenus} from "../../Resources/menuStructureSlice";
import {resetAccordionPreferencesState} from "../../Assets/common/accordionPreferencesSlice";
import {resetShowOutOfEstateAssets} from "../../Assets/common/OutOfEstate/OutOfEstateAssetsSlice";
import {selectClientDocuments, selectMyDocuments} from "../../Resources/clientMeetingDocumentSlice";
import {
    setSelectedReportType
} from "../../Reporting/CreateReport/ReportingSlice";

const ClientProfileHeader: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const {profile, proposals, approvedProfile, isLoading} = useProfileAndProposals(id);
    const meeting = useAppSelector(selectActiveMeeting)!;
    const showMeetingControls = useAppSelector(selectShowMeetingControls)!;
    const [isResourcePage, setIsResourcePage] = useState(false);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const adviceLibrarySubMenus = useAppSelector(selectAdviceLibrarySubMenus);
    const practiceDocumentsSubMenus = useAppSelector(selectPracticeDocumentsSubMenus);
    const clientDocumentsSubMenus = useAppSelector(selectClientDocuments);
    const myDocumentsSubMenus = useAppSelector(selectMyDocuments);


    const [lastVisitedNonResourceUrl, setLastVisitedNonResourceUrl] = useState<{ name: string, url: string }>({
        name: '',
        url: ''
    });

    const navigationLinks = useMemo(() => links(id, profile, adviceLibrarySubMenus, practiceDocumentsSubMenus, clientDocumentsSubMenus, myDocumentsSubMenus, releaseToggles!), [id, profile, adviceLibrarySubMenus, practiceDocumentsSubMenus, clientDocumentsSubMenus, releaseToggles]);

    useEffect(() => {
        if (history.location.pathname && (history.location.pathname.indexOf('AdviceLibrary') === -1
            && history.location.pathname.indexOf('PracticeDocuments') === -1
            && history.location.pathname.indexOf('ClientDocuments') === -1
            && history.location.pathname.indexOf('MyDocuments') === -1)) {
            setLastVisitedNonResourceUrl({...lastVisitedNonResourceUrl, url: window.location.pathname});
            setIsResourcePage(false);
        } else {
            if (lastVisitedNonResourceUrl.url && !isResourcePage) {
                const prevPageTitle = findPageTitle(navigationLinks);
                setLastVisitedNonResourceUrl({...lastVisitedNonResourceUrl, name: prevPageTitle?.name || ''});
            }

            setIsResourcePage(true);
        }
    }, [window.location.pathname]);

    const findPageTitle = useCallback((val: Array<any>): { id: string, name: string, path: string } => {
        return val.reduce((acc, currentVal) => {
            if (acc) return acc;
            if (currentVal.children) {
                return findPageTitle(currentVal.children)
            }
            return currentVal.path === lastVisitedNonResourceUrl.url ? currentVal : null;
        }, null);
    }, [lastVisitedNonResourceUrl])


    const loadSelectedProposal = (currentProfileId: string, selectedId: string) => {
        if (currentProfileId === selectedId) {
            return;
        }
        const isApprovedProfileSelected = approvedProfile.id === selectedId;
        let path = history.location.pathname.replace(currentProfileId, selectedId);
        if (isApprovedProfileSelected && path.includes('/ProposalSettings/')) {
            path = path.replace(/\/ProposalSettings\/.*/, '/ProfileSettings');
        } else if (!isApprovedProfileSelected && path.includes('/ProfileSettings')) {
            path = path.replace(/\/ProfileSettings/, `/ProposalSettings/${approvedProfile.id}`);
        }

        const pathReplacements = [
            {old: '/ClientProfile/AssetSummary/', new: '/ClientProfile/AssetSummary'},
            {old: '/ClientProfile/CurrentNetWorth/', new: '/ClientProfile/CurrentNetWorth'},
            {old: '/LegalAgreementHoldings/', new: ''}
        ];

        pathReplacements.forEach(replacement => {
            if (path.includes(replacement.old)) {
                path = path.replace(new RegExp(replacement.old + '.*'), replacement.new);
            }
        });

        history.push(path);
    }

    const handleSelect = (currentProfileId: string, selectedId: string) => {
        if (showMeetingControls) {
            meetingApiClient.updateMeeting({...meeting, profileIdToPresent: selectedId})
                .then(updatedMeeting => {
                    setActiveMeeting(updatedMeeting);
                    loadSelectedProposal(currentProfileId, selectedId);
                })
                .catch(error => {
                    console.error('Could not update meeting', error.message);
                });
        } else {
            loadSelectedProposal(currentProfileId, selectedId);
        }

        dispatch(resetAccordionPreferencesState(selectedId));
        dispatch(resetShowOutOfEstateAssets())
        dispatch(setSelectedReportType("Annual Report"));
    }

    const navigateNonAdvisePage = () => {
        if (lastVisitedNonResourceUrl?.url) {
            history.push({pathname: lastVisitedNonResourceUrl.url});
        } else {
            history.push({pathname: `/Profile/${id}/ClientProfile/FamilyTree`});
        }
    }

    return (
        <div className="clientProfileHeader">
            <Dropdown value={!isLoading ? profile.id : ''}
                       className="clientProfileDropdown"
                       aria-labelledby={"select-proposal-label"}
                       onChange={(e: any) => handleSelect(profile.id, e.value)}
                       label={approvedProfile.displayName}
                       inlineLabel
                       labelWidth="250px"
                       panelHeight="auto"
                       panelWidth="260px"
                       size='medium'>
                <DropdownItem className={'fontweight-500'} key={approvedProfile.id} value={approvedProfile.id}
                              itemText={approvedProfile.displayName}
                />
                {proposals.map(proposal => <DropdownItem key={proposal.id} value={proposal.id}
                                                         itemText={proposal.displayName}
                                                         style={{paddingLeft: '35px'}}
                />)}
            </Dropdown>
            <div className="clientProfileHeader__right-aligned-content">
                <HeaderTickerTabs profileId={profile.id} isResourcePage={isResourcePage}/>
                {isResourcePage &&
                    <>
                        <span className="ticker-tab-spacer"/>
                        <div className="clientProfileHeader__previous-visited-section">
                            {lastVisitedNonResourceUrl.name && <div className={"paddingleft-lg"}>
                                <p className="clientProfileHeader__previous-visited-title ">PREVIOUS SCREEN</p>
                                <p className="clientProfileHeader__previous-visited-value">
                                    {lastVisitedNonResourceUrl.name}
                                </p>
                            </div>}
                            <Button
                                aria-label="Close File"
                                className="clientProfileButton marginleft-lg"
                                icon="none"
                                iconName="settings"
                                includeRef={false}
                                kind="primary"
                                onClick={navigateNonAdvisePage}
                                size="small"
                                tabIndex={0}
                                type="button"
                            >
                                Close File
                            </Button>
                        </div>
                    </>}


            </div>

        </div>);
}

export default ClientProfileHeader;
