import React, {useEffect, useRef, useState} from 'react';
import {RouteWithProfileAndProposalId} from '../../routes/types';
import {useHistory, useParams} from 'react-router-dom';
import {clientManagementApiClient} from '../ClientManagementApiClient';
import {ProfileRequestValidation, ProfileResponse, validateProfileResponse} from '../models/ProfileResponse';
import {Col, Radio, Row, Table} from "xps-react";
import {
    AlertBanner,
    AlertMessage,
    Button,
    Dropdown,
    DropdownItem,
    Icon,
    Name,
    Tab,
    TabBar,
    TabPanel,
    TabsProvider
} from "../../components";
import {calculateTotalTax, EffectiveTaxRates} from "../NewClientProfile/EffectiveTaxRates";
import {EffectiveFees} from "../NewClientProfile/EffectiveFees";
import {EffectiveTaxRateType} from "../models/EffectiveTaxRateType";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {FinancialModelSelection} from "../models/FinancialModelSelection";
import {referenceDataClient} from "../../core/ReferenceDataClient";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {PrimaryContactInformation} from './PrimaryContactInformation';
import {PrimaryContact} from '../models/PrimaryContact';
import {msalUtils} from "../../MsalUtils";
import {ServiceTeamMember} from "../models/ServiceTeam";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Partner, PartnerResource, SearchPartnerResults} from "../models/PartnerResource";
import {
    clearPartnerWorkstationState,
    selectLinkedUlek,
    setLinkedClientName,
    setLinkedUlek
} from "../PartnerWorkstation/partnerWorkstationSlice";
import {selectProfile, setProfile} from "../ClientProfile/activeProfileSlice";
import {partnerApiClient} from "../PartnerApiClient";
import {caseInsensitiveEquals} from "../../utils/stringUtils";
import {PARTNER_DASHBOARD_PAGE_SIZES} from "../PartnerDashboard/partnerDashboardConstants";
import {getEmptyProfileSettingsForm, selectProfileSettingsForm, setProfileSettingsForm} from "./ProfileSettingsSlice";
import {wealthManagementApiClient} from "../WealthManagementApiClient";
import NewClientFinancialModel from "../NewClientProfile/NewClientFinancialModel";
import ErrorModal, {emptyErrorModalData, ErrorModalData} from "../../components/Modal/Error/ErrorModal";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {LinkUnlinkPWClientRequest} from "../../Resources/models/ClientDocuments";
import {selectOldServiceTeam, setOldServiceTeam} from "../../Resources/clientMeetingDocumentSlice";
import {resourcesApiClient} from "../../Resources/ResourcesApiClient";
import {TypeaheadSearchBar} from "../../components/TypeaheadSearchBar";
import {NO_OP} from "../../constants/common";
import classNames from "classnames";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import CustomModal from "../../components/Modal/Custom/CustomModal";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import {DropdownOnChangeData} from "../../components/Dropdown/Dropdown";
import {AssetsSummary} from "../../Assets/models/Assets";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {setClientAssets} from "../../Assets/clientAssetsSlice";
import {Location} from "history";

export const enum ProfileSettingsType {
    // TODO NEW_PROFILE
    PROFILE = 'PROFILE',
    PROPOSAL = 'PROPOSAL',
    NEW_PROPOSAL = 'NEW_PROPOSAL',
}

type ProfileSettingsProps = {
    settingsType?: ProfileSettingsType
}

type ProfileSettingsContentProps = {
    showServiceTeamTab: boolean;
    generalSettings: JSX.Element;
    serviceTeamSettings: JSX.Element;
}

export const ProfileSettingsContent: React.FC<ProfileSettingsContentProps> = ({
                                                                                  showServiceTeamTab,
                                                                                  generalSettings,
                                                                                  serviceTeamSettings
                                                                              }) => {
    return showServiceTeamTab
        ? <TabsProvider tabIdArray={['details', 'serviceTeam']}>
            <TabBar size="medium">
                <Tab name="General"/>
                <Tab name="Service Team"/>
            </TabBar>
            <TabPanel className={'row-margin'}>
                {generalSettings}
                {serviceTeamSettings}
            </TabPanel>
        </TabsProvider>
        : generalSettings
}

const ProfileSettings: React.FC<ProfileSettingsProps> = ({settingsType = ProfileSettingsType.PROFILE}) => {
    const history = useHistory();
    const {profileId, proposalId} = useParams<RouteWithProfileAndProposalId>();
    const msal = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [primaryContact, updatePrimaryContact] = useState({} as PrimaryContact);
    const [referenceData, updateReferenceData] = useState({} as ReferenceDataType);
    const [showErrorBanner, updateShowErrorBanner] = useState(false);
    const [saveHasBeenClicked, updateSaveHasBeenClicked] = useState(false);
    const [profileOwner, updateProfileOwner] = useState('');
    const [accountName, updateAccountName] = useState('');
    const [serviceTeam, updateServiceTeam] = useState([] as ServiceTeamMember[]);
    const [displayNameInteractions, updateDisplayNameInteractions] = useState(false);
    const [clientProfileValidation, updateClientProfileValidation] = useState({
        displayName: null,
        primaryContact: null
    } as ProfileRequestValidation)
    const clientProfile = useAppSelector(selectProfile);
    const linkedUlek = useAppSelector(selectLinkedUlek)!;
    const profileSettingsForm = useAppSelector(selectProfileSettingsForm);
    const displayName = profileSettingsForm.displayName;
    const dispatch = useAppDispatch();
    const [hasProposal, updateHasProposal] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [proposalError, setProposalError] = React.useState<ErrorModalData>(emptyErrorModalData);
    const {isArchiveReadOnly} = useProfileEditableState();
    const oldServiceTeam = useAppSelector(selectOldServiceTeam);
    const [accessData, setAccessData] = useState({} as AssetsSummary);
    const releaseToggles = useAppSelector(selectReleaseToggles)

    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const [navLocation, setNavLocation] = useState<Location | null>(null);
    const unblockRef = useRef()
    const [initialProfileType, setInitialProfileType] = useState(clientProfile.prospect);

    const isProfileTypeChanged = !initialProfileType == clientProfile.prospect;

    useEffect(()=>{
        const blocker = (newLocation: Location) => {
            // @ts-ignore
            const forceNavigate = newLocation.state?.forceNavigate ? newLocation.state.forceNavigate : false

            if(isProfileTypeChanged && !forceNavigate){
                setNavLocation(newLocation);
                setShowDiscardChangesModal(true);
                return false;
            }
            return true
        };
        unblockRef.current = history.block(blocker);

        return()=>{
            if(unblockRef.current) {
                // @ts-ignore -- this ref is callable
                unblockRef.current();
            }
        };
    },[isProfileTypeChanged, history]);

    const handleDiscardChanges = ()=> {
        setShowDiscardChangesModal(false)
        if(navLocation){
            history.push(navLocation.pathname,{forceNavigate: true})
        }
    }

    useEffect(() => {
        checkFormIsValid();
    }, [displayName, primaryContact]);

    useEffect(() => {
        clientManagementApiClient.getProfile(settingsType === ProfileSettingsType.PROPOSAL ? proposalId : profileId)
            .then((profile: ProfileResponse) => {
                if (clientProfile.resourceCode) {
                    profile.resourceCode = clientProfile.resourceCode;
                }
                dispatch(setProfile(profile));
                dispatch(setProfileSettingsForm({
                    ...profileSettingsForm,
                    displayName: profile.displayName,
                    effectiveTaxRate: {
                        ...profile.effectiveTaxRate,
                        total: calculateTotalTax(profile.effectiveTaxRate.federal, profile.effectiveTaxRate.stateAndLocal)
                    },
                    allocationType: profile.allocationType,
                    accreditedStatus: profile.accreditedStatus,
                    managementFees: profile.managementFees,
                }));
                updateProfileOwner(profile.owner);
                updatePrimaryContact(profile.primaryContact);
            }).catch(error => {
            console.error('Could not fetch profile/proposal', error.message);
        })
        referenceDataClient.getReferenceData()
            .then((data: ReferenceDataType) => updateReferenceData(data))
        assetsApiClient.getAssetsSummary(profileId).then((assetResponse)=>{
            dispatch(setClientAssets(assetResponse));
            setAccessData(assetResponse) })
        clientManagementApiClient.hasProposals(profileId).then((hasProposals) => {
            updateHasProposal(hasProposals);
        }).catch(error => console.log('Could not fetch proposals', error.message))
    }, [profileId, proposalId]);

    useEffect(() => {
        if (clientProfile.resourceCode) {
            partnerApiClient.getServiceTeam(clientProfile.resourceCode)
                .then((team: ServiceTeamMember[]) => {
                    updateServiceTeam(team);
                });
        } else {
            updateServiceTeam([{
                partnerCode: msalUtils.getLanId(msal),
                partnerName: msalUtils.getAccountName(msal),
                phoneNumber: msalUtils.getAccountPhoneNumber(msal),
                email: msalUtils.getAccountEmail(msal),
                title: msalUtils.getAccountTitle(msal)
            }]);
        }
    }, [clientProfile]);

    useEffect(() => {
        updateAccountName(msalUtils.getAccountName(msal)!);
    }, []);

    useEffect(() => {
        if (clientProfile?.resourceCode !== '') {
            partnerApiClient.getPartnerResources({
                resourceCode: clientProfile.resourceCode,
                partnerCode: msalUtils.getLanId(msal)
            })
                .then((linkedPartnerResource: PartnerResource[]) => {
                    if (linkedPartnerResource !== undefined) {
                        setLinkedPartnerWorkstationClient(linkedPartnerResource[0]);
                    }
                }).catch(error => {
                    console.error('Could not fetch partner resources', error.message);
                }
            );
        }
    }, [clientProfile?.resourceCode]);

    useEffect(() => {
        if (linkedUlek !== "" && linkedUlek !== null && serviceTeam.length > 1 && oldServiceTeam.resourceCode === undefined) {
            const partners: string[] = [];
            serviceTeam?.forEach(member => {
                partners.push(member.partnerCode);
            });

            dispatch(setOldServiceTeam({
                resourceCode: linkedUlek,
                partners
            }));
        }
    }, [linkedUlek, serviceTeam]);



    const checkFormIsValid = () => {
        const profileValidation = validateProfileResponse({displayName, primaryContact} as ProfileResponse)
        updateClientProfileValidation(profileValidation);
        const isValid = Object.values(profileValidation).every(x => x === null);
        updateShowErrorBanner(!isValid);
        return isValid;
    }

    const handleSaveProfile = () => {
        return clientManagementApiClient.putProfile({
            ...clientProfile,
            displayName: displayName,
            primaryContact: primaryContact,
            effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
            managementFees: profileSettingsForm.managementFees,
            accreditedStatus: profileSettingsForm.accreditedStatus,
            allocationType: profileSettingsForm.allocationType,
            lastModifiedByName: accountName,
            resourceCode: linkedUlek,
            owner: profileOwner
        }).catch(error => {
            console.error('Could not save profile', error.message)
        });
    };

    const handleSaveProspect = () => {
        return clientManagementApiClient.putProspect({
            profile: {
                ...clientProfile,
                displayName: displayName,
                primaryContact: primaryContact,
                effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
                managementFees: profileSettingsForm.managementFees,
                accreditedStatus: profileSettingsForm.accreditedStatus,
                allocationType: profileSettingsForm.allocationType,
                lastModifiedByName: accountName,
                resourceCode: linkedUlek,
                owner: profileOwner
            },
            serviceTeamMembers: serviceTeam,
        }).catch(error => {
            console.error('Could not save prospect', error.message)
        });
    };

    const handleSaveSample = () => {
        return clientManagementApiClient.putSample({
            profile: {
                ...clientProfile,
                displayName: displayName,
                primaryContact: primaryContact,
                effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
                managementFees: profileSettingsForm.managementFees,
                accreditedStatus: profileSettingsForm.accreditedStatus,
                allocationType: profileSettingsForm.allocationType,
                lastModifiedByName: accountName,
                resourceCode: linkedUlek,
                owner: profileOwner
            },
            serviceTeamMembers: serviceTeam,
        }).catch(error => {
            console.error('Could not save prospect', error.message)
        });
    };

    const handleCreateNewProposal = async (): Promise<boolean> => {
        setSaveButtonDisabled(true);
        const response = await wealthManagementApiClient.postProposal(profileId, {
            proposalName: profileSettingsForm.displayName,
            createdByName: accountName,
            effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
            accreditedStatus: profileSettingsForm.accreditedStatus,
            allocationType: profileSettingsForm.allocationType,
            managementFees: profileSettingsForm.managementFees,
        })

        if (!response.isSuccessStatusCode) {
            setProposalError({
                isOpen: true,
                header: "Unable to save a new proposal",
                message: "If this error remains after retrying to save, please contact the help desk",
                operationId: response.traceId
            });
            console.error('Could not create proposal');
        }
        return response.isSuccessStatusCode;
    };

    const handleSaveProposal = () => {
        return clientManagementApiClient.putProposal(profileId, proposalId, {
            id: proposalId,
            proposalName: profileSettingsForm.displayName,
            createdByName: accountName,
            effectiveTaxRate: profileSettingsForm.effectiveTaxRate,
            accreditedStatus: profileSettingsForm.accreditedStatus,
            allocationType: profileSettingsForm.allocationType,
            managementFees: profileSettingsForm.managementFees,
        }).catch(error => {
            console.error('Could not save proposal', error.message)
        });
    };

    function handleLinkUnLinkPWClient() {
        if (settingsType === ProfileSettingsType.PROFILE) {

            let linkUnlinkPWClientRequest: LinkUnlinkPWClientRequest = {}
            const isResourceCodeExist = (oldServiceTeam.resourceCode !== undefined && linkedUlek !== "" && linkedUlek !== null);
            const isResourceCodeUpdated = (isResourceCodeExist && oldServiceTeam.resourceCode !== linkedUlek);
            const isResourceCodeAdded = (isResourceCodeExist && oldServiceTeam.resourceCode === linkedUlek);
            const isResourceCodeDeleted = (oldServiceTeam.resourceCode !== undefined && (linkedUlek === "" || linkedUlek === null));

            if (isResourceCodeUpdated) {
                const addedPartners: string[] = [];
                serviceTeam?.forEach(member => {
                    addedPartners.push(member.partnerCode);
                });

                linkUnlinkPWClientRequest = {
                    resourceCode: oldServiceTeam.resourceCode!,
                    removedPartners: oldServiceTeam.partners,
                    survivedPartyResourceCode: linkedUlek,
                    addedPartners,
                    deleteFlag: 'Y'
                }
            } else if (isResourceCodeAdded) {
                linkUnlinkPWClientRequest = {
                    resourceCode: oldServiceTeam.resourceCode!,
                    addedPartners: oldServiceTeam.partners,
                    deleteFlag: 'N'
                }
            } else if (isResourceCodeDeleted) {
                linkUnlinkPWClientRequest = {
                    resourceCode: oldServiceTeam.resourceCode!,
                    removedPartners: oldServiceTeam.partners,
                    deleteFlag: 'Y'
                }
            }
            if (linkUnlinkPWClientRequest.resourceCode !== undefined) {
                resourcesApiClient.linkUnlinkPWClient(profileId, linkUnlinkPWClientRequest)
                    .catch(error => {
                        console.error('Could not save proposal', error.message)
                    });
            }

        }

    }

    const handleSaveButton = async () => {
        if (checkFormIsValid()) {
            let handleSave;
            switch (settingsType) {
                case ProfileSettingsType.NEW_PROPOSAL :
                    handleSave = handleCreateNewProposal;
                    break;
                case ProfileSettingsType.PROFILE :
                    if(clientProfile.prospect)
                        handleSave = handleSaveProspect;
                    else if(clientProfile.sample)
                        handleSave = handleSaveSample;
                    else
                        handleSave = handleSaveProfile;
                    break;
                case ProfileSettingsType.PROPOSAL :
                    handleSave = handleSaveProposal;
                    break;
                default :
                    console.error("Unknown ProfileSettingsType : ", settingsType);
                    return;
            }

            handleSave().then((data) => {
                if (data) {
                    setInitialProfileType(clientProfile.prospect)
                    handleLinkUnLinkPWClient();
                    resetFormAndLinkedClient();
                    if (history.location.pathname.includes('ClientProfile')) {
                        if (typeof data !== 'boolean') {
                            dispatch(setProfile(data as ProfileResponse))
                        }
                        history.go(0)
                    } else {
                        history.push({
                            pathname: `/Profile/${profileId}`
                        })
                    }
                }
            });
        } else {
            updateShowErrorBanner(true);
            updateSaveHasBeenClicked(true);
        }
    }

    const resetFormAndLinkedClient = () => {
        dispatch(setProfileSettingsForm(getEmptyProfileSettingsForm()));
        dispatch(clearPartnerWorkstationState());
    }

    const setLinkedPartnerWorkstationClient = (partnerResource: PartnerResource) => {
        dispatch(setLinkedUlek(partnerResource.resourceCode));
        dispatch(setLinkedClientName(partnerResource.entityName));
    }

    const generalSettings = <GeneralTab displayName={displayName}
                                        displayNameInteractions={displayNameInteractions}
                                        updateDisplayNameInteractions={updateDisplayNameInteractions}
                                        settingsType={settingsType}
                                        clientProfile={clientProfile}
                                        clientProfileValidation={clientProfileValidation}
                                        saveHasBeenClicked={saveHasBeenClicked}
                                        primaryContact={primaryContact}
                                        referenceData={referenceData}
                                        isProfileWithProposalsReadOnly={settingsType === ProfileSettingsType.PROFILE && hasProposal}
                                        isArchiveReadOnly={isArchiveReadOnly}
                                        accessData={accessData}/>;

    const serviceTeamSettings = <ServiceTeamTab serviceTeam={serviceTeam}
                                                savedProfileOwner={clientProfile.owner}
                                                profileOwnerFormState={profileOwner}
                                                updateProfileOwnerFormState={updateProfileOwner}
                                                userLanID={msalUtils.getLanId(msal)}
                                                hasLinkedUlek={!!linkedUlek}
                                                disabled={!!linkedUlek && (settingsType === ProfileSettingsType.PROPOSAL || clientProfile.archived)}
                                                isProspect={clientProfile.prospect}
                                                isSample={clientProfile.sample}
                                                isProposal={settingsType === ProfileSettingsType.PROPOSAL}
                                                onPartnerSelect={(clientProfile.prospect || clientProfile.sample)
                                                    ? (partner: Partner) => {
                                                        updateServiceTeam([...serviceTeam, partner])
                                                    }
                                                    : NO_OP}
                                                onRemoveMember={
                                                    (clientProfile.prospect || clientProfile.sample)
                                                        ? (partnerCode) => {
                                                            updateServiceTeam(serviceTeam.filter((member) => member.partnerCode !== partnerCode))
                                                        }
                                                        : NO_OP
                                                }
    />;


    const handleProposalRetry = async () => {
        setProposalError({...proposalError, isOpen: false});
        await handleSaveButton();
    }

    const handleProposalCancel = () => {
        setProposalError({...proposalError, isOpen: false});
        setSaveButtonDisabled(false);
    }
    return (
        <div>
            { releaseToggles?.enableProfileSettingsDropdown  &&
                <CustomModal
                    isOpen={showDiscardChangesModal}
                    title={'Discard Changes?'}
                    content={'If you leave this page, any changes you have made will not be saved'}
                    onClickCancel={() => setShowDiscardChangesModal(false)}
                    onClickConfirm={handleDiscardChanges}
                    cancelText={'KEEP EDITING'}
                    confirmText={'DISCARD CHANGES'}
                />
            }
            {!isAuthenticated && msalUtils.handleLogin(msal)}
            {isAuthenticated && <div className="page-container">
                <div className="profile-settings">
                    <ProfileSettingsHeader clientProfile={clientProfile}
                                           settingsType={settingsType}
                                           hasErrors={showErrorBanner && saveHasBeenClicked}
                                           onSave={handleSaveButton}
                                           onCancel={() => {
                                               resetFormAndLinkedClient();
                                               history.goBack()
                                           }}
                                           saveButtonDisabled={saveButtonDisabled}
                                           showCancelButton={!history.location.pathname.includes('ClientProfile')}
                    />
                    <ProfileSettingsContent showServiceTeamTab={settingsType !== ProfileSettingsType.NEW_PROPOSAL}
                                            generalSettings={generalSettings}
                                            serviceTeamSettings={serviceTeamSettings}

                    />
                    <ErrorModal
                        errorModalData={proposalError}
                        onClickRetry={handleProposalRetry}
                        onClickCancel={handleProposalCancel}
                    />
                </div>
            </div>
            }
        </div>
    );
}

type ProfileSettingsHeaderProps = {
    clientProfile: ProfileResponse,
    settingsType: ProfileSettingsType,
    onCancel: () => void,
    onSave: () => void,
    hasErrors: boolean,
    saveButtonDisabled: boolean
    showCancelButton: boolean
}

const getProfileSettingsHeaderText = (settingsType: ProfileSettingsType, clientProfile: ProfileResponse) => {
    switch (settingsType) {
        case ProfileSettingsType.NEW_PROPOSAL:
            return 'New Proposal';
        case ProfileSettingsType.PROPOSAL:
            return `Proposal Settings - ${clientProfile?.displayName}`;
        case ProfileSettingsType.PROFILE:
            return `Client Profile Settings - ${clientProfile?.displayName}`;
    }
}

const ProfileSettingsHeader: React.FC<ProfileSettingsHeaderProps> = ({
                                                                         clientProfile,
                                                                         settingsType,
                                                                         onCancel,
                                                                         onSave,
                                                                         hasErrors,
                                                                         saveButtonDisabled,
                                                                         showCancelButton
                                                                     }) => {
    const title = getProfileSettingsHeaderText(settingsType, clientProfile);
    return (
        <div className="row-container">
            <Row>
                <span className="font-xxl title">{title}</span>
                <Col>
                    <div className="textalign-right">
                        {showCancelButton && <Button className="marginright-md"
                                                     icon="none"
                                                     id="cancel_new_client_profile_button"
                                                     includeRef={false}
                                                     kind="secondary"
                                                     onClick={onCancel}
                                                     size="medium"
                                                     tabIndex={0}
                                                     type="button"
                        >
                            CANCEL
                        </Button>}
                        <Button
                            icon="none"
                            id="save_new_client_profile_button"
                            includeRef={false}
                            kind="primary"
                            onClick={onSave}
                            size="medium"
                            tabIndex={0}
                            type="button"
                            disabled={saveButtonDisabled}
                        >
                            SAVE
                        </Button>
                    </div>
                </Col>
                {hasErrors && (
                    <AlertMessage
                        className="display-flex justify-content-right alertBanner"
                        id="alertBanner"
                        fullWidth={false}
                        icon="warning"
                        showAlert={true}
                        showCloseBtn={false}
                        type="error"
                    >
                        <b className="alertBannerStyleName">Required fields missing</b>
                        &nbsp; &nbsp;
                        <b className="font-weight-400">All required fields must be entered to save a client
                            profile.</b>
                    </AlertMessage>
                )}
            </Row>
        </div>
    );
}

type GeneralTabProps = {
    displayName: string,
    displayNameInteractions: boolean,
    updateDisplayNameInteractions: (e: boolean) => void,
    settingsType: ProfileSettingsType,
    clientProfile: ProfileResponse,
    clientProfileValidation: ProfileRequestValidation,
    saveHasBeenClicked: boolean,
    primaryContact: PrimaryContact,
    referenceData: ReferenceDataType,
    isProfileWithProposalsReadOnly: boolean,
    isArchiveReadOnly: boolean,
    accessData:AssetsSummary
}

export const GeneralTab: React.FC<GeneralTabProps> = ({
                                                          displayName,
                                                          displayNameInteractions,
                                                          updateDisplayNameInteractions,
                                                          settingsType,
                                                          clientProfile,
                                                          clientProfileValidation,
                                                          saveHasBeenClicked,
                                                          primaryContact,
                                                          referenceData,
                                                          isProfileWithProposalsReadOnly,
                                                          isArchiveReadOnly,
                                                          accessData
                                                      }) => {
    const profileSettingsForm = useAppSelector(selectProfileSettingsForm);
    const dispatch = useAppDispatch();
    const releaseToggles = useAppSelector(selectReleaseToggles)
    const profileIsSample = useAppSelector(selectProfile).sample;
    const linkedUlek = useAppSelector(selectLinkedUlek)
    const [openConvertToProspectModal, setOpenConvertToProspectModal] = useState(false);
    const [noProspectConversionModal, setNoProspectConversionModal] = useState(false);
    const [openConvertToClientModal, setOpenConvertToClientModal] = useState(false);
    const hasLinkedUlek = linkedUlek;

    const hasAnyInvestmentProgram = () =>{
        return !!(accessData.investmentProgram && (accessData.investmentProgram.legalAgreements.length > 0 ||
            accessData.investmentProgram.heldAwayAccounts.length > 0));
    }

    const handleDropdownChange =(data: DropdownOnChangeData<string>)=> {
        const selectedValue = data.itemText;
        if(selectedValue ==='Prospect'){
            if(!hasLinkedUlek && !hasAnyInvestmentProgram())
            {
                setOpenConvertToProspectModal(true)
                setNoProspectConversionModal(false)
            } else {
                setNoProspectConversionModal(true)
                setOpenConvertToProspectModal(false)
            }
        } else if(selectedValue ==='Client' && clientProfile.prospect){
                setOpenConvertToClientModal(true);
        }
        }

    const handleConvertToProspect = () =>{
        setOpenConvertToProspectModal(false)
        dispatch(setProfile({...clientProfile, prospect: true}));
    }

    const handleConvertToClient = () => {
        setOpenConvertToClientModal(false);
        dispatch(setProfile({...clientProfile, prospect: false}));
    }

    const handleCloseModal = () =>{
        setOpenConvertToProspectModal(false)
        setNoProspectConversionModal(false)
        setOpenConvertToClientModal(false)
    }

    return (
        <div className="general-tab">
            <Row>
                <Col>
                    <div className="margintop-lg">
                        <span className="h4">{
                            settingsType === ProfileSettingsType.PROFILE
                                ? 'Profile Details'
                                : 'Proposal Details'
                        }</span>
                        <span
                            className="font-default color-text--error marginleft-md">* Required fields</span>
                    </div>
                    <hr className="section"/>
                    <div className="parent">
                        <Name name={displayName}
                              label={"Display Name"}
                              onChange={(e: any) => {
                                  dispatch(setProfileSettingsForm({
                                      ...profileSettingsForm,
                                      displayName: e.target.value
                                  }));
                              }}
                              disabled={isArchiveReadOnly}
                              required={true}
                              error={clientProfileValidation.displayName}
                              hasInteractions={displayNameInteractions}
                              whenUserHasInteracted={() => {
                                  updateDisplayNameInteractions(true)
                              }}
                              forceShowErrors={saveHasBeenClicked}/>
                    </div>
                    {
                        releaseToggles?.enableProfileSettingsDropdown &&
                        settingsType === ProfileSettingsType.PROFILE &&
                        !profileIsSample &&
                        <div className="parent paddingtop-md">
                            <label className="h5 label profileTypeLabel"
                                   id="profileTypeInput-label"
                                   data-testid="profileTypeLabel"
                                   style={{paddingTop: "10px"}} aria-required={true}
                            >
                                Profile Type
                            </label>
                            <div style={{paddingLeft: "200px"}}>
                                <Dropdown name="profileType"
                                          className="font-bold"
                                          aria-label="profileTypeInput"
                                          aria-labelledby="profileTypeInput-label"
                                          alignRight={false}
                                          disabled={isArchiveReadOnly || isProfileWithProposalsReadOnly}
                                          dropdownKind="select"
                                          panelHeight="auto"
                                          labelWidth="300px"
                                          size="medium"
                                          data-testid="profileTypeInputId"
                                          defaultText={!clientProfile.prospect ? "Client" : "Prospect"}
                                          value={!clientProfile.prospect ? "Client" : "Prospect"}
                                          onChange={handleDropdownChange}
                                >
                                    <DropdownItem itemText={"Client"}
                                                  data-testid="clientDropdownItem"/>
                                    <DropdownItem itemText={"Prospect"}
                                                  data-testid="prospectDropdownItem"/>
                                </Dropdown>
                            </div>

                            <CustomModal
                                    isOpen={openConvertToClientModal}
                                    title={'Edit Profile Type'}
                                    content={
                                        <>
                                        <p>Are you sure you want to convert this Prospect Profile to a Client Profile?
                                         The profile will be moved to the Active Client List on the Partner Dashboard.</p>
                                        <p>To provide access to Service Team, a PW Client Profile must be linked via Profile Settings</p>
                                        </>
                                    }
                                    onClickCancel={handleCloseModal}
                                    onClickConfirm={handleConvertToClient}
                                    cancelText={'CANCEL'}
                                    confirmText={'CONFIRM CLIENT CONVERSION'}
                                    confirmButtonDestructive= {false}
                            />

                            <CustomModal
                                    isOpen={openConvertToProspectModal}
                                    title={'Edit Profile Type'}
                                    content={'Are you sure you want to convert this Client Profile to a Prospect Profile? ' +
                                        'The profile will be moved to the Prospect Client List on the Partner Dashboard. '}
                                    onClickCancel={handleCloseModal}
                                    onClickConfirm={handleConvertToProspect}
                                    cancelText={'CANCEL'}
                                    confirmText={'CONFIRM PROSPECT CONVERSION'}
                                    confirmButtonDestructive= {false}
                            />

                            <ModalWrapper
                                    id="edit-profileType-modal"
                                    isOpen={noProspectConversionModal}
                                    headerText={"Edit Profile Type"}
                                    buttons={[
                                        {
                                            text: 'OKAY',
                                            onClick: handleCloseModal,
                                            primary: true,
                                            destructive: false,
                                        }
                                    ]}
                                    showCloseButton={true}
                                    onRequestClose={handleCloseModal}
                                >
                                    <div className="font-md">
                                        <AlertBanner
                                            icon={'warning'}
                                            showAlert={true}
                                            message={"Cannot convert Client Profile to Prospect Profile"}
                                        />
                                        <br/>
                                        A Client Profile with a linked Partner Workstation Client and/or Investment Program
                                        cannot be converted to a Prospect Profile
                                    </div>
                            </ModalWrapper>
                        </div>
                    }
                    {clientProfile?.primaryContact
                        ? (<PrimaryContactInformation
                            primaryContact={primaryContact}
                            showPartnerWorkstationLink={(settingsType === ProfileSettingsType.PROFILE) && !clientProfile.prospect && !clientProfile.sample && clientProfile.activeClient}/>)
                        : undefined}
                </Col>
                <Col className="margintop-lg">
                    {referenceData.taxRates && clientProfile?.effectiveTaxRate ? (
                        <EffectiveTaxRates
                            taxRate={profileSettingsForm.effectiveTaxRate}
                            updateTaxRate={(taxRate: EffectiveTaxRateType) => {
                                dispatch(setProfileSettingsForm({
                                    ...profileSettingsForm,
                                    effectiveTaxRate: taxRate
                                }));
                            }}
                            referenceData={referenceData}
                            isReadOnly={isProfileWithProposalsReadOnly || isArchiveReadOnly}/>
                    ) : undefined}
                    {referenceData.accreditedStatus && clientProfile?.accreditedStatus ? (
                        <NewClientFinancialModel financialModel={{
                            accreditedStatus: profileSettingsForm.accreditedStatus,
                            allocationType: profileSettingsForm.allocationType
                        }}
                                                 updateFinancialModel={(financialModel: FinancialModelSelection) => {
                                                     dispatch(setProfileSettingsForm({
                                                         ...profileSettingsForm,
                                                         ...financialModel
                                                     }));
                                                 }}
                                                 referenceData={referenceData}
                                                 disabled={isProfileWithProposalsReadOnly || isArchiveReadOnly}/>
                    ) : undefined}

                    {referenceData.managementFees && clientProfile?.managementFees ? (
                        <EffectiveFees managementFees={profileSettingsForm.managementFees}
                                       updateManagementFees={((managementFees: string) => {
                                           dispatch(setProfileSettingsForm({
                                               ...profileSettingsForm,
                                               managementFees: managementFees
                                           }));
                                       })}
                                       referenceData={referenceData}
                                       isReadOnly={isProfileWithProposalsReadOnly || isArchiveReadOnly}/>
                    ) : undefined}
                </Col>
            </Row>
        </div>
    );
}

type ServiceTeamTabProps = {
    serviceTeam: ServiceTeamMember[],
    savedProfileOwner: string,
    profileOwnerFormState: string,
    updateProfileOwnerFormState: (e: string) => void,
    userLanID: string,
    hasLinkedUlek: boolean,
    disabled: boolean,
    isProspect: boolean,
    isProposal: boolean,
    isSample: boolean,
    onPartnerSelect: (partner: Partner) => void;
    onRemoveMember: (partnerCode: string) => void;
}

export const ServiceTeamTab: React.FC<ServiceTeamTabProps> = ({
                                                                  serviceTeam,
                                                                  savedProfileOwner,
                                                                  profileOwnerFormState,
                                                                  updateProfileOwnerFormState,
                                                                  userLanID,
                                                                  hasLinkedUlek,
                                                                  disabled,
                                                                  isProspect,
                                                                  isProposal,
                                                                  isSample,
                                                                  onPartnerSelect,
                                                                  onRemoveMember
                                                              }) => {
    const onPerformSearch = (searchTerm: string, page?: number): Promise<SearchPartnerResults> => {
        return partnerApiClient.searchPartners(searchTerm, page).then();
    }

    const searchbarHeaders = <div className="search-results-grid">
        <span className="textalign-left"><b>LAN ID</b></span>
        <span className="textalign-left"><b>Partner Name</b></span>
        <span className="textalign-left"><b>Phone Number</b></span>
        <span className="textalign-left"><b>Email Address</b></span>
        <span className="textalign-left"><b>Partner Title / Role</b></span>
    </div>;

    const displaySearchResults = (result: Partner) => {
        const isServiceTeamMemberAlreadySelected = serviceTeam.some(teamMember => teamMember.partnerCode === result.partnerCode);
        return <div className={classNames("search-results-grid", {
            disabled: isServiceTeamMemberAlreadySelected
        })}>
            <span className="textalign-left">{result.partnerCode}</span>
            <span className="textalign-left">{result.partnerName}</span>
            <span className="textalign-left">{result.phoneNumber}</span>
            <span className="textalign-left">{result.email}</span>
            <span className="textalign-left">{result.title}</span>
        </div>
    };

    const columns: any[] = [
        {
            id: 'ownerId',
            Header: 'Owner',
            accessor: 'partnerCode',
            maxWidth: 200,
            Cell: ({value}: { value: string }) => {
                const partnerCode = value;
                if (caseInsensitiveEquals(savedProfileOwner, userLanID)) {
                    const isChecked = caseInsensitiveEquals(partnerCode, profileOwnerFormState);
                    return <Radio
                        disabled={disabled}
                        checked={isChecked}
                        value={partnerCode}
                        id={partnerCode}
                        name={partnerCode}
                        role={"radio"}
                        aria-label={partnerCode}
                        aria-checked={isChecked}
                        onChange={() => updateProfileOwnerFormState(partnerCode)}
                    />
                }
                return caseInsensitiveEquals(partnerCode, savedProfileOwner)
                    ? <Icon name={'status_validated'} color={'#64a70b'}/>
                    : <div/>
            },
        },
        {
            Header: 'Partner Name',
            accessor: 'partnerName',
            maxWidth: 200
        },
        {
            Header: 'Phone Number',
            accessor: 'phoneNumber',
            maxWidth: 200
        },
        {
            Header: 'Email Address',
            accessor: 'email',
            maxWidth: 200
        },
        {
            Header: 'Partner Title / Role',
            accessor: 'title'
        }
    ];

    if ((isProspect || isSample) && !isProposal) {
        columns.push({
                id: 'delete_service_team_member',
                accessor: 'partnerCode',
                Cell: ({value}: { value: string }) => {
                    return !caseInsensitiveEquals(value, savedProfileOwner)
                        ? <Button
                            className={'delete-service-team-button'}
                            kind="borderless"
                            size="small"
                            tabIndex={0}
                            type="button"
                            destructive={true}
                            icon={"left"}
                            iconName={"delete"}
                            onClick={() => {
                                onRemoveMember(value)
                            }}
                        >
                            Remove
                        </Button>
                        : <div/>
                }
            }
        )
    }

    return (
        <div className="service-team-table">
            {!isProspect && !isSample && <div className={"service-team-subtitle"}>
                <span>Service Team partner access is managed within Partner Workstation</span>
            </div>}
            {(isProspect || isSample) && !isProposal && <div className={"prospect-team-subtitle"}>
                <span>Please search by LAN ID in the field below to add a partner to the Service Team</span>
            </div>}
            {(isProspect || isSample) && !isProposal && <div className={'search-bar-container'} data-testid={'partner-searchbar'}>
                <TypeaheadSearchBar<Partner>
                    performSearch={onPerformSearch}
                    placeholder={'Type LAN ID...'}
                    displayResult={displaySearchResults}
                    resultHeader={searchbarHeaders}
                    onSelect={(partner: Partner) => {
                        const isServiceTeamMemberAlreadySelected = serviceTeam.some(teamMember => teamMember.partnerCode === partner.partnerCode);
                        if (isServiceTeamMemberAlreadySelected) {
                            return;
                        }
                        onPartnerSelect(partner);
                    }}
                />
            </div>}
            <Table
                data-testid={'service_team_table'}
                id="service_team_table"
                data={serviceTeam}
                key={profileOwnerFormState}
                showPagination={serviceTeam.length > 10}
                defaultPageSize={15}
                pageSizeOptions={PARTNER_DASHBOARD_PAGE_SIZES}
                columns={columns}
            />
            {!hasLinkedUlek && !isProspect && !isSample &&
                <div className={"no-partner-workstation-text"}>
                    <span>This profile is not connected to Partner Workstation</span>
                </div>
            }
        </div>
    );
}

export default ProfileSettings;
