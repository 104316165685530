import {DatePicker, Input, RedAsterisk, RequiredFieldsSubheader, UnderlinedHeader} from "../../components";
import React, {ChangeEvent, useEffect, useState} from "react";
import {DISPLAY_DATE_FORMAT} from "../../constants/common";
import moment, {Moment} from "moment";
import {RevocableTrust} from "../EstateFlowchartTypes";
import {useAppSelector} from "src/store/hooks";
import {useParams} from "react-router-dom";
import {getEstateFlowcharts} from "../EstateFlowChartSlice";
import {RouteWithIdAndFlowchartId} from "src/routes/types";
import {momentToISO} from "../../utils/dateUtils";
import {isValid} from "./validations";

type BasicInformationContentProps = {
    saveHasBeenClicked : boolean,
    updatedRevocableTrust: Function
}

const BasicInformationContent: React.FC<BasicInformationContentProps> = ({
                                                                             saveHasBeenClicked,
                                                                             updatedRevocableTrust
                                                                         }) => {

    const [basicInformation, updateBasicInformation] = useState({} as RevocableTrust);
    const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);
    const {flowchartId} = useParams<RouteWithIdAndFlowchartId>();

    useEffect(() => {
        if (estateFlowchartsInState.length > 0) {
            const trust = (estateFlowchartsInState.find(flowchart => flowchart.flowchartId === flowchartId))?.["revocableTrust"];
            if (trust) {
                updateBasicInformation(trust);
            }
        }
    }, [])

    useEffect(() => {
        updatedRevocableTrust(basicInformation)
    }, [basicInformation]);

    return (
        <section aria-label="Basic Info" className="basic-information__form">
            <UnderlinedHeader leftAlignedContent={<><h4>Basic Information</h4> <RequiredFieldsSubheader/></>}/>

            <div className="textalign-right form-main">
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'flowchart-name'}>Flowchart Name<RedAsterisk/></label>
                    <Input
                        aria-label="Flowchart Name"
                        size="medium"
                        value={basicInformation.flowchartName || ""}
                        error={saveHasBeenClicked &&  !isValid(basicInformation.flowchartName) ? "Flowchart Name is required." : undefined}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, flowchartName: e.target.value
                        })}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'legal-name'}>Legal Name of Trust or
                        Entity<RedAsterisk/></label>
                    <Input
                        aria-label="Legal Name"
                        size="medium"
                        value={basicInformation.legalName || ''}
                        error={saveHasBeenClicked && !isValid(basicInformation.legalName) ? "Legal Name of Trust or Entity is required." : undefined}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, legalName: e.target.value
                        })}

                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'nick-name'}>Nickname</label>
                    <Input
                        aria-label="Nickname"
                        size="medium"
                        value={basicInformation.nickName ? basicInformation.nickName : ''}
                        disabled={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, nickName: e.target.value
                        })}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'date-created'}>Date Created<RedAsterisk /></label>
                    <DatePicker
                        className="createdDateField"
                        id="createdDateInput"
                        aria-label="Date Created"
                        displayFormat={DISPLAY_DATE_FORMAT}
                        hideKeyboardShortcutsPanel
                        isOutsideRange={(date: Moment) => date.isAfter(moment())}
                        date={basicInformation.trustCreationDate ? moment(basicInformation.trustCreationDate) : undefined}
                        error={saveHasBeenClicked && !isValid(basicInformation.trustCreationDate) ? "Date Created is required." : undefined}
                        onDateChange={
                            (date: Moment) => {
                                updateBasicInformation({
                                    ...basicInformation, trustCreationDate: momentToISO(date)
                                })
                            }
                        }
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <div className={"textarea-label"}><label className={"h5"} data-testid={'record-of-amendments'}>Record
                        of Amendments</label>
                        <span>{basicInformation?.recordOfAmendment ? basicInformation?.recordOfAmendment?.length : 0}/200</span>
                    </div>
                    <textarea
                        name="Record of Amendments"
                        className="record-of-amendments input-skin"
                        data-testid='record-of-amendments-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={200}
                        defaultValue={basicInformation.recordOfAmendment ? `${basicInformation.recordOfAmendment}` : ''}
                        onChange={(e) => {
                            updateBasicInformation({
                                ...basicInformation, recordOfAmendment: e.target.value})}
                        }
                    />
                </div>
            </div>
        </section>
    );
}

export default BasicInformationContent;