import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {LifestyleSpendingGoal, LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import {AssetTotals} from "../../Assets/models/Assets";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveYears,
    setPortfolioReserveButtonStates,
    setPortfolioReserveYears
} from "./PortfolioReserveSlice";
import {roundPercentagesToHundred} from "../../utils/roundPercentagesToHundred";
import {setHeaderTickerTabsProposedAllocation} from "../../ClientManagement/ClientProfile/HeaderTickerTabsSlice";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import SetPortfolioReserveHeader from "./SetPortfolioReserveHeader";
import {handleTogglePortfolioReserve} from "./PortfolioReserveUtil";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {Donut} from "xps-react";
import {COLOR_PORTFOLIO_RESERVE, COLOR_PROJECTED_ASSETS, COLOR_TRANSPARENT} from "../../constants/colors";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import {Button, Icon, InfoPopover} from "../../components";
import {formatCurrency, formatNumberRoundedToOneDecimal, formatYear} from "../../utils/format";
import AlignedInflowsTable from "./AlignedInflowsTable";
import {PortfolioReserveButtonState} from "./models/PortfolioReserveButtonState";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import classNames from "classnames";

type ReviewPortfolioReserveContentProps = {
    portfolioReserve: PortfolioReserveResponse,
    profile: ProfileResponse,
    lifestyleSpendingGoal: LifestyleSpendingGoal,
    assets: AssetTotals,
    nonLifestyleTotalPresentValue: number,
    showTargetLabel: boolean,
    portfolioIsInvestablySufficient: boolean,
    protectedPeriods: any,
    portfolioReserveTargetLength: number,
    errorModelData?: GenericErrorModalData,
    pageNumber?: number,
}

const ReviewPortfolioReserveContent = ({
                                           portfolioReserve,
                                           profile,
                                           lifestyleSpendingGoal,
                                           assets,
                                           nonLifestyleTotalPresentValue,
                                           showTargetLabel = true,
                                           portfolioIsInvestablySufficient = false,
                                           protectedPeriods,
                                           portfolioReserveTargetLength,
                                           errorModelData,
                                           pageNumber
                                       }: ReviewPortfolioReserveContentProps) => {
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);

    const profileFromState = useAppSelector(selectProfile);
    const {minimumRiskInputYears} = useAppSelector(selectPortfolioReserveYears);

    const dispatch = useAppDispatch();
    const portfolioReserveButtons = useAppSelector(selectPortfolioReserveButtonStates);

    const reserveTargetAssetAllocation = {
        totalRiskControlAssetsPercent: portfolioReserve.adjustedTargetLengthAllocation.riskControlPercent,
        totalRiskAssetsPercent: portfolioReserve.adjustedTargetLengthAllocation.riskAssetPercent,
    }


    const reserveTargetText = getReserveTargetText(profile);

    const portfolioSize = Math.max(assets.totalInvestableValue, (lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue + nonLifestyleTotalPresentValue))

    const {
        portfolioReservePresentValue,
        portfolioReserveAlignedInflowPresentValue,
        reserveTargetAmount,
        inflowPresentValues
    } = lifestyleSpendingGoal.calculatedFields


    const {
        percentage1: roundedRiskAssetsPercent,
        percentage2: roundedRiskControlAssetsPercent
    } = roundPercentagesToHundred(reserveTargetAssetAllocation.totalRiskAssetsPercent, reserveTargetAssetAllocation.totalRiskControlAssetsPercent);

    const handleErrorCloseButton = () => {
        errorModelData!.isOpen = false;
        setGenericError({...genericError, isOpen: false});
    }

    const showLineBreak = () => {
        if (pageNumber) {
            let pageOneLessThanEqualToFivePeriods = (pageNumber === 1 && protectedPeriods.length <= 5)
            let pageTwoGreaterThanFivePeriods = (pageNumber === 2 && protectedPeriods.length > 5)
            return pageOneLessThanEqualToFivePeriods || pageTwoGreaterThanFivePeriods
        }
        return false
    }

    useEffect(() => {
        //if minimum risk scenario had been updated on edit pr page, reset to default for next load
        if (minimumRiskInputYears !== portfolioReserve.minimumRiskReserveLength) {
            dispatch(setPortfolioReserveYears({
                prTargetInputYears: profile.portfolioReserveTargetLength !== undefined ? profile.portfolioReserveTargetLength : portfolioReserve.portfolioReserveTargetLength,
                minimumRiskInputYears: portfolioReserve.minimumRiskReserveLength
            }));
        }
    })

    //rendering the page
    useEffect(() => {
        if (!profileFromState.activePortfolioReserve) {
            dispatch(setHeaderTickerTabsProposedAllocation(reserveTargetAssetAllocation));
        }
    }, [reserveTargetAssetAllocation]);

    return (
        <div className="set-portfolio-reserve-page">
            <div>
                {errorModelData?.isOpen ?
                    <GenericErrorModal
                        errorModalData={errorModelData}
                        onClickButton={() => handleErrorCloseButton()}
                        buttonText={'Close'}/> : <React.Fragment/>
                }
                {(errorModelData?.isOpen === undefined || errorModelData?.isOpen === false) && <React.Fragment/>}
            </div>
            <ScrollableContainer id={'review-portfolio-reserve-page-scroll-container'}
                                 className="display-flex flex-column">
                <SetPortfolioReserveHeader
                    displaySetReserveTargetButton={true}
                    isSetReserve={false}
                    onTogglePortfolioReserve={handleTogglePortfolioReserve}
                />
                <div className="set-portfolio-reserve-page__body">
                    <div className="set-portfolio-reserve-page__body__chart">
                        <div
                            className="review-portfolio-reserve-page__body__customized-allocation__header-container">
                            <h2 className="review-portfolio-reserve-page__body__customized-allocation__sub-header"
                                text-align="left">
                                {getProposedAssetAllocationText(pageNumber)}
                            </h2>
                            <h3 className="review-portfolio-reserve-page__body__customized-allocation__sub-header-text"
                                style={{display: "block"}}> Investable Assets Only</h3>
                        </div>
                        <div className="set-portfolio-reserve-page__donut-container">
                            <div className="set-portfolio-reserve-page__donut-container__solid">
                                <RiskDonut
                                    donutSize={'lg'}
                                    data={reserveTargetAssetAllocation}
                                    testId="RiskDonut"
                                />
                            </div>
                            {portfolioIsInvestablySufficient &&
                                <div className="set-portfolio-reserve-page__donut-container__shaded">
                                    <div className={`donut-container donut-container__xl`}>
                                        <Donut
                                            role="risk-donut"
                                            className="donut"
                                            data-testid="PortfolioReserveDonut"
                                            data={[
                                                {value: (portfolioSize - portfolioReservePresentValue)},
                                                {value: portfolioReservePresentValue},
                                            ]}
                                            colors={[COLOR_TRANSPARENT, COLOR_PORTFOLIO_RESERVE]}
                                            outerRadius={60}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={"risk-assets-and-risk-control"} data-testid="RiskDonutLegend">
                            <div className={"risk"}>
                                <div className={"risk-legend-label"} data-testid="RiskLegendForRA">
                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS}>
                                        <span className={"risk-label-bold"}>Risk Assets</span>
                                    </RiskLegend>
                                    <span
                                        className={"risk-percentage"}>{` ${roundedRiskAssetsPercent}%`}</span>
                                </div>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"} data-testid="RiskLegendForRC">
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}>
                                        <span className={"risk-label-bold"}>Risk Control</span>
                                    </RiskLegend>
                                    <span
                                        className={"risk-percentage"}>{` ${roundedRiskControlAssetsPercent}%`}</span>
                                </div>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}
                                     data-testid="RiskLegendForPortfolioReserve">
                                    <RiskLegend legendColor={riskLegendColor.PORTFOLIO_RESERVE}
                                                label={"Portfolio Reserve"}/>
                                </div>
                            </div>
                            {
                                !portfolioIsInvestablySufficient &&
                                <div data-testid="InvestableInsufficiencyWarning">
                                    <div style={{marginTop: '4px'}}>
                                        <InfoPopover
                                            content={<div>Consider reducing your portfolio reserve
                                                target or aligning
                                                goals
                                                to non-investable assets in order to achieve investable
                                                sufficiency.</div>}
                                            direction="right"
                                            width="288px"
                                        />
                                        <span
                                            style={{color: COLOR_PROJECTED_ASSETS}}>
                                        Investable Asset Shortfall
                                    </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="review-portfolio-reserve-page__body__customized-allocation">

                        {(!pageNumber || pageNumber === 1) && <> <h2
                            className="review-portfolio-reserve-page__body__customized-allocation__sub-header">
                            Review your current Portfolio Reserve
                        </h2>
                            <CustomizedAllocationTextForMLS text="Your annual lifestyle spending"
                                                            spendingPeriods={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                                                            portfolioReserveTargetLength={portfolioReserveTargetLength}
                                                            protectedPeriods={protectedPeriods}/> </>
                        }
                        {(showLineBreak())}
                        <>
                            <img
                                className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                src="/Rectangle679.svg"/>
                            <CustomizedAllocationText
                                text="The years of lifestyle spending you want to protect"
                                quantity={formatYear(portfolioReserveTargetLength)}/>
                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">close</i>
                            <div
                                className="review-portfolio-reserve-page__body__customized-allocation__sub-text">{reserveTargetText}
                            </div>
                            {getEditPortfolioReserveBanner(showTargetLabel)}
                            <img
                                className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                src="/Rectangle677.svg"/>
                            <CustomizedAllocationText text="Your Reserve Target Amount"
                                                      quantity={formatCurrency(reserveTargetAmount)}/>
                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                            <span
                                className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Calculated in Present Value</span>

                            <img
                                className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                src="/Rectangle679.svg"/>
                            <CustomizedAllocationText
                                text="Future inflows you will receive over those years"
                                quantity={formatCurrency(portfolioReserveAlignedInflowPresentValue)}>
                                <Button kind="borderless"
                                        size="small"
                                        type="button"
                                        onClick={() => {
                                            dispatch(setPortfolioReserveButtonStates({
                                                ...portfolioReserveButtons,
                                                futureInflow: !portfolioReserveButtons.futureInflow
                                            }));
                                        }}>{getPortfolioReserveButtons(portfolioReserveButtons)}
                                </Button>
                            </CustomizedAllocationText>
                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon review-portfolio-reserve-page__body__customized-allocation__minus-icon">–</i>
                            {portfolioReserveButtons.futureInflow &&
                                <AlignedInflowsTable
                                    portfolioReserveTargetLength={portfolioReserveTargetLength}
                                    inflowPresentValues={inflowPresentValues}
                                />
                            }

                            <img
                                className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                src="/Rectangle677.svg"/>
                            <CustomizedAllocationText text="Your Portfolio Reserve"
                                                      quantity={formatCurrency(portfolioReservePresentValue)}
                                                      tooltip={profileFromState.activePortfolioReserve &&
                                                          <InfoPopover
                                                              content={<div
                                                                  style={{fontSize: "15px"}}>This value
                                                                  considers
                                                                  drawdown to risk control assets
                                                                  since turning on the Portfolio
                                                                  Reserve.</div>}
                                                              direction="top"
                                                              width="288px"
                                                          />}/>
                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                            <span
                                className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Allocated to Risk Control Assets</span>
                        </>
                    </div>
                </div>
            </ScrollableContainer>
        </div>
    )
}

const CustomizedAllocationText = ({children, quantity, text, tooltip}: {
    text: string,
    quantity: string,
    children?: React.ReactNode,
    tooltip?: React.ReactNode
}) => {
    return <div className="review-portfolio-reserve-page__body__customized-allocation__text">
        <div>{text}{tooltip ? tooltip : <></>}</div>

        <div className="quantity">{quantity}</div>
        {children ? children : <></>}
    </div>;
}

const EditPortfolioReserveBanner = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const handleEditPR = () => {
        history.push(`/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve/EditPortfolioReserve`, {forceNavigate: true});
    }

    return (
        <div style={{paddingLeft: "15%", paddingRight: "15%"}} className={"paddingtop-lg"}>
            <div
                className="paddingtop-sm paddingright-sm display-flex paddingbottom-sm"
                style={{backgroundColor: "#f6f6f6"}}>
                <Icon
                    data-testid={"change-pr-icon"}
                    name={"info"}
                    type={"info"}
                    className={"paddingleft-md paddingtop-xs"}/>
                <div
                    className={"paddingleft-lg paddingright-xxxl display-flex font--small align-content-center"}
                    style={{fontWeight: "500", paddingTop: "3px"}}
                >
                    Looking to change the Reserve Target?
                </div>
                <div
                    className={"paddingleft-lg display-flex font--small align-content-center"}
                >
                    <Button kind="borderless"
                            size="small"
                            type="button"
                            style={{borderColor: "transparent", whiteSpace: "pre"}}
                            onClick={handleEditPR}
                    >
                        {"EDIT PORTFOLIO RESERVE"}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const CustomizedAllocationTextForMLS = ({spendingPeriods, portfolioReserveTargetLength, text, protectedPeriods}: {
    text: string,
    spendingPeriods: LifestyleSpendingPeriodInputs[],
    portfolioReserveTargetLength: number,
    protectedPeriods: any
}) => {

    if (portfolioReserveTargetLength === 0) {
        return <CustomizedAllocationText text={text}
                                         quantity={formatCurrency(spendingPeriods[0]?.annualSpend)}/>
    }

    if (protectedPeriods.length < 2) {
        return <CustomizedAllocationText text={text}
                                         quantity={formatCurrency(protectedPeriods[0]?.annualSpend)}/>
    }
    return <div className="review-portfolio-reserve-page__body__customized-allocation__text">
        <div>{text}</div>
        <div>
            {protectedPeriods.map((item: LifestyleSpendingPeriodInputs, index: number) => {
                    const lastItem = index === protectedPeriods.length - 1;
                    const roundedYears = Number(formatNumberRoundedToOneDecimal(item.endYear - item.startYear))
                    return <div
                        className="review-portfolio-reserve-page__body__customized-allocation__text__spending-period"
                        key={`${index}-${item.id}`}>
                        <div className="quantity">{formatCurrency(item.annualSpend)}</div>
                        <div
                            className={classNames(
                                "review-portfolio-reserve-page__body__customized-allocation__sub-text-years",
                                {
                                    "paddingbottom-12": !lastItem
                                }
                            )}>
                            {`${roundedYears} years`}
                        </div>
                    </div>;
                }
            )}
        </div>
    </div>
}

const getReserveTargetText = (profile: ProfileResponse) => {
    return profile.activePortfolioReserve ? 'Expected Reserve Length' : 'Your Reserve Target Length'
};

const getProposedAssetAllocationText = (pageNumber: number | undefined) => {
    return (pageNumber === 1 || pageNumber === undefined) ? "Proposed Asset Allocation" : "Proposed Asset Allocation (continued)";
};

const getPortfolioReserveButtons = (portfolioReserveButtons: PortfolioReserveButtonState) => {
    return portfolioReserveButtons.futureInflow ? "Hide" : "Show"
};

const getEditPortfolioReserveBanner = (showTargetLabel: boolean) => {
    return showTargetLabel ? <EditPortfolioReserveBanner/> : <div/>;
}

export default ReviewPortfolioReserveContent;