import DataEntrySideDrawer from "../../components/DataEntry/DataEntrySideDrawer";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {Accordion, AccordionItem, Icon} from "../../components";
import {Checkbox} from "xps-react";
import React, {ChangeEvent, useMemo} from "react";
import {AdvisorDocumentSubMenu, SubMenuItem} from "../../Resources/models/AdviceLibrary";
import {DocumentDetails} from "../../models/routeData/RouteParamTypes";

type AdvisorDocumentSetupSectionProps = {
    drawerLabel: string;
    onAttach: () => void;
    openAdvisorDocumentDrawer: boolean
    selectedAdvisorDocuments: string[];
    advisorDocumentsSubMenuArray: AdvisorDocumentSubMenu[];
    setSelectedAdvisorDocuments: (state: string[]) => void;
    setShowDrawerForAdvisorDocument: (state: boolean) => void;
    onRefreshSeismicDocs: () => void
};

function AdvisorDocumentSetupSection({
                                         drawerLabel,
                                         onAttach,
                                         openAdvisorDocumentDrawer,
                                         selectedAdvisorDocuments,
                                         advisorDocumentsSubMenuArray,
                                         setSelectedAdvisorDocuments,
                                         setShowDrawerForAdvisorDocument,
                                         onRefreshSeismicDocs
                                     }: AdvisorDocumentSetupSectionProps) {

    const expandedSections = drawerLabel === "Advice Library" ? advisorDocumentsSubMenuArray.map((submenu, index) => `${submenu.subMenuLabel}-${index}`) : ["Practice Documents-0","GDWM Practice-1","Financial Essentials-2"]

    let advisorDocumentsSubMenus = drawerLabel === "Advice Library" ? getAdvisorDocumentsAdviceLibrarySubMenu(advisorDocumentsSubMenuArray) : getAdvisorDocumentsPracticeDocumentsSubMenu(advisorDocumentsSubMenuArray);

    const selectedDocuments: string[] = useMemo(() => {
        return openAdvisorDocumentDrawer ? [...selectedAdvisorDocuments] : []
    }, [openAdvisorDocumentDrawer]);


    const handleToggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        let updatedDocumentNames = [...selectedAdvisorDocuments];
        const {checked, name: selectedDocumentName} = e.target;
        if (checked) {
            updatedDocumentNames.push(selectedDocumentName);
        } else {
            updatedDocumentNames = updatedDocumentNames.filter(document => document !== selectedDocumentName);
        }
        setSelectedAdvisorDocuments(updatedDocumentNames);
    }

    const isDocumentChecked = (label: string) => {
        return selectedAdvisorDocuments.includes(label);
    }

    const handleClose = () => {
        setSelectedAdvisorDocuments([...selectedDocuments]);
        setShowDrawerForAdvisorDocument(false);
    }

    if (!openAdvisorDocumentDrawer) {
        return null;
    }

    function getAdvisorDocumentsPracticeDocumentsSubMenu(advisorDocumentsMenuArray: AdvisorDocumentSubMenu[]) {

        let advisorDocumentsSubMenusArray : AdvisorDocumentSubMenu[] = [];

        let advisorDocumentsSubMenus1: AdvisorDocumentSubMenu  = {
            subMenuLabel: "",
            subMenuItems: [],
            subMenuFolders: []
        };
        let advisorDocumentsSubMenus2: AdvisorDocumentSubMenu  = {
            subMenuLabel: "",
            subMenuItems: [],
            subMenuFolders: []
        };
        let advisorDocumentsSubMenus3: AdvisorDocumentSubMenu  = {
            subMenuLabel: "",
            subMenuItems: [],
            subMenuFolders: []
        };
        let subMenuItems1: SubMenuItem[] = [];
        let subMenuItems2: SubMenuItem[] = [];
        let subMenuItems3: SubMenuItem[] = [];

        advisorDocumentsMenuArray.forEach(submenu => {
            if(!(submenu.subMenuLabel === "Financial Essentials" || submenu.subMenuLabel === "GDWM Practice"))
            {

                submenu.subMenuItems.forEach(item => {
                    subMenuItems1.push({
                        documentLabel: item.documentLabel,
                        seismicDocumentName: "",
                        tagCollateralType: "",
                        additionalTagGPS: ""
                    });
                })

                advisorDocumentsSubMenus1 = {
                    subMenuLabel: "Practice Documents",
                    subMenuItems: sortDocumentsBasedOnLabelNames(subMenuItems1),
                    subMenuFolders: []
                }
            }
            else if((submenu.subMenuLabel === "GDWM Practice"))
            {

                submenu.subMenuItems.forEach(item => {
                    subMenuItems2.push({
                        documentLabel: item.documentLabel,
                        seismicDocumentName: "",
                        tagCollateralType: "",
                        additionalTagGPS: ""
                    });
                })

                advisorDocumentsSubMenus2 = {
                    subMenuLabel: submenu.subMenuLabel,
                    subMenuItems: sortDocumentsBasedOnLabelNames(subMenuItems2),
                    subMenuFolders: []
                }

            }
            else if((submenu.subMenuLabel === "Financial Essentials"))
            {

                submenu.subMenuItems.forEach(item => {
                    subMenuItems3.push({
                        documentLabel: item.documentLabel,
                        seismicDocumentName: "",
                        tagCollateralType: "",
                        additionalTagGPS: ""
                    });
                })



                advisorDocumentsSubMenus3 = {
                    subMenuLabel: submenu.subMenuLabel,
                    subMenuItems: sortDocumentsBasedOnLabelNames(subMenuItems3),
                    subMenuFolders: []
                }

            }

        })
        advisorDocumentsSubMenusArray.push(advisorDocumentsSubMenus1);
        advisorDocumentsSubMenusArray.push(advisorDocumentsSubMenus2);
        advisorDocumentsSubMenusArray.push(advisorDocumentsSubMenus3);
        return advisorDocumentsSubMenusArray;
    }

    function getAdvisorDocumentsAdviceLibrarySubMenu(advisorDocumentsMenuArray: AdvisorDocumentSubMenu[]) {

        let advisorDocumentsSubMenusArray : AdvisorDocumentSubMenu[] = [];

        advisorDocumentsMenuArray.forEach(submenu => {

            let advisorDocumentsSubMenu: AdvisorDocumentSubMenu;

            let subMenuItems: SubMenuItem[] = [];

            submenu.subMenuItems.forEach(item => {
                subMenuItems.push({
                    documentLabel: item.documentLabel,
                    seismicDocumentName: "",
                    tagCollateralType: "",
                    additionalTagGPS: ""
                });
            })

            advisorDocumentsSubMenu = {
                subMenuLabel: submenu.subMenuLabel,
                subMenuItems: sortDocumentsBasedOnLabelNames(subMenuItems),
                subMenuFolders: []
            }
            advisorDocumentsSubMenusArray.push(advisorDocumentsSubMenu);
        })

        return advisorDocumentsSubMenusArray;
    }

    function sortDocumentsBasedOnLabelNames(subMenuItems: SubMenuItem[])
    {
        return subMenuItems.sort((ele1, ele2) => (ele1.documentLabel.localeCompare(ele2.documentLabel)));
    }

    return <DataEntrySideDrawer isOpen={openAdvisorDocumentDrawer}>
        <DataEntryHeader
            title={drawerLabel}
            primaryButtonText={"Attach"}
            hideSecondaryButton={true}
            onPrimaryButtonClick={onAttach}
            showCloseButton={true}
            onCloseButtonClick={handleClose}
            buttonSize="small"
            showRefreshButton={true}
            onRefreshButtonClick={onRefreshSeismicDocs}
        />
        <Accordion className="advice-library-accordion" allowMultipleExpanded={true} expanded={expandedSections}
                   accordionId={`adviceLibraryAccordion`}>
            {advisorDocumentsSubMenus.map((submenu, index) => {
                const uuid = `${submenu.subMenuLabel}-${index}`;
                return (
                     <AccordionItem
                        uuid={uuid}
                        key={uuid}
                        HeaderComponent={({expanded}) =>
                            <div className="accordion-header-content marginbottom-12">
                                <span className="display-flex align-items-center">
                                  <Icon name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>
                                  <span className="paddingleft-md">
                                      <div
                                          className="h5 fontweight-normal margin-none">{submenu.subMenuLabel}</div>
                                  </span>
                                </span>
                            </div>}
                    >
                        <ul className="advice-library-accordion__items">
                            {submenu.subMenuItems.map(({documentLabel}, childIndex) => (
                                <li
                                    className="advice-library-accordion__item"
                                    key={`${documentLabel}-${childIndex}`}
                                >
                                    <Checkbox
                                        className="advice-library-accordion__item__checkbox"
                                        aria-label={documentLabel}
                                        value={documentLabel}
                                        checked={isDocumentChecked(documentLabel)}
                                        name={documentLabel}
                                        onChange={handleToggleCheckbox}
                                    />
                                </li>
                            ))}
                        </ul>
                    </AccordionItem>
                );
            })
            }
        </Accordion>
    </DataEntrySideDrawer>;
}

export default AdvisorDocumentSetupSection;