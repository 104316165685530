import {StackedBarChartItemType} from "../../../components/StackedBarChart/StackedBarChartItem";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS,
 COLOR_PROJECTED_ASSETS
} from "../../../constants/colors";

export default class ComparePlanAssetBarChartColumn {
    protected readonly totalNetAssetsPresentValue: number;
    protected readonly totalGoalsPresentValue: number;
    protected readonly excessAssets: number;
    protected readonly chartUnderlayMaxValue: number;
    protected readonly hasExcessAssets: boolean;

    constructor(totalNetAssetsPresentValue: number, totalGoalsPresentValue: number, excessAssets: number,
                chartUnderlayMaxValue: number, hasExcessAssets: boolean) {
        this.totalNetAssetsPresentValue = totalNetAssetsPresentValue;
        this.totalGoalsPresentValue = totalGoalsPresentValue;
        this.excessAssets = excessAssets;
        this.chartUnderlayMaxValue = chartUnderlayMaxValue;
        this.hasExcessAssets = hasExcessAssets
    }

    getColumnMaxValue(): number {
        return Math.max(this.totalNetAssetsPresentValue, this.totalGoalsPresentValue);
    }

    getGoalsPositionVerticalOffset() {
        return (100 - this.totalGoalsPresentValue/ this.getColumnMaxValue() * 100).toFixed(2);
    }

    getChartItems(): StackedBarChartItemType[] {
        if(!this.hasExcessAssets) {
            return [
                {
                    label: "Net Assets",
                    total: this.totalNetAssetsPresentValue,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.totalNetAssetsPresentValue
                }
            ];
        } else {
            return [
                {
                    label: "Excess Assets",
                    total: this.excessAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: 0
                },
                {
                    label: "Goals",
                    total: this.totalGoalsPresentValue ,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.totalGoalsPresentValue
                }
                ];
        }
    }

    getVerticalLineMargins() {
        let topMargin;
        let bottomMargin;
            topMargin = 100 - ((this.totalNetAssetsPresentValue/ this.getColumnMaxValue()) * 100);
            bottomMargin = (this.totalGoalsPresentValue / this.getColumnMaxValue()) * 100;

        return {
            topMargin: Math.max(topMargin, 0).toFixed(2),
            bottomMargin: Math.max(bottomMargin, 0).toFixed(2)
        };
    }

    getPillColor(): string {
        return this.excessAssets >= 0 ? COLOR_EXCESS_ASSETS : COLOR_PROJECTED_ASSETS;
    }
}