import {Col, Row} from "react-grid-system";
import {BarchartUnderlay} from "../../ClientManagement/AssetReliance/BarChart/BarChartUnderlay";
import React from "react";
import {CHART_CELL_WIDTH,  TITLE_CELL_WIDTH} from "./ComparePlansContent";
import { hasPositiveExcessAssets} from "../../ClientManagement/AssetReliance/AssetRelianceUtil";
import ChartStatusLine from "../../ClientManagement/AssetReliance/ChartStatusLine";
import {COLOR_GOALS, COLOR_NT_ORANGE_800} from "../../constants/colors";
import {StackedBarChart} from "../../components";
import ExcessAssetsVerticalLine from "../../ClientManagement/AssetReliance/ExcessAssetsVerticalLine";
import _ from "lodash";
import {roundUpToNext} from "../../utils/format";
import ComparePlanAssetBarChartColumn
    from "../../ClientManagement/AssetReliance/BarChart/ComparePlanAssetBarChartColumn";

type ComparePlansBarchartContainerProps = {
    netAssets: number,
    barChartColWidth: number,
    totalStacks: number,
    totalGoals: number,
    excessAssets: number
}

export const ComparePlansBarchartContainer: React.FC<ComparePlansBarchartContainerProps> = ({
                                                                                                netAssets,
                                                                                                barChartColWidth,
                                                                                                totalStacks,
                                                                                                totalGoals,
                                                                                                excessAssets
                                                                                            }) => {
    const minimumValueToApplyGraphRounding = 1_000_000;
    let barchartMaxValue = totalGoals >= netAssets ? totalGoals : netAssets;

    const chartUnderlayMaxValue = (barchartMaxValue >= minimumValueToApplyGraphRounding)
        ? roundUpToNext(barchartMaxValue, 5)
        : 1_000_000;
    return (
        <>
            <Row>
                <Col className="asset-reliance-chart-container">
                    <BarchartUnderlay totalStacksCount={totalStacks}
                                      width={barChartColWidth}
                                      maxValue={chartUnderlayMaxValue}
                                      titleCellWidth={TITLE_CELL_WIDTH}
                                      stackContainerWidth={CHART_CELL_WIDTH}
                                      numberOfColumns={6}
                                      minimumColumn={0}
                                      rightBorderDivider={true}
                    />
                    <span className="divider"></span>
                    <Row data-testid="AssetRelianceBarchartRow"
                         className="asset-reliance-barchart-row"
                         style={{height: "100%"}}>
                        {_.range(totalStacks).map((index) => {
                            const isAssetSufficient: boolean = hasPositiveExcessAssets(excessAssets);
                            const assetBarChart = new ComparePlanAssetBarChartColumn(netAssets, totalGoals, excessAssets, chartUnderlayMaxValue, isAssetSufficient)

                            const columnHeightPercentage = (assetBarChart.getColumnMaxValue() / chartUnderlayMaxValue * 100).toFixed(2);

                            return <React.Fragment key={`ExcludedAssetBarChart` + index}>
                                <Col md={CHART_CELL_WIDTH}

                                     style={{height: `${columnHeightPercentage}%`, alignSelf: "flex-end"}}

                                >
                                    <ChartStatusLine label=''
                                                     width={`${CHART_CELL_WIDTH}px`}
                                                     verticalOffset={assetBarChart.getGoalsPositionVerticalOffset()}
                                                     lineColor={COLOR_GOALS}
                                                     marginLeft={405}
                                                     textColor={COLOR_NT_ORANGE_800}
                                                     id={"barChartOverlay"}
                                                     isAssetReliance={true}
                                    />
                                    <div data-testid={"AssetRelianceStackBarchart"} className="compareplan_barchart-container">
                                            <StackedBarChart
                                                showLabels={false}
                                                chartWidth={CHART_CELL_WIDTH}
                                                data={assetBarChart.getChartItems()}
                                            />
                                            <ExcessAssetsVerticalLine color={assetBarChart.getPillColor()}
                                                                      bottomPercentage={assetBarChart.getVerticalLineMargins().bottomMargin}
                                                                      excessAssets={excessAssets}
                                                                      topPercentage={assetBarChart.getVerticalLineMargins().topMargin}
                                                                      classname={"compare-plans-asset-line"}
                                            />


                                    </div>
                                </Col>
                            </React.Fragment>
                        })}
                    </Row>
                </Col>
            </Row>

        </>
    )
}
