import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {PresentationPaneHeader} from "../../components";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {
    deleteFamilyGoal,
    deleteNonLifestyleGoal,
    selectGoalModel,
    setNonLifestyleGoalChanged
} from "../controller/GoalsModelSlice";
import {getPortfolioAlignedGoalsAndLiabilitiesValue} from "../../Portfolio/portfolioUtils";
import {InvestableInsufficiencyCard} from "./InvestableInsufficiencyCard";
import {LifestyleGoalTable} from "./LifestyleGoalTable";
import React, {useMemo, useState} from "react";
import {GoalType, NonLifestyleGoalType, PrioritizationType, UpdateNonLifestyleGoalType} from "../models/GoalType";
import NonLifestyleGoalsPrioritizationTable from "./NonLifestyleGoalsPrioritizationTable";
import PrioritizationUtils from "./PrioritizationUtils";
import NonLifeStyleGoalsFBNITable from "./NonLifeStyleGoalsFBNITable";
import {AssetAllocationCard} from "./AssetAllocationCard";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {emptyFamilyGoal, emptyNonLifestyleGoal} from "../TestUtils";
import {FamilyGoalType} from "../models/FamilyGoalType";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";

export interface NonLifestyleGoalRow {
    name: string,
    goalType?: GoalType,
    annualFlow?: number,
    yearsOfFlow: number,
    yearsUntilFlow: number,
    presentValue: number,
    beneficiaryName: string,
    isFundedByNonInvestableAssets: boolean,
    savedGoal?: NonLifestyleGoalType,
    fundedByPortfolioPercentage?: number
}

export interface FundedByPortfolioPercentageAttributes {
    breakEvenYear: number,
    breakEvenYearTotalPV: number,
    remainingAssetsForBreakEvenYear: number
}


const Prioritization = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const goalModel = useAppSelector<GoalModelType>(selectGoalModel);
    const displayName = useProfileAndProposals(id).approvedProfile.displayName;

    const initialAllGoal: NonLifestyleGoalRow[] = PrioritizationUtils.makeNonLifestyleGoalListTableRowData(goalModel.nonLifestyleGoals, [...Object.values(goalModel.familyGoals)], goalModel.investorGroup)
    const [allGoals, setAllGoals] = useState<NonLifestyleGoalRow[]>(initialAllGoal);
    const [allGoalsForAAChangeStr, setAllGoalsForAAChangeStr] = useState<string>(JSON.stringify(initialAllGoal));

    const fundedByPortfolioGoals: NonLifestyleGoalRow[] = useMemo(() => {
        return allGoals.filter(s => !s.isFundedByNonInvestableAssets);
    }, [allGoals])

    const {needs, totalNeedsPresentValue, availableAssetsForNeeds} = useMemo(() => {
        return getNeeds(fundedByPortfolioGoals, goalModel);
    }, [fundedByPortfolioGoals, goalModel])
    const wants = useMemo(() => {
        return getWants(fundedByPortfolioGoals, availableAssetsForNeeds, totalNeedsPresentValue);
    }, [fundedByPortfolioGoals, availableAssetsForNeeds, totalNeedsPresentValue, goalModel])
    let fundedByNonInvestables: NonLifestyleGoalRow[] = PrioritizationUtils.sortNonLifeStyleGoalList(allGoals.filter(s => s.isFundedByNonInvestableAssets))

    fundedByNonInvestables = PrioritizationUtils.addFbniLifestyleGoal(goalModel, fundedByNonInvestables);

    const totalPortfolioAssetsValue = goalModel.assets.totalInvestableValue;
    const portfolioAlignedGoalAndTaxLiabilitiesValue = getPortfolioAlignedGoalsAndLiabilitiesValue({
        ...goalModel,
        lifestylePresentValue: goalModel.lifestyleSpendingGoal.calculatedFields
    });
    const isInvestablySufficient: boolean = (totalPortfolioAssetsValue - portfolioAlignedGoalAndTaxLiabilitiesValue) >= 0;

    const profile = useAppSelector(selectProfile);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const activePortfolioReserve = profile.activePortfolioReserve;
    let activePortfolioReserveHeader = activePortfolioReserve ? 'The Portfolio Reserve is on' : '';

    const handleDeleteDropDownOnClick = (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => {
        if (updatedNonLifestyleGoal.goalType === GoalType.FAMILY && updatedNonLifestyleGoal.beneficiaryId !== undefined) {
            const nonLifestyleGoal: FamilyGoalType = {
                ...emptyFamilyGoal,
                id: updatedNonLifestyleGoal.id,
                beneficiaryId: updatedNonLifestyleGoal.beneficiaryId
            }
            dispatch(deleteFamilyGoal({goal: nonLifestyleGoal}))
            setAllGoals(allGoals.filter(row => row.savedGoal?.id !== updatedNonLifestyleGoal.id))
        } else {
            const nonLifestyleGoal: NonLifestyleGoalType = {
                ...emptyNonLifestyleGoal,
                id: updatedNonLifestyleGoal.id,
                goalType: updatedNonLifestyleGoal.goalType
            }
            dispatch(deleteNonLifestyleGoal({goal: nonLifestyleGoal}))
            setAllGoals(allGoals.filter(row => row.savedGoal?.id !== updatedNonLifestyleGoal.id))
        }
    }

    const handleDropDownOnClick = (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => {
        setAllGoals(allGoals.map(row => {
            if (row.savedGoal!.id === updatedNonLifestyleGoal.id) {
                return Object.assign(row, {
                    ...row,
                    savedGoal: {...row.savedGoal, prioritizationType: updatedNonLifestyleGoal.prioritizationType}
                })
            }
            return row
        }));

        if (!isInvestablySufficient && activePortfolioReserve) {
            setAllGoalsForAAChangeStr(JSON.stringify(allGoals));
        }
        dispatch(setNonLifestyleGoalChanged(true))
    }

    const memoizedNeedsTable = useMemo(() => {
        return <NonLifestyleGoalsPrioritizationTable goalsForDisplay={needs}
                                                     tableClassName={(needs.length === 1) ? 'goals-table-single-row' : 'goals-table'}
                                                     profileId={id}
                                                     name="needs"
                                                     displayProgressBar={profile.activePortfolioReserve}
                                                     handleDropDownOnClick={handleDropDownOnClick}
                                                     handleDeleteOnClick={handleDeleteDropDownOnClick}
                                                     isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}/>;
    }, [needs, id, profile, isProfileWithProposalsOrArchived]);

    const memoizedWantsTable = useMemo(() => {
        return <NonLifestyleGoalsPrioritizationTable goalsForDisplay={wants}
                                                     tableClassName={(wants.length === 1) ? 'goals-wants-table-single-row' : 'goals-wants-table'}
                                                     profileId={id}
                                                     name="wants"
                                                     displayProgressBar={profile.activePortfolioReserve}
                                                     handleDropDownOnClick={handleDropDownOnClick}
                                                     handleDeleteOnClick={handleDeleteDropDownOnClick}
                                                     isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}/>;
    }, [wants, id, profile, isProfileWithProposalsOrArchived]);

    const fbniTable = (fundedByNonInvestables.length === 1) ?
        <NonLifeStyleGoalsFBNITable goalsForDisplay={fundedByNonInvestables}
                                    tableClassName={'goals-fbni-table-single-row'}
                                    displayProgressBar={activePortfolioReserve}/> :
        <NonLifeStyleGoalsFBNITable goalsForDisplay={fundedByNonInvestables}
                                    tableClassName={'goals-fbni-table'}
                                    displayProgressBar={activePortfolioReserve}/>

    return (
        <ScrollableContainer id='prioritization-page-scroll-container' className="prioritization-page">
            <PresentationPaneHeader displayName={displayName} title="Prioritize Your Goals"/>
            <div className="activated">
                {!isInvestablySufficient && activePortfolioReserve &&
                    <span><i data-testid="reliant-on-market-recov-icon"
                             className="icons dds-icons icon--size-18 icon--warning paddingright-md">error</i></span>}
                <span>{activePortfolioReserveHeader}</span>
            </div>
            <div className="goal-prioritization-cards">
                <InvestableInsufficiencyCard
                    totalPortfolioAssetsValue={totalPortfolioAssetsValue}
                    portfolioAlignedGoalAndTaxLiabilitiesValue={portfolioAlignedGoalAndTaxLiabilitiesValue}
                    activePortfolioReserve={activePortfolioReserve}
                />
                {
                    useMemo(
                        () => {
                            return <AssetAllocationCard allGoalsForAAChangeStr={allGoalsForAAChangeStr}/>
                        }, [allGoalsForAAChangeStr, activePortfolioReserve, allGoals])
                }
            </div>
            <div className={"goal-prioritization-container"}>
                <div className={'goal-prioritization-container title'}>{"Core Lifestyle"}</div>
                {
                    useMemo(() => {
                        return <LifestyleGoalTable displayProgressBar={activePortfolioReserve}/>
                    }, [activePortfolioReserve])
                }
            </div>

            <div className={"goal-prioritization-container"}>
                <div className={'goal-prioritization-container title'}>{"Needs"}</div>
                {needs.length <= 0 ?
                    <div className={'goal-prioritization-container no-goals-warning'}
                         data-testid={"goal-fundedByPortfolioGoals-list"}>There are currently no discretionary,
                        philanthropic, or family
                        goals entered.</div>
                    : memoizedNeedsTable
                }
            </div>
            <div className={"goal-prioritization-container"}>
                <div className={'goal-prioritization-container title'}>{"Wants"}</div>
                {wants.length <= 0 ?
                    <div className={'goal-prioritization-container no-goals-warning'}
                         data-testid={"goal-wants-list"}>There are no wants currently.</div>
                    : memoizedWantsTable
                }
            </div>
            <div className={"goal-prioritization-container"}>
                <div className={'goal-prioritization-container title'}>{"Funded by Non-Investable Assets"}</div>
                {fundedByNonInvestables.length <= 0 ?
                    <div className={'goal-prioritization-container no-goals-warning'}
                         data-testid={"goal-fbni-list"}>There are currently no goals funded by Non-Investable
                        Assets.</div>
                    : fbniTable
                }
            </div>
        </ScrollableContainer>
    );
}

function getNeeds(fundedByPortfolioGoals: NonLifestyleGoalRow[], goalModel: GoalModelType) {
    let needs: NonLifestyleGoalRow[] = PrioritizationUtils.sortNonLifeStyleGoalList(fundedByPortfolioGoals.filter(s => s.savedGoal!.prioritizationType === PrioritizationType.NEEDS))
    const totalNeedsPresentValue = PrioritizationUtils.getNonLifestyleGoalTotalPresentValue(needs);
    const availableAssetsForNeeds = goalModel.assets.totalInvestableValue - goalModel.taxLiabilities.totalTaxLiabilitiesFundedByPortfolio - goalModel.lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue;
    needs = addFundedByPortfolioPercentageToNeedsOrWants(needs, availableAssetsForNeeds, totalNeedsPresentValue);
    return {needs, totalNeedsPresentValue, availableAssetsForNeeds};
}

function getWants(fundedByPortfolioGoals: NonLifestyleGoalRow[], availableAssetsForNeeds: number, totalNeedsPresentValue: number) {
    let wants: NonLifestyleGoalRow[] = PrioritizationUtils.sortNonLifeStyleGoalList(fundedByPortfolioGoals.filter(s => s.savedGoal!.prioritizationType === PrioritizationType.WANTS))
    const totalWantsPresentValue = PrioritizationUtils.getNonLifestyleGoalTotalPresentValue(wants);
    const availableAssetsForWants = availableAssetsForNeeds - totalNeedsPresentValue;
    return addFundedByPortfolioPercentageToNeedsOrWants(wants, availableAssetsForWants, totalWantsPresentValue);
}

function addFundedByPortfolioPercentageToNeedsOrWants(nonLifestyleGoalRows: NonLifestyleGoalRow[], availableAssets: number, totalPVPerPrioritizationType: number): NonLifestyleGoalRow[] {
    if (availableAssets <= 0) {
        return nonLifestyleGoalRows.map(nonLifestyleGoalRow => {
            nonLifestyleGoalRow.fundedByPortfolioPercentage = 0;
            return nonLifestyleGoalRow
        })
    }
    if (totalPVPerPrioritizationType > availableAssets) {
        const fundedByPortfolioPercentageAttr = PrioritizationUtils.getFundedByPortfolioPercentageAttributes(nonLifestyleGoalRows, availableAssets);
        return PrioritizationUtils.updateNonLSRowsWithFundedByPortfolioPercentage(nonLifestyleGoalRows, fundedByPortfolioPercentageAttr)
    }

    return nonLifestyleGoalRows.map(nonLifestyleGoalRow => {
        nonLifestyleGoalRow.fundedByPortfolioPercentage = 100;
        return nonLifestyleGoalRow
    })
}

export default Prioritization