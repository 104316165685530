import React, {useEffect, useState} from "react";
import WealthPlanOptimizerContent from "./WealthPlanOptimizerContent";
import {Route, Switch, useParams} from "react-router-dom";
import AddEditStateOfResidency from "./AddEditStateOfResidency";
import AddEditAnnualGifting from "./AddEditAnnualGifting";
import {useAppDispatch} from "../store/hooks";
import {
    resetWealthPlanOptimizerState,
    setWealthPlanOptimizerState,
    WealthPlanOptimizerState
} from "./WealthPlanOptimizerSlice";
import {wealthPOApiClient} from "./WealthPOApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";
import {RouteWithId} from "../routes/types";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import {EmptyStateContainer} from "../components";
import {initialStateOfResidencyStrategy, StrategyDetailsSectionInfo, StrategyType} from "./WealthPOTypes";
import AddEditGiftToGrantorTrust from "./AddEditGiftToGrantorTrust";
import {buildStrategyDetailsList} from "./WealthPOUtils";
import AddEditGRAT from "./AddEditGRAT";

const WealthPlanOptimizer = () => {
    const {id} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false)
    const [strategyDetailsSectionInfoList, setStrategyDetailsSectionInfoList] = useState<StrategyDetailsSectionInfo[]>([]);

    const fetchStrategiesSummary = () => {
        setIsLoading(true);

        wealthPOApiClient.getWealthPOSummary(id).then(wealthPOSummaryResponse => {
            if (wealthPOSummaryResponse.stateOfResidencyStrategy == null) {
                wealthPOSummaryResponse.stateOfResidencyStrategy = initialStateOfResidencyStrategy;
            }

            dispatch(setWealthPlanOptimizerState({
                wealthPOSummaryResponse,
            } as WealthPlanOptimizerState))

            const {strategyDetailsList} = buildStrategyDetailsList(
                wealthPOSummaryResponse.stateOfResidencyStrategy,
                wealthPOSummaryResponse.annualGiftingStrategies,
                wealthPOSummaryResponse.giftToGrantorTrustStrategies,
                wealthPOSummaryResponse.gratStrategies
            )
            setStrategyDetailsSectionInfoList(strategyDetailsList);
            setIsLoading(false);
        }).catch((error) => {
            if (error.status === 404) {
                setStrategyDetailsSectionInfoList([]);
                dispatch(resetWealthPlanOptimizerState());
            } else {
                openErrorModal(error)
            }

            setIsLoading(false);
        });
    };

    const openErrorModal = (error: Error | any) => {
        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load Wealth Plan Optimizer (${error.status})`,
            operationId: error.headers.get('trace-id')
        });
    };

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();
        fetchStrategiesSummary();
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    useEffect(() => {
        fetchStrategiesSummary();

        return () => {
            setStrategyDetailsSectionInfoList([]);
            dispatch(resetWealthPlanOptimizerState());
        }
    }, [id])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    if (genericError.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        );
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        );
    }

    return (
        <Switch>
            <Route exact path="/Profile/:id/ClientProfile/WealthPlanOptimizer/Summary">
                <WealthPlanOptimizerContent refreshStrategiesSummary={fetchStrategiesSummary}
                                            strategyDetailsList={strategyDetailsSectionInfoList}
                                            isContinued={false}
                                            isInReportPreview={false}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddStateOfResidency">
                <AddEditStateOfResidency refreshStrategiesSummary={fetchStrategiesSummary} header={"Add State of Residency Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditStateOfResidency/:strategyId">
                <AddEditStateOfResidency refreshStrategiesSummary={fetchStrategiesSummary} header={"Edit State of Residency Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddAnnualGifting">
                <AddEditAnnualGifting refreshStrategiesSummary={fetchStrategiesSummary} header={"Add Annual Gifting Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditAnnualGifting/:strategyId">
                <AddEditAnnualGifting refreshStrategiesSummary={fetchStrategiesSummary} header={"Edit Annual Gifting Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddGiftToGrantorTrust">
                <AddEditGiftToGrantorTrust header={"Add Gift to Grantor Trust"} refreshStrategiesSummary={fetchStrategiesSummary}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditGiftToGrantorTrust/:strategyId">
                <AddEditGiftToGrantorTrust header={"Edit Gift to Grantor Trust"} refreshStrategiesSummary={fetchStrategiesSummary}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddTwoYearGRAT">
                <AddEditGRAT strategyType={StrategyType.TWO_YEAR_GRAT} header={"Add 2-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditTwoYearGRAT/:strategyId">
                <AddEditGRAT strategyType={StrategyType.TWO_YEAR_GRAT} header={"Edit 2-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddFiveYearGRAT">
                <AddEditGRAT strategyType={StrategyType.FIVE_YEAR_GRAT} header={"Add 5-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditFiveYearGRAT/:strategyId">
                <AddEditGRAT strategyType={StrategyType.FIVE_YEAR_GRAT} header={"Edit 5-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
        </Switch>
    )
}

export default WealthPlanOptimizer;
