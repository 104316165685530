import React from 'react';
import classNames from 'classnames';
import {Button} from "../Button";

type NavDrawerSideMenuProps = {
    className?: string,
    children: React.ReactNode,
    isOpen: boolean | undefined,
    onRequestClose: () => void,
    title: string | undefined,
    isSharePointDocs?: boolean,
    hRef?: string,
    onRefreshSharePointDocs: () => void,
    onRefreshSeismicDocs: () => void
}

export const NavDrawerSideMenu = ({
                                      children,
                                      className,
                                      isOpen,
                                      onRequestClose,
                                      title,
                                      isSharePointDocs=false,
                                      hRef="",
                                      onRefreshSharePointDocs,
                                      onRefreshSeismicDocs
                                  }: NavDrawerSideMenuProps) => {


    return (
        <div
            className={classNames(
                'nav-drawer-side-menu',
                { 'nav-drawer-side-menu--open': isOpen, 'nav-drawer-side-menu--closed': !isOpen },
                'custom-scrollbar',
                'custom-scrollbar--bg-grey-050',
                className
            )}
        >
            <div className="nav-drawer-side-menu__container">
                <div className= {`${isSharePointDocs ? 'nav-drawer-side-menu__header-share-point':'nav-drawer-side-menu__header'}`}>
                    <span className="nav-drawer-side-menu__title">{title}</span>
                    <Button
                        className="nav-drawer-side-menu__close-button nav-drawer-side-menu__refresh-button"
                        icon="only"
                        iconName="refresh"
                        kind="borderless"
                        size="small"
                        onClick={(title === "Client Documents" || title === "My Documents") ? onRefreshSharePointDocs : onRefreshSeismicDocs}
                    />
                    <Button
                        className="nav-drawer-side-menu__close-button"
                        icon="only"
                        iconName="close"
                        kind="borderless"
                        size="small"
                        onClick={onRequestClose}
                    />
                </div>
                {isSharePointDocs && <div>
                    <a
                        className="nav-drawer-side-menu__share-point-link"
                        href={hRef}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        OPEN SHAREPOINT
                        <i className="icon dds-icons marginleft-sm">
                            launch
                        </i>
                    </a>
                </div>}
                <div className= {`${isSharePointDocs ? 'nav-drawer-side-menu__content-share-point':'nav-drawer-side-menu__content'}`}>{children}</div>
            </div>
        </div>
    )
}
