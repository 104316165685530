import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {HandleSaveResponse, StrategyHeader} from "./components/StrategyHeader";
import {StrategySidebar} from "./components/StrategySidebar";
import {DataEntrySummaryItem} from "../components/DataEntry/DataEntrySummary";
import {
    CurrencyInput,
    EmptyStateContainer,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../components";
import {
    formatCurrency,
    formatNumber,
    formatNumberRoundedToTwoDecimals,
    formatPercentWithWholeNumberAndTwoDecimals,
    formatPercentWithWholeNumberAndTwoDecimalsDisplayPercent
} from "../utils/format";
import PercentInput from "../components/Input/PercentInput";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import {
    EstimatedImpact,
    FailedAPICall,
    GRATEstimatedImpactRequestDTO,
    GRATRates,
    GRATStrategy,
    initialEstimatedImpact,
    initialFiveYearGRATStrategy,
    initialTwoYearGRATStrategy,
    PayoutPercentagesRequestDTO,
    StrategyType,
} from "./WealthPOTypes";
import useProfileEditableState from "../hooks/useProfileEditableState";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import moment from "moment";
import {selectGRATStrategies} from "./WealthPlanOptimizerSlice";
import {useAppSelector} from "../store/hooks";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {validateDescription} from "./WealthPOUtils";

export interface AddEditGRATProps {
    strategyType: StrategyType,
    header: string,
    refreshStrategiesSummary: () => void
}

enum GRATInputTypes {
    INCREASE_IN_PAYMENT
}

const AddEditGRAT: React.FC<AddEditGRATProps> = ({
                                                     strategyType,
                                                     header,
                                                     refreshStrategiesSummary
                                                 }) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();

    const strategies = useAppSelector(selectGRATStrategies)
    let strategy: GRATStrategy = {
        strategyId: strategyId,
        strategyType: strategyType,
        description: "",
        contributionAmount: 0,
        increaseInPayment: 20,
        annuityRate: 0,
        estimatedImpact: initialEstimatedImpact,
        enabled: true
    };

    switch (strategyType){
        case StrategyType.TWO_YEAR_GRAT:
            strategy = initialTwoYearGRATStrategy
            break;
        case StrategyType.FIVE_YEAR_GRAT:
            strategy = initialFiveYearGRATStrategy
            break;
        default:
            break;
    }

    if (strategyId) {
        const foundStrategy = strategies.find(s => s.strategyId === strategyId);
        if (foundStrategy) {
            strategy = foundStrategy;
        }
    }

    const [description, setDescription] = useState<string>(strategy.description);
    const [contributionAmountDisplayValue, setContributionAmountDisplayValue] = useState<number>(strategy.contributionAmount);
    const [contributionAmount, setContributionAmount] = useState<number>(strategy.contributionAmount);
    const [increaseInPaymentDisplayValue, setIncreaseInPaymentDisplayValue] = useState<string>(strategy.increaseInPayment.toString());
    const [increaseInPayment, setIncreaseInPayment] = useState<number>(strategy.increaseInPayment);
    const [annuityRateDisplayValue, setAnnuityRateDisplayValue] = useState<string>("0.00");
    const [annuityRate, setAnnuityRate] = useState<number | undefined>(undefined);
    const [referenceDataHurdleRate, setReferenceDataHurdleRate] = useState<string>("0.00")
    const [riskAssetGrowthRate, setRiskAssetGrowthRate] = useState<string>("");
    const [payoutPercentages, setPayoutPercentages] = useState<number[]>([])
    const [error, setError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);
    const [estimatedImpact, setEstimatedImpact] = useState<EstimatedImpact>(strategy.estimatedImpact)
    const [validationError, setValidationError] = useState<boolean>(false);
    const [inputError, setInputError] = useState<GRATInputTypes | undefined>(undefined)

    const [initialFormUpdated, setInitialFormUpdated] = useState<boolean>(false);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined);
    const [failedApiCall, setFailedApiCall] = useState<FailedAPICall>();

    const handleIncreaseInPaymentOnBlur = () => {
        let newValue = Number(increaseInPaymentDisplayValue)

        if (newValue > 20) {
            setInputError(GRATInputTypes.INCREASE_IN_PAYMENT)
            setIncreaseInPaymentDisplayValue(increaseInPayment.toString())
            return;
        }

        setIncreaseInPayment(newValue);
        if (!initialFormUpdated) {
            setInitialFormUpdated(true);
        }
    }

    const handleSave = async (): Promise<HandleSaveResponse> => {
        setValidationError(false)

        const valid = validateDescription(description);

        let response = {
            isSuccess: valid,
            errors: {
                hasValidationError: false,
                hasCommunicationError: false,
                traceId: ""
            }
        } as HandleSaveResponse

        if (!valid) {
            setValidationError(true)

            response.isSuccess = false
            response.errors.hasValidationError = true
            return response;
        } else {
            let requestBody = {
                strategyType: strategyType,
                contributionAmount,
                description,
                increaseInPayment,
                annuityRate
            } as GRATStrategy

            try {
                if (strategyId) {
                    requestBody = {
                        ...requestBody,
                        strategyId
                    }

                    await wealthPOApiClient.editGRATStrategy(id, requestBody);
                } else {
                    await wealthPOApiClient.createGRATStrategy(id, requestBody);
                }
            } catch (saveError: Error | any) {
                response.isSuccess = false;
                response.errors.hasCommunicationError = true;
                response.errors.traceId = saveError.headers.get('trace-id');
            }

            return response;
        }
    }

    const fetchReferenceData = () => {
        wealthPOApiClient.getGRATRatesReferenceData(id)
            .then((gratRates: GRATRates) => {
                const riskAssetRateDto = gratRates.growthRates.rateDtos.find(rateDto => rateDto.id === "RiskAsset");
                if (riskAssetRateDto) {
                    const growthRate = riskAssetRateDto.value * 100;
                    const s = growthRate.toPrecision(3);
                    setRiskAssetGrowthRate(s)
                }

                // Hardcoding to 5.0 according to current (12/9) AC. In future will use rate from reference data: gratRates.hurdleRate
                const formattedReferenceDataHurdleRate = formatPercentWithWholeNumberAndTwoDecimals(0.050, false);
                const referenceDataHurdleRateWithPercent = formatPercentWithWholeNumberAndTwoDecimalsDisplayPercent(0.050)
                let existingStrategyAnnuityRate = formatNumber(strategy.annuityRate)

                if (!strategyId) {
                    setAnnuityRate(Number(formattedReferenceDataHurdleRate));
                    setAnnuityRateDisplayValue(formattedReferenceDataHurdleRate);
                } else {
                    setAnnuityRate(Number(existingStrategyAnnuityRate));
                    setAnnuityRateDisplayValue(existingStrategyAnnuityRate);
                }

                setReferenceDataHurdleRate(referenceDataHurdleRateWithPercent)
            })
            .catch(err => {
                setFailedApiCall(FailedAPICall.REFERENCE_DATA);
                openErrorModal(err)
            })
    }

    const fetchPayoutPercentages = () => {
        if (annuityRate) {
            const payoutPercentagesRequestDTO = {
                contributionAmount,
                increaseInPayment,
                annuityRate,
                strategyType
            } as PayoutPercentagesRequestDTO

            wealthPOApiClient.getPayoutPercentages(id, payoutPercentagesRequestDTO)
                .then(payoutPercentagesResponseDTO => {
                    setPayoutPercentages(payoutPercentagesResponseDTO.payoutPercentages)
                })
                .catch(err => {
                    setFailedApiCall(FailedAPICall.PAYOUT_PERCENTAGES);
                    openErrorModal(err)
                })
        }
    }

    const getEstimatedImpact = (requestBody: GRATEstimatedImpactRequestDTO, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForGRAT(id, requestBody, signal);
    }

    const fetchEstimatedImpact = async () => {
        estimatedImpactAbortControllerRef.current = new AbortController();
        const {signal} = estimatedImpactAbortControllerRef.current;

        getEstimatedImpact({
            strategyId: undefined,
            strategyType: StrategyType.TWO_YEAR_GRAT,
            contributionAmount: contributionAmount,
            payoutPercentages: payoutPercentages,
            enabled: true
        }, signal)
            .then(estimatedImpactResponse => {
                if(strategy.strategyType === StrategyType.TWO_YEAR_GRAT){
                    setEstimatedImpact(estimatedImpactResponse);
                }
            })
            .catch((err) => {
                setFailedApiCall(FailedAPICall.ESTIMATED_IMPACT);
                openErrorModal(err);
            });
    }

    useEffect(() => {
        fetchReferenceData();
    }, [id])

    useEffect(() => {
        if (!initialFormUpdated) {
            setInitialFormUpdated(true)
        }
        fetchPayoutPercentages();
    }, [contributionAmount, increaseInPayment, annuityRate])

    useEffect(() => {
        estimatedImpactAbortControllerRef.current = undefined;

        if (initialFormUpdated) {
            fetchEstimatedImpact().then();
        }

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [payoutPercentages])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setInputError(undefined);
        }, 6000);
        return () => {
            clearTimeout(timeout);
        }
    }, [inputError]);

    const openErrorModal = (e: Error | any) => {
        setError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load ${header} page (${e.status})`,
            operationId: e.headers.get('trace-id')
        })
    }

    const closeErrorModal = () => {
        setError({...error, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();
        switch (failedApiCall) {
            case(FailedAPICall.REFERENCE_DATA):
                fetchReferenceData();
                break;
            case(FailedAPICall.PAYOUT_PERCENTAGES):
                fetchPayoutPercentages();
                break;
            case(FailedAPICall.ESTIMATED_IMPACT):
                fetchEstimatedImpact().then();
                break;
        }
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    const memoizedErrorMessage = useMemo(() => {
        let errorMessage;

        switch (inputError) {
            case GRATInputTypes.INCREASE_IN_PAYMENT:
                errorMessage = "Increase in payment cannot be more than 20%."
                break;
            default:
                errorMessage = ""
        }

        return inputError !== undefined && (
            <>
                <div></div>
                <div data-testid={`error-message-${inputError}`}
                     className="strategy-time-frame-error-msg">
                    {errorMessage}
                </div>
            </>
        )
    }, [inputError])

    const memoizedStrategySummary = useMemo(() => {
        return [
            {
                label: "Contribution Amount",
                value: formatCurrency(contributionAmount),
                testId: "contributionAmount"
            } as DataEntrySummaryItem,
            {
                label: "% Increase in Payment",
                value: `${formatNumberRoundedToTwoDecimals(increaseInPayment)}%`,
                testId: "increaseInPayment"
            } as DataEntrySummaryItem,
            {
                label: "Annuity Rate",
                value: `${formatNumberRoundedToTwoDecimals(annuityRate ? annuityRate : 0)}%`,
                testId: "annuityRate"
            } as DataEntrySummaryItem
        ]
    }, [contributionAmount, increaseInPayment, annuityRate])

    const memoizedEstimatedImpact: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Amount to Beneficiaries",
                value: (estimatedImpact.amountToBeneficiaries !== undefined) ? formatCurrency(estimatedImpact.amountToBeneficiaries) : undefined,
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "estEstateTax",
            } as DataEntrySummaryItem,
        ]
    }, [estimatedImpact])

    if (error.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={error}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        )
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        )
    }

    return (
        <ScrollableContainer id={"add-edit-grat-scroll-container"} className="wealth-plan-optimizer">
            <StrategyHeader
                header={header}
                handleSave={handleSave}
                handleCancel={() => false}
                refreshStrategySummary={refreshStrategiesSummary}
            />

            <div className="wealthpo-strategy__form">
                <article>
                    <section aria-label="Strategy Details">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Details"
                            rightAlignedContent={<RequiredFieldsSubheader/>}
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'description'}>Description<RedAsterisk/></label>
                            <Input
                                name="descriptionField"
                                aria-label="description"
                                aria-labelledby="descriptionFieldInput-label"
                                id="descriptionFieldInput"
                                removeMarginTop
                                size="medium"
                                type="text"
                                value={description}
                                readOnly={isProfileWithProposalsOrArchived}
                                error={validationError ? "Description is required." : undefined}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'contributionAmountInput'}>Contribution Amount</label>
                            <div className="annual-flow-input-field">
                                <CurrencyInput
                                    name="contributionAmountField"
                                    aria-label="contributionAmount"
                                    id="contributionAmountFieldInput"
                                    size="medium"
                                    value={contributionAmountDisplayValue}
                                    readOnly={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChangeValue={e => isNaN(parseInt(e.target.value)) ? setContributionAmountDisplayValue(0) : setContributionAmountDisplayValue(parseInt(e.target.value))}
                                    onBlur={() => {
                                        setContributionAmount(contributionAmountDisplayValue);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'increaseInPaymentInput'}>% Increase in Payment</label>
                            <div className="percent-input-field">
                                <PercentInput
                                    hideLabel
                                    aria-label="increaseInPayment"
                                    label="increaseInPayment"
                                    size="medium"
                                    defaultValue={"20"}
                                    value={increaseInPaymentDisplayValue}
                                    disabled={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChange={e => setIncreaseInPaymentDisplayValue(e)}
                                    onBlur={handleIncreaseInPaymentOnBlur}
                                />
                            </div>
                        </div>
                        {(inputError !== undefined && inputError === GRATInputTypes.INCREASE_IN_PAYMENT) && memoizedErrorMessage}
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'annuityRateInput'}>Annuity Rate</label>
                            <div className="percent-input-field">
                                <PercentInput
                                    hideLabel
                                    aria-label="annuityRate"
                                    label="annuityRate"
                                    size="medium"
                                    defaultValue={annuityRateDisplayValue}
                                    value={annuityRateDisplayValue}
                                    disabled={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChange={e => setAnnuityRateDisplayValue(e)}
                                    onBlur={() => {
                                        setAnnuityRate(Number(annuityRateDisplayValue));
                                    }}
                                />
                                <span
                                    className="subheader">7520 Hurdle Rate for month of {moment.months(moment().month())} {moment().year()}: {referenceDataHurdleRate}</span>
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"}>Growth Rate</label>
                            <div className="growth-rate-value">
                                <span aria-label={'growthRate'}>Triple Net Risk Asset ({riskAssetGrowthRate}%)</span>
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5 align-self-start"}>Payout Percentages</label>
                            <div className={"payout-percentages"}>
                                {payoutPercentages.map((value, index) => {
                                    const percentage = formatPercentWithWholeNumberAndTwoDecimals(value)
                                    return (
                                        <span
                                            key={index + 1}
                                            role="payout-percentage"
                                            aria-label="payout-percentage">{`Year ${index + 1} Annuity Rate: ${percentage}`}
                                    </span>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </article>

                <StrategySidebar
                    strategySummary={memoizedStrategySummary}
                    estimatedImpact={memoizedEstimatedImpact}
                />
            </div>

        </ScrollableContainer>
    )
}

export default AddEditGRAT;