import React from "react";


const ReviewFiduciariesForm: React.FC = () => {
    return (
        <div className="review-fiduciaries-header">
            <h4>
                Review Fiduciaries
            </h4>
        </div>
    )
}

export default ReviewFiduciariesForm;