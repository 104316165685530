import {RevocableTrust, TrustValidation} from "../EstateFlowchartTypes";

export const isValid = (value:  String) => {
    return !!(value && value.replace(/\s/g, '').length > 0);
}
export const validateTrustResponse = (trust: RevocableTrust): TrustValidation => {
    const revocableTrustValidation = {
        flowchartName: isValid(trust.flowchartName) ? null : "Flowchart Name is required.",
        legalName: isValid(trust.legalName) ? null : "Legal name is required",
        dateCreated: isValid(trust.trustCreationDate) ? null : "Date Created is required",
    }
    const revocableTrustHasErrors = Object.values(revocableTrustValidation).some((validation) => validation !== null)

    return (
        {revocableTrust: revocableTrustHasErrors ? revocableTrustValidation : null}
    );
}
