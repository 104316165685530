import React, {useState} from "react";
import AddFormHeader from "./components/AddFormHeader";
import {AlertMessage, Button} from "src/components";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId, RouteWithIdAndFlowchartId} from "../routes/types";
import BasicInformationContent from "./components/BasicInformationContent";
import {RevocableTrust, TrustValidation} from "./EstateFlowchartTypes";
import {estateFlowchartApiClient} from "./EstateFlowchartApiClient";
import {validateTrustResponse} from "./components/validations";

type AddRevocableTrustProps = {}

const subNavigation = [{
    name: "BasicInformation",
    displayName: "Basic Information"
}]

const RevocableTrustForm: React.FC<AddRevocableTrustProps> = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const [showErrorBanner, updateShowErrorBanner] = useState(false);
    const [saveHasBeenClicked, updateSaveHasBeenClicked] = useState(false);
    const [revocableTrust, updateRevocableTrust] = useState({} as RevocableTrust);
    const {flowchartId} = useParams<RouteWithIdAndFlowchartId>();
    const [activeView, setActiveView] = useState("BasicInformation" as String);

    const RevocableTrustAlert = () => {
        return (
            <div className="row-container">
                <AlertMessage
                    className="display-flex justify-content-right alertBanner"
                    id="alertBanner"
                    fullWidth={false}
                    icon="warning"
                    showAlert={true}
                    showCloseBtn={false}
                    type="error"
                >
                    <b className="alertBannerStyleName">Required fields missing.</b>
                    &nbsp;
                    <b className="font-weight-400">All required fields must be entered to save a flowchart.</b>
                </AlertMessage>
            </div>
        );
    }

    const checkFormIsValid = () => {
        const profileValidation = validateTrustResponse(revocableTrust as RevocableTrust)
        const isValid = Object.values(profileValidation).every(x => x === null);
        updateShowErrorBanner(!isValid);
        return isValid;
    }

    const handleCreateNewTrust = async (): Promise<boolean> => {
        /**
         * API POST to create new trust;
         */
        await estateFlowchartApiClient.createRevocableTrust(id, revocableTrust);
        return true;
    }

    const handleEditTrust =  async (): Promise<boolean> => {
        await estateFlowchartApiClient.editRevocableTrust(id, flowchartId, revocableTrust);
        return true;
    }

    const onSave = async () => {
        if (checkFormIsValid()) {
            let handleSave;
            if (flowchartId) {
                if(flowchartId === "new") {
                    handleSave = handleCreateNewTrust;
                } else {
                    handleSave = handleEditTrust;
                }
            } else {
                handleSave = handleCreateNewTrust;
            }

            handleSave().then((data) => {
                if (data) {
                    /*Perform actions after the SAVE call**/
                    history.push({
                        pathname: `/Profile/${id}/ClientProfile/EstateFlow`
                    })
                }
            });
        } else {
            updateShowErrorBanner(true);
            updateSaveHasBeenClicked(true);
        }
    }

    const handleMenuClick = (view: String) =>{
        setActiveView(view);
    }

    const activateMenuItem = (view : String) => {
        return view === activeView ? "sub-nav-item--active" : "sub-nav-item";
    }

    return (
        <div className="basic-information__form-container">
            <AddFormHeader title="Add Revocable Trust"/>
            <div className="revocable-trust-content">
                <div className="sub-nav-container">
                    <ul className="sub-nav-list" data-testid={'sub-nav-list'}>
                        {subNavigation.map((item, index) => (
                            <li className={activateMenuItem(item.name)} onClick={() => handleMenuClick(item.name)} key={index} data-testid={"sub-nav-list-item-"+ item.name}>{item.displayName}</li>
                        ))}
                    </ul>
                </div>
                <div className="revocable-trust-form">
                    {showErrorBanner && RevocableTrustAlert()}
                    <div className={showErrorBanner ? "form-container form-container-error" : "form-container"}>
                        {activeView==="BasicInformation" && <BasicInformationContent saveHasBeenClicked={saveHasBeenClicked}
                                                                                     updatedRevocableTrust={(newValue: RevocableTrust) => updateRevocableTrust(newValue)}/> }
                    </div>
                </div>
            </div>
            <div className="form-footer textalign-right">
                <Button
                    icon="none"
                    id="save_new_client_profile_button"
                    includeRef={false}
                    kind="primary"
                    onClick={onSave}
                    size="medium"
                    tabIndex={0}
                    type="button"
                >
                    SAVE
                </Button>
            </div>
        </div>
    )
}

export default RevocableTrustForm;