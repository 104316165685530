import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {
    formatCurrency,
    formatCurrencyFve,
    formatPercentWithWholeNumberAndTwoDecimals,
    formatPercentWithWholeNumberAndTwoDecimalsDisplayPercent
} from "../../../utils/format";
import {COLOR_ASSET_SHORTFALL_ACCENT, COLOR_EXCESS_ASSETS_ACCENT} from "../../../constants/colors";
import {ReviewAssetTableDisplayReport} from "./ReviewAssetTableDisplayReport";
import {GoalTableDisplayReport} from "./GoalTableDisplayReport";
import {FveDiscountRateType} from "../../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetTableDisplay} from "../../../ClientManagement/AssetReliance/TableDisplay/AssetTableDisplay";
import {AssetRelianceStack} from "../../../ClientManagement/models/AssetRelianceResponse";
import {hasPositiveExcessAssets} from "../../../ClientManagement/AssetReliance/AssetRelianceUtil";

export class ExcessAssetsTableDisplayReport extends TableDisplay<number | string> {
    private readonly assetTableDisplay: AssetTableDisplay | ReviewAssetTableDisplayReport;
    private readonly goalTableDisplay: GoalTableDisplayReport;
    private readonly allAssetsStack: AssetRelianceStack;
    private readonly showExpectedExcessAssets: boolean;
    private readonly includeLifeInsuranceAtDeath: boolean;
    private readonly fveDiscountRateType: FveDiscountRateType;
    private readonly investorGroup?: InvestorGroupType;
    private readonly excludedExcessAssetStacks: AssetRelianceStack[];

    constructor({
                    columnCounter,
                    assetTableDisplay,
                    goalTableDisplay,
                    allAssetsStack,
                    showExpectedExcessAssets,
                    includeLifeInsuranceAtDeath,
                    fveDiscountRateType,
                    investorGroup,
                    excludedExcessAssetStacks,
                }: {
        columnCounter: ColumnCounter,
        assetTableDisplay: AssetTableDisplay | ReviewAssetTableDisplayReport,
        goalTableDisplay: GoalTableDisplayReport,
        allAssetsStack: AssetRelianceStack,
        showExpectedExcessAssets: boolean,
        includeLifeInsuranceAtDeath: boolean,
        fveDiscountRateType: FveDiscountRateType,
        investorGroup?: InvestorGroupType,
        excludedExcessAssetStacks: AssetRelianceStack[],
    }) {
        super(columnCounter,
            (value) => formatCurrencyFve(value),
            (secondaryValue) => formatPercentWithWholeNumberAndTwoDecimalsDisplayPercent(secondaryValue)
        );

        this.assetTableDisplay = assetTableDisplay;
        this.goalTableDisplay = goalTableDisplay;
        this.allAssetsStack = allAssetsStack;
        this.excludedExcessAssetStacks = excludedExcessAssetStacks;
        this.showExpectedExcessAssets = showExpectedExcessAssets;
        this.includeLifeInsuranceAtDeath = includeLifeInsuranceAtDeath;
        this.fveDiscountRateType = fveDiscountRateType;
        this.investorGroup = investorGroup;
    }

    get rows(): TableRow<number | string>[] {
        const excessAssetsHeaderValues_ = Array(1).fill(0);
        return this.showExpectedExcessAssets ? excessAssetsHeaderValues_.map((): TableRow<number | string> => ({
            accentColor: hasPositiveExcessAssets(this.allAssetsStack.excessAssets) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT,
            uniqueIdentifier: "",
            label: ExcessAssetsTableDisplayReport.createLabel(this.investorGroup!),
            labelClassName: 'paddingtop-md',
            secondaryLabel: ExcessAssetsTableDisplayReport.createSecondaryLabel(this.fveDiscountRateType),
            subtitle: ExcessAssetsTableDisplayReport.createSubtitle(this.investorGroup!),
            values: this.createFutureValueOfExcessAssetsTableCells(this.allAssetsStack, this.excludedExcessAssetStacks, this.fveDiscountRateType, this.includeLifeInsuranceAtDeath),
            children: []
        })) : [];
    }

    protected get headerLabel(): string {
        return hasPositiveExcessAssets(this.allAssetsStack.excessAssets) ? "Excess Assets" : "Asset Shortfall";
    }

    protected get headerValues(): TableCell<number | string>[] {
        const assetHeaderValues_ = this.assetTableDisplay.header.values;
        const goalHeaderValues_ = this.goalTableDisplay.header.values;
        const excessAssetsHeaderValues_ = Array(this.columnCount).fill(0);

        if (assetHeaderValues_.length !== this.columnCount || goalHeaderValues_.length !== this.columnCount) {
            console.error(`Mismatched column counts: Excess Assets = ${this.columnCount}, Assets = ${assetHeaderValues_.length}, Goals = ${goalHeaderValues_.length}`);
            return [];
        }

        for (let i = 0, l = this.columnCount; i < l; i++) {
            excessAssetsHeaderValues_[i] = assetHeaderValues_[i].originalValue - goalHeaderValues_[i].originalValue;
        }
        return excessAssetsHeaderValues_.map(value => {
            const tableCellClassName_ = value < 0 ? 'negative-difference' : undefined;
            return this.createTableCell({value, className: tableCellClassName_});
        });
    }

    private static createLabel(investorGroup_: InvestorGroupType): string {
        const {ageFrom, numberOfYears} = investorGroup_.planningPeriod;
        return `Excess Assets at Age ${ageFrom + numberOfYears}`;
    }

    private static createSecondaryLabel(fveDiscountRateType_: FveDiscountRateType): string {
        switch (fveDiscountRateType_) {
            case FveDiscountRateType.TRIPLE_NET:
                return 'Triple Net Growth Rate'
            case FveDiscountRateType.NOMINAL_NET:
                return 'Nominal Net Growth Rate'
            default:
                return ''
        }
    }


    private static createSubtitle(investorGroup_: InvestorGroupType): string {
        const {numberOfYears} = investorGroup_.planningPeriod;
        return `${numberOfYears} year planning period`;
    }

    private static getFutureValueOfExcessAssets(fveDiscountRateType_: FveDiscountRateType, allAssetStacks_: AssetRelianceStack, includeLifeInsuranceAtDeath_: boolean) {
        const lifeInsuranceStr_ = includeLifeInsuranceAtDeath_ ? "includeLifeInsurance" : "noLifeInsurance";
        const val = {
            'includeLifeInsurance': {
                [FveDiscountRateType.TRIPLE_NET]: allAssetStacks_.fveWithLifeInsuranceDeathValueTNDR,
                [FveDiscountRateType.NOMINAL_NET]: allAssetStacks_.fveWithLifeInsuranceDeathValueNNDR,
            },
            'noLifeInsurance': {
                [FveDiscountRateType.TRIPLE_NET]: allAssetStacks_.futureValueOfExcessAssets,
                [FveDiscountRateType.NOMINAL_NET]: allAssetStacks_.futureValueOfExcessAssetsWithNominalNetDiscountRate,
            }
        }

        return val[lifeInsuranceStr_][fveDiscountRateType_];
    }

    private static getDiscountRate(fveDiscountRateType_: FveDiscountRateType, assetStacks_: AssetRelianceStack) {
        return fveDiscountRateType_ === FveDiscountRateType.TRIPLE_NET ? assetStacks_.displayedTripleNetDiscountRate : assetStacks_.displayedNominalNetDiscountRate;
    }

    private createFutureValueOfExcessAssetsTableCells(allAssetStacks_: AssetRelianceStack, otherAssetStacks_: AssetRelianceStack[], fveDiscountRateType_: FveDiscountRateType, includeLifeInsuranceAtDeath_: boolean): TableCell<number | string>[] {
        const tableCellClassName_ = undefined;

        const allAssetsTableCell_ = this.createTableCell({
            value: ExcessAssetsTableDisplayReport.getFutureValueOfExcessAssets(fveDiscountRateType_, allAssetStacks_, includeLifeInsuranceAtDeath_),
            secondaryValue: ExcessAssetsTableDisplayReport.getDiscountRate(fveDiscountRateType_, allAssetStacks_),
            className: tableCellClassName_
        });

        const excessAssetTableCells_: TableCell<number | string>[] = otherAssetStacks_.map((stack_) => {
            const fveValue_ = ExcessAssetsTableDisplayReport.getFutureValueOfExcessAssets(fveDiscountRateType_, stack_, includeLifeInsuranceAtDeath_);
            const growthRateValue_ = ExcessAssetsTableDisplayReport.getDiscountRate(fveDiscountRateType_, stack_);
            return this.createTableCell({
                value: fveValue_ < 0  || stack_.stackAssetType === "INVESTABLE_PORTFOLIO_ASSET" ? "N/A" : fveValue_,
                secondaryValue: fveValue_ < 0 || stack_.stackAssetType === "INVESTABLE_PORTFOLIO_ASSET" ? "" : growthRateValue_,
                className: tableCellClassName_
            });
        });
        excessAssetTableCells_.unshift(allAssetsTableCell_);
        return excessAssetTableCells_;
    }

}
