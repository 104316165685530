import React, {useMemo} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {Dropdown, DropdownItem} from "../../components";

type AddTrustButtonDropdownProps = {
    className: string,
}

const AddTrustButtonDropdown: React.FC<AddTrustButtonDropdownProps> = ({
                                                                           className
                                                                       }) => {

    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const dropdownItemArray = useMemo(() => {
        return [
            {
                itemText: "Revocable Trust",
                value: "Revocable Trust",
                onClick: () => {
                    history.push(`/Profile/${id}/ClientProfile/EstateFlowchart/new/RevocableTrust`);
                },
                disabled: false
            },
        ]
    }, [id])

    return <Dropdown
        className={className}
        alignRight
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Add Trust"
        iconNameOpen="add"
        iconNameClose="add"
        panelWidth={245}
        rounded
        disabled={isProfileWithProposalsOrArchived}
    >
        {dropdownItemArray.map((item: any, i: number) =>
            <DropdownItem key={i}
                          itemText={item.itemText}
                          value={item.value}
                          onClick={item.onClick}
                          disabled={item.disabled}
            />)}
    </Dropdown>;

};

export default AddTrustButtonDropdown;