import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {EstateFlowchartState} from "./EstateFlowchartTypes";

export type EstateFlowchartsState =  EstateFlowchartState[];

function initialEstateFlowchartState(): EstateFlowchartsState {
    return [];
}

const estateFlowchartSlice = createSlice({
    name: "estateFlowChart",
    initialState: initialEstateFlowchartState,
    reducers: {
        setEstateFlowchartState: (_state, action: PayloadAction<Array<EstateFlowchartState>>) => action.payload,
        resetEstateFlowchartState: () => ([]),
    }
});

export const getEstateFlowcharts = (state: RootState) => state.client.estateFlowcharts

export const {
    setEstateFlowchartState,
    resetEstateFlowchartState
} = estateFlowchartSlice.actions

export default estateFlowchartSlice.reducer;