import React, {useEffect, useState} from 'react';
import {ReactComponentLike} from 'prop-types';
import NavContext from './NavContext';
import {Accordion} from '../Accordion';
import {NavDrawerItem, NavDrawerSideMenuList} from '.';
import {HistoryProps, LinkProps, SubMenuProps} from '../../models/routeData/RouteParamTypes';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectApprovedProfileFromApi,
    selectClientDocuments,
    selectMyDocuments,
    setClientDocumentsResponse,
    setMyDocumentsResponse
} from "../../Resources/clientMeetingDocumentSlice";
import {
    getAdvisorDocumentsAdviceLibrarySubMenu,
    getAdvisorDocumentsPracticeDocumentsSubMenu,
    getClientMeetingDocumentsSubMenu
} from "../../ClientManagement/ClientProfile/ClientProfileLinks";
import {resourcesApiClient} from "../../Resources/ResourcesApiClient";
import {ClientMeetingDocumentsResponse} from "../../Resources/models/ClientDocuments";
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";
import {emptyClientMeetingDocumentsSubMenusData} from "../../Resources/ResourcesConstant";
import {selectResourceCode} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {MenuStructureResponse} from "../../Resources/models/AdviceLibrary";
import {
    selectAdviceLibrarySubMenus, selectPracticeDocumentsSubMenus,
    setAdviceLibraryMenu,
    setPracticeDocumentsMenu
} from "../../Resources/menuStructureSlice";

type NavDrawerItemGroupProps = {
    history?: HistoryProps,
    links: LinkProps[],
    LinkRenderer: ReactComponentLike | string,
    sideMenuRequired: boolean
}

export const NavDrawerItemGroup = ({
                                       history,
                                       links = [],
                                       LinkRenderer,
                                       sideMenuRequired
                                   }: NavDrawerItemGroupProps) => {
    const [sideMenus, setSideMenus] = useState<SubMenuProps[]>([]);
    const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(false);
    const [sideMenuPath, setSideMenuPath] = useState<string | undefined>('');
    const [sideMenuTitle, setSideMenuTitle] = useState<string>('');

    const msal = useMsal();
    const currentUserId = msalUtils.getLanId(msal);
    const dispatch = useAppDispatch();

    const clientDocumentsSubMenus = useAppSelector(selectClientDocuments);
    const myDocumentsSubMenus = useAppSelector(selectMyDocuments);
    const adviceLibrarySubMenus = useAppSelector(selectAdviceLibrarySubMenus);
    const practiceDocumentsSubMenus = useAppSelector(selectPracticeDocumentsSubMenus);
    const approvedProfileFromApi = useAppSelector(selectApprovedProfileFromApi);
    const resourceCode = useAppSelector(selectResourceCode);
    const [currentLinkId,setCurrentLinkId] = useState<string>()
    const onRefreshSharePointDocs = () => {

        const clientName = (approvedProfileFromApi.primaryContact.firstName).concat(" ", approvedProfileFromApi.primaryContact.lastName);

        resourcesApiClient.getClientMeetingDocuments(approvedProfileFromApi.id, clientName, currentUserId, resourceCode, "true").then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
            dispatch(setClientDocumentsResponse({...clientMeetingDocumentsResponse, isSharePointDown: false}));
        }).catch((_error) => {
            dispatch(setClientDocumentsResponse({...emptyClientMeetingDocumentsSubMenusData, isSharePointDown: true}));
        });

        resourcesApiClient.getMyDocuments(currentUserId, approvedProfileFromApi.id).then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
            dispatch(setMyDocumentsResponse({...clientMeetingDocumentsResponse, isSharePointDown: false}));
        }).catch((_error) => {
            dispatch(setMyDocumentsResponse({...emptyClientMeetingDocumentsSubMenusData, isSharePointDown: true}));
        });
    }

    const onRefreshSeismicDocs = () => {

        resourcesApiClient.getMenuStructure(true)
            .then((menuStructureResponse: MenuStructureResponse) => {
                dispatch(setAdviceLibraryMenu(menuStructureResponse.menu["AdviceLibrary"]));
                dispatch(setPracticeDocumentsMenu(menuStructureResponse.menu["PracticeDocuments"]));
            })
            .catch(console.error);
    }

    useEffect(() => {
        let subMenuOptions: SubMenuProps[] = [];
        if (sideMenuTitle === "Client Documents") {
            subMenuOptions = (clientDocumentsSubMenus.documentItems.length > 0 || clientDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(clientDocumentsSubMenus) : []
            setSideMenus(subMenuOptions);
        } else if ((sideMenuTitle === "My Documents")){
            subMenuOptions = (myDocumentsSubMenus.documentItems.length > 0 || myDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(myDocumentsSubMenus) : []
            setSideMenus(subMenuOptions);
        }
        else if ((sideMenuTitle === "Practice Documents")){
            subMenuOptions =  getAdvisorDocumentsPracticeDocumentsSubMenu(practiceDocumentsSubMenus)
            setSideMenus(subMenuOptions);
        }
        else if ((sideMenuTitle === "Advice Library")){
            subMenuOptions =  getAdvisorDocumentsAdviceLibrarySubMenu(adviceLibrarySubMenus)
            setSideMenus(subMenuOptions);
        }

    }, [clientDocumentsSubMenus, myDocumentsSubMenus,practiceDocumentsSubMenus,adviceLibrarySubMenus]);

    const toggleMenu = (link: LinkProps, path?: string) => {
        setSideMenuPath(path);
        if(currentLinkId === link.id){
            setIsOpenSideMenu(!isOpenSideMenu);
            setSideMenus(!isOpenSideMenu ? (link.subMenuOptions || []) : []);
        }
        else if(link.id === "AdviceLibrary" || link.id === 'PracticeDocuments' || link.id === 'ClientDocuments' || link.id === 'MyDocuments' ) {
            setIsOpenSideMenu(true);
            setSideMenus((link.subMenuOptions || []));
            setCurrentLinkId(link.id);
        } else {
            setIsOpenSideMenu(false);
            setSideMenus( []);
        }
        setSideMenuTitle(link.subMenuTitle || '');

    };

    return (
        <NavContext.Provider value={{toggleMenu}}>
            <Accordion allowMultipleExpanded={true}>
                {links.filter(link => !link['hidden'])?.map(link => (
                    <NavDrawerItem
                        key={link.id}
                        history={history}
                        link={link}
                        LinkRenderer={LinkRenderer}
                    />
                ))}
            </Accordion>

            {sideMenuRequired && (
                <NavDrawerSideMenuList
                    className="advice-library-menu"
                    isOpen={isOpenSideMenu}
                    items={sideMenus}
                    LinkRenderer={LinkRenderer}
                    location={`${sideMenuPath}`}
                    onRequestClose={() => setIsOpenSideMenu(false)}
                    title={sideMenuTitle}
                    onRefreshSharePointDocs={onRefreshSharePointDocs}
                    onRefreshSeismicDocs={onRefreshSeismicDocs}
                />
            )}
        </NavContext.Provider>
    )
}
