import React from 'react';
import classNames from 'classnames';
import {Accordion, AccordionItem} from '../Accordion';
import {ReactComponentLike} from "prop-types";
import {SubMenuProps} from "../../models/routeData/RouteParamTypes";
import {NavDrawerSideMenu} from ".";
import {useAppSelector} from "../../store/hooks";
import {selectClientDocuments, selectMyDocuments} from "../../Resources/clientMeetingDocumentSlice";
import {AccordionItemInfoWithNoDocuments} from "../Accordion/AccordionItemInfoWithNoDocuments";

type NavDrawerSideMenuListProps = {
    className?: string,
    items?: SubMenuProps[],
    LinkRenderer: ReactComponentLike | string,
    location: string,
    onRequestClose: () => void,
    isOpen?: boolean,
    title?: string,
    isSubmenuFolder?: boolean,
    isSingleAccordion?: boolean,
    item?: SubMenuProps,
    hRef?: string,
    onRefreshSharePointDocs: () => void,
    onRefreshSeismicDocs: () => void
    isSharePointDown?: boolean
}

export const NavDrawerSideMenuList = ({
                                          className,
                                          items = [],
                                          LinkRenderer = 'a',
                                          location,
                                          isOpen,
                                          title,
                                          onRequestClose,
                                          isSubmenuFolder = false,
                                          isSingleAccordion = true,
                                          onRefreshSharePointDocs,
                                          onRefreshSeismicDocs
                                      }: NavDrawerSideMenuListProps) => {

    const clientDocumentsSubMenus = useAppSelector(selectClientDocuments);
    const myDocumentsSubMenus = useAppSelector(selectMyDocuments);
    const hRef = title === "Client Documents" ? clientDocumentsSubMenus.webUrl : myDocumentsSubMenus.webUrl;


    const isSharePointDocs = (items?.length !== undefined && items?.length > 0 && (items[0].children.length > 0 || items[0].subMenuFolders!.length > 0))  && (title == "Client Documents" || title == "My Documents") ? true : false;


    return (
        isSubmenuFolder ? (<AccordionItemWrapper items={items} location={location} LinkRenderer={LinkRenderer}
                                                 onRequestClose={onRequestClose} isOpen={isOpen} isSingleAccordion={isSingleAccordion} hRef={hRef} onRefreshSharePointDocs={onRefreshSharePointDocs} onRefreshSeismicDocs= {onRefreshSeismicDocs} isSharePointDown={clientDocumentsSubMenus.isSharePointDown}/> ) : (
            <NavDrawerSideMenu className={classNames('nav-drawer-side-menu-list', className)} isOpen={isOpen}
                               title={title} onRequestClose={onRequestClose} isSharePointDocs={isSharePointDocs} hRef={hRef} onRefreshSharePointDocs={onRefreshSharePointDocs} onRefreshSeismicDocs={onRefreshSeismicDocs}>
                <AccordionItemWrapper items={items} location={location} LinkRenderer={LinkRenderer}
                                      onRequestClose={onRequestClose} isOpen={isOpen} isSingleAccordion={false} hRef={hRef} onRefreshSharePointDocs={onRefreshSharePointDocs}  onRefreshSeismicDocs= {onRefreshSeismicDocs}  title={title} isSharePointDown={clientDocumentsSubMenus.isSharePointDown}/>
            </NavDrawerSideMenu>)
    )
}

const AccordionItemWrapper = ({items, location, LinkRenderer, onRequestClose, isOpen, isSingleAccordion, hRef, onRefreshSharePointDocs, onRefreshSeismicDocs, title, isSharePointDown}: NavDrawerSideMenuListProps) => {


    return ((items && items.length > 0) ? <Accordion allowMultipleExpanded={true}>
            {items.map((item: SubMenuProps, index: number) => {
                const uuid = `${item.title}-${index}`;
                const itemTitle = item.title;

                return (
                    (item.title !=="" && item.title !== undefined) ? <AccordionItem
                        className="nav-drawer-side-menu-list__section"
                        primaryText={item.title}
                        uuid={uuid}
                        key={uuid}
                        isSingleAccordion={isSingleAccordion}
                    >

                        <AccordionItemInfo title={itemTitle} location={location} LinkRenderer={LinkRenderer} onRequestClose={onRequestClose} item={item} isOpen={isOpen} onRefreshSharePointDocs={onRefreshSharePointDocs} onRefreshSeismicDocs= {onRefreshSeismicDocs}/>

                        {item.subMenuFolders && item.subMenuFolders.length > 0 &&
                            <NavDrawerSideMenuList
                                className="advice-library-menu"
                                isOpen={isOpen}
                                items={item.subMenuFolders}
                                LinkRenderer={LinkRenderer}
                                location={location}
                                onRequestClose={onRequestClose}
                                title={""}
                                isSubmenuFolder={true}
                                isSingleAccordion={false}
                                onRefreshSharePointDocs={onRefreshSharePointDocs}
                                onRefreshSeismicDocs= {onRefreshSeismicDocs}
                            />}
                    </AccordionItem> :  <AccordionItemInfo key={uuid} title={itemTitle} location={location} LinkRenderer={LinkRenderer} onRequestClose={onRequestClose} item={item} isOpen={isOpen} onRefreshSharePointDocs={onRefreshSharePointDocs} onRefreshSeismicDocs= {onRefreshSeismicDocs}/>
                );
            })}
        </Accordion> : <AccordionItemInfoWithNoDocuments hRef={hRef} isReportSection={false} title={title} onRefreshSharePointDocs={onRefreshSharePointDocs} isSharePointDown={isSharePointDown}/>
    )
}

const AccordionItemInfo = ({title, location, LinkRenderer, onRequestClose, item, isOpen, onRefreshSharePointDocs, onRefreshSeismicDocs}: NavDrawerSideMenuListProps) => {

    const isClientMeetingDocuments = location.includes("ClientDocuments") || location.includes("MyDocuments");
    const pathURL =  location + "/" ;

    return (<> <ul className="nav-drawer-side-menu-list__items">
            {item!.children.map((child, childIndex) => {
                const fileName = child.label;
                const extension = isClientMeetingDocuments ? "."+(fileName.substring(fileName.lastIndexOf(".")+1, fileName.length)): "";

                return (
                    <li className="nav-drawer-side-menu-list__item" key={`${child}-${childIndex}`}>
                        <LinkRenderer
                            className="gray-link"
                            to={isClientMeetingDocuments ? (pathURL + `${child.link}${extension}/1`) : (pathURL + child.subMenuLabel + "/" + `${child.link}${extension}/1`)}
                            onClick={onRequestClose}
                        >
                            {child.label}
                        </LinkRenderer>
                    </li>
                )}
            )}
        </ul>

            {title === undefined && item!.subMenuFolders && item!.subMenuFolders.length > 0 &&
                <NavDrawerSideMenuList
                    className="advice-library-menu"
                    isOpen={isOpen}
                    items={item!.subMenuFolders}
                    LinkRenderer={LinkRenderer}
                    location={location}
                    onRequestClose={onRequestClose}
                    title={""}
                    isSubmenuFolder={true}
                    isSingleAccordion={false}
                    onRefreshSharePointDocs={onRefreshSharePointDocs}
                    onRefreshSeismicDocs= {onRefreshSeismicDocs}
                />}
        </>

    );
}
