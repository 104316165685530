import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import BarChartLegend, {barChartLegend} from "../../components/Legend/BarChartLegend";
import {
    Dropdown,
    DropdownItem,
    FixedColumnCounter
} from "../../components";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getPlanSummary, selectPlanSummary} from "../PlanSummary/planSummarySlice";
import {getRefWidth} from "../../ClientManagement/AssetReliance/AssetRelianceUtil";
import {ComparePlansBarchartContainer} from "./ComparePlansBarchartContainer";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS_ACCENT,
    COLOR_GOALS,

} from "../../constants/colors";
import {GoalsTableDisplayForComparePlan} from "./GoalsTableDisplayForComparePlan";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {NetAssetsTableDisplayForComparePlan} from "./NetAssetsDisplayTableForComparePlans";
import {ExcessAssetsDisplayTableForComparePlans} from "./ExcessAssetsDisplayTableForComparePlans";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {AccordionTableHeaderOnly} from "../../components/AccordionTable/AccordionTableHeaderOnly";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";

const ComparePlansContent: React.FC = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const profile = useAppSelector(selectProfile)!;
    const profileIdForProposal = useAppSelector(selectProfile)?.profileIdForProposal
    const {proposals} = useProfileAndProposals(id);
    const [barChartColWidth, setBarChartColWidth] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [planSummaryResponse, setPlanSummaryResponse] = useState<PlanSummaryResponse>();

    const chartLegend = <div>
        <div className="compare-plans-sub-header">Excess Assets</div>
        <div className="bar-chart-legend-container">
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.NET_ASSETS} label={"ASSETS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.GOALS} label={"GOALS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.EXCESS_ASSETS} label={"EXCESS ASSETS"}/>
            </div>
        </div>
    </div>;

    const fetchPlanSummary = async (fetchId: string) => {
        const response = await dispatch(getPlanSummary(fetchId));
        if (response.payload) {
            setPlanSummaryResponse(response.payload as PlanSummaryResponse);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const idToFetch = profileIdForProposal ?? profile.id;
        (async () => {
            await fetchPlanSummary(idToFetch);
        })();
    }, [id, profile.id, profileIdForProposal]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const netAssets = planSummaryResponse!.totalNetValue + planSummaryResponse!.totalAssetPurchaseValue;
    const totalGoals = planSummaryResponse!.goalsTotalPresentValue;
    const excessAssets = planSummaryResponse!.excessAssets;

    const goalsTableDisplay = new GoalsTableDisplayForComparePlan(
        new FixedColumnCounter(2),
        planSummaryResponse!.goalsTotalPresentValue
    );

    const netAssetsTableDisplay = new NetAssetsTableDisplayForComparePlan(
        new FixedColumnCounter(2),
        planSummaryResponse!.totalNetValue + planSummaryResponse!.totalAssetPurchaseValue
    );

    const excessAssetsTableDisplay = new ExcessAssetsDisplayTableForComparePlans(
        new FixedColumnCounter(2),
        planSummaryResponse!.excessAssets
    );

    return (
        <main className="compare-plans-content">
            <Row>
                <Col width={TITLE_CELL_WIDTH}>
                    {chartLegend}
                </Col>
                <span className="divider"></span>
                <Col md={CHART_TITLE_CELL_WIDTH}
                     ref={(col: Col & HTMLDivElement | null) => {
                         const width = getRefWidth(col);
                         console.log(width);
                         setBarChartColWidth(width);
                     }}
                >
                    <div className="compare-plans-sub-header sub-title">
                        <span>Profile (Current)</span>
                    </div>
                </Col>
                <span className="divider"></span>
                <Col md={CHART_TITLE_CELL_WIDTH}>
                    <Dropdown value={!isLoading ? profile.id : ''}
                              className="compare-plans-dropdown"
                              label="Select a plan"
                              inlineLabel
                              panelHeight="auto"
                              panelWidth="300px"
                              size='medium'
                              disabled={proposals.length === 0}>
                        {proposals.map(proposal => <DropdownItem key={proposal.id} value={proposal.id}
                                                                 itemText={proposal.displayName}
                                                                 style={{paddingLeft: '35px'}}
                        />)}
                    </Dropdown>
                </Col>
            </Row>
            <ComparePlansBarchartContainer netAssets={netAssets!}
                                           barChartColWidth={barChartColWidth}
                                           totalStacks={1}
                                           totalGoals={totalGoals}
                                           excessAssets={excessAssets}
            />
            <div className="margintop-xxxl">
                    <AccordionTableHeaderOnly accordionTableId={"compare-plans-assets"}
                                    collapsedAccentColor={COLOR_ASSETS_ACCOUNTS}
                                    ariaLabel={"Compare-Plans-Assets"}
                                    titleCellWidth={TITLE_CELL_WIDTH}
                                    tableDisplayData={netAssetsTableDisplay}
                                    valueColumnSize={4}

                    />

            </div>
            <div>
                    <AccordionTableHeaderOnly accordionTableId={"compare-plans-goals"}
                                    collapsedAccentColor={COLOR_GOALS}
                                    ariaLabel={"Compare-Plans-Goals"}
                                    titleCellWidth={TITLE_CELL_WIDTH}
                                    tableDisplayData={goalsTableDisplay}
                                    valueColumnSize={4}

                    />

            </div>
            <div>
                    <AccordionTableHeaderOnly accordionTableId={"compare-plans-excess-assets"}
                                    collapsedAccentColor={COLOR_EXCESS_ASSETS_ACCENT}
                                    ariaLabel={"compare-plans-excess-assets-row"}
                                    titleCellWidth={TITLE_CELL_WIDTH}
                                    tableDisplayData={excessAssetsTableDisplay}
                                    valueColumnSize={4}
                    />

            </div>
        </main>
    )
}

export default ComparePlansContent;
export const TITLE_CELL_WIDTH = 374;
export const CHART_CELL_WIDTH = 370;
export const CHART_TITLE_CELL_WIDTH = 4;