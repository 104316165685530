import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../components";
import {formatNumberRoundedToWholeNumber} from "../../utils/format";


export class GoalsTableDisplayForComparePlan extends TableDisplay<number, TableRow<number>> {
    private readonly goalsTotalPresentValue: number;

    constructor(columnCounter: ColumnCounter, goalsTotalPresentValue: number) {
        super(columnCounter);
        this.goalsTotalPresentValue = goalsTotalPresentValue;
    }

    protected get headerLabel(): string {
        return "Goals";
    }

    protected get headerValues(): TableCell<number>[] {
        return [{
            originalValue: this.goalsTotalPresentValue,
            renderedValue: formatNumberRoundedToWholeNumber(this.goalsTotalPresentValue),
            isBlank: false
        }];
    }

    get rows(): TableRow<number>[] {
        return [{
            accentColor: '',
            children: [],
            label: "Total Present Value",
            uniqueIdentifier: 'total-present-value',
            values: [{
                originalValue: this.goalsTotalPresentValue,
                renderedValue: this.goalsTotalPresentValue?.toString(),
                isBlank: false
            }]
        }];
    }
}