import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import AssetsViewContext from "../common/AssetsViewContext";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {useAppInsights} from "../../AppInsights";
import {RouteWithAssetId} from "../../routes/types";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {assetsApiClient} from "../AssetsApiClient";
import {HoldingAssetSubclassDetails} from "../models/Holding";
import {AssetClassifications} from "../models/AssetClassifications";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import HoldingsInfo from "../StandaloneAccount/Holdings/HoldingsInfo";
import {Icon} from "../../components";
import {HoldingsScrollableContainer} from "../StandaloneAccount/Holdings/HoldingsScrollableContainer";
import {HoldingSummaryAccordion} from "../StandaloneAccount/Holdings/HoldingSummaryAccordion";
import {HoldingSummaryAssetSubclass} from "../StandaloneAccount/Holdings/HoldingSummaryAssetSubclass";
import {BankingHoldingRequest} from "../models/BankingAccount";

import {
    IPAccountsHoldingSummarySubclassDetailsRow
} from "../InvestmentProgram/IPAccountsHoldingSummarySubclassDetailsRow";
import {BankingAccountsSummary} from "../models/BankingAccounts";
import useProfileEditableState from "../../hooks/useProfileEditableState";

export default function BankingAccountHoldings() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const appInsights = useAppInsights();
    const {assetId: bankingAccountId} = useParams<RouteWithAssetId>();
    const [bankingHoldings, setBankingHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [bankingAccountDetails, setBankingAccountDetails] = useState<BankingAccountsSummary>();
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        appInsights.startTrackPage("ViewNtBankAccountHoldings");
        Promise.all([
            assetsApiClient.getBankingAccountById(profile.id, bankingAccountId),
            assetsApiClient.getBankingAccountHoldings(profile.id, bankingAccountId),
            assetsApiClient.getAssetClassifications(),
            clientManagementApiClient.getInvestorGroup(profile.id)
        ]).then(([accountDetails, bankingAccountHoldings, assetClassificationResponse, investorGroupResponse]) => {
            setBankingAccountDetails(accountDetails);
            setBankingHoldings(bankingAccountHoldings.allHoldings);
            setClassifications(assetClassificationResponse);
            setInvestorGroup(investorGroupResponse);
        })
            .catch(reason => {
                console.log(reason);
            })
            .finally(() => {
                appInsights.stopTrackPage("ViewBankingHoldings", window.location.href, {
                    profileId: profile.id,
                    bankingAccountId: bankingAccountId
                });
            });

    }, [profile.id, bankingAccountId]);

    const handleLockHolding = (index: number, checked: boolean) => {
        bankingHoldings[index] = {...bankingHoldings[index], locked: checked};
        setBankingHoldings(bankingHoldings);
    }

    const handleConcentrateHolding = (index: number, checked: boolean) => {
        bankingHoldings[index] = {...bankingHoldings[index], concentrated: checked};
        setBankingHoldings([...bankingHoldings]);
    }

    const handleSaveHoldings = () => {
        const holdingRequest: BankingHoldingRequest = {
            allHoldings: bankingHoldings
        }
        assetsApiClient.putBankingAccountHoldings(profile.id, bankingAccountId, holdingRequest).then(response => {
            if (response.status === 200) {
                history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/EditBankingAccount/${bankingAccountId}`);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    if (!bankingAccountDetails || !bankingHoldings || !classifications || !investorGroup) {
        return <LoadingIndicator/>
    }

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;

    const assetSubclassDetailsRowRenderer = (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) =>
        <IPAccountsHoldingSummarySubclassDetailsRow
            key={`asset-subclass-details-${assetSubclassDetail.id}-${assetSubclassDetail.productName}`}
            index={index}
            assetSubclassDetail={assetSubclassDetail}
            handleLockHolding={handleLockHolding}
            handleConcentrateHolding={handleConcentrateHolding}
            disableCheckboxes={isProfileWithProposalsOrArchived!}
        />;

    return (
        <div className="held-away-holdings asset-form holdings-container">
            <div className="layout-data-entry-form">
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={`${profile.primaryContact.firstName}_${bankingAccountDetails?.accountTypeDesc}`}
                    onPrimaryButtonClick={handleSaveHoldings}
                    disablePrimaryButton={isProfileWithProposalsOrArchived}
                    primaryButtonText="Done"
                    hideSecondaryButton={true}
                />
            </div>
            <HoldingsInfo
                account={{
                    institution: bankingAccountDetails?.institution,
                    accountNumber: bankingAccountDetails?.accountNumber,
                    taxStatus: bankingAccountDetails?.taxStatus || 'Taxable',
                    memberOwnerships: bankingAccountDetails?.memberOwnerships || [],
                    legalEntityOwnerships: bankingAccountDetails?.legalEntityOwnerships || [],
                    ownershipCategory: bankingAccountDetails?.ownershipCategory || "Sole"
                }}
                primaryMember={primaryMember}
                partnerMember={partnerMember}
                allHoldings={bankingHoldings}
                classifications={classifications}
            />
            <div className="holding-grid holding-grid-container">
                <div className="grid-display-contents" role="row">
                        <span className="display-flex align-items-center paddingbottom-md"
                              role="cell">
                            <Icon name="chevron_double_right"/>
                            <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                        </span>
                    <span className="condensed-subtitle" role="cell">Product</span>
                    <span className="textalign-right condensed-subtitle" role="cell">Account</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Tax Cost</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Market Value</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Investable Value</span>
                    <span className="condensed-subtitle textalign-center" role="cell">Locked</span>
                    <span className="condensed-subtitle textalign-left" role="cell">Concen</span>
                </div>
                <div className="empty-grid-row"/>
                <HoldingsScrollableContainer>
                    <HoldingSummaryAccordion
                        riskAssetClasses={classifications?.riskAssetClasses}
                        riskControlAssetClasses={classifications?.riskControlAssetClasses}
                        assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                            return <HoldingSummaryAssetSubclass
                                key={subclassIndex}
                                assetSubclass={assetSubclass}
                                holdingsWithIndex={holdingsWithIndex}
                                subclassIndex={subclassIndex}
                                assetSubclassDetailsRowRenderer={assetSubclassDetailsRowRenderer}/>
                        }}
                        selectedHoldings={bankingHoldings}
                    />
                </HoldingsScrollableContainer>
            </div>
        </div>
    )
}

