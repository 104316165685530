import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {
    EmptyStateContainer,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    StateOfResidencyInput,
    UnderlinedHeader
} from "../components";
import {DataEntrySummaryItem} from "../components/DataEntry/DataEntrySummary";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import {EstimatedImpact, FailedAPICall, StateInputDto, StateOfResidencyStrategy} from "./WealthPOTypes";
import {useAppSelector} from "../store/hooks";
import {selectStateOfResidencyEstimatedImpact, selectStateOfResidencyStrategy,} from "./WealthPlanOptimizerSlice";
import {formatCurrency} from "../utils/format";
import AlertBanner from "../components/AlertBanner/AlertBanner";
import {
    getStateAbbreviationFromSelectedStateValue,
    getStateNameForStateOfResidencyId,
    getStateNamesForStateOfResidencyDropdown,
} from "./WealthPOUtils";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import useProfileEditableState from "../hooks/useProfileEditableState";
import {selectProfile} from "../ClientManagement/ClientProfile/activeProfileSlice";
import {HandleSaveResponse, StrategyHeader} from "./components/StrategyHeader";
import {StrategySidebar} from "./components/StrategySidebar";

type ValidationErrors = {
    description: string | undefined,
    selectedState: string | undefined
};

export interface AddEditStateOfResidencyProps {
    header: string,
    refreshStrategiesSummary: () => void,
}

const AddEditStateOfResidency: React.FC<AddEditStateOfResidencyProps> = ({
                                                                             header,
                                                                             refreshStrategiesSummary
                                                                         }: AddEditStateOfResidencyProps) => {

    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const initialEstimatedImpact = useAppSelector<EstimatedImpact>(selectStateOfResidencyEstimatedImpact);
    const stateOfResidencyStrategy = useAppSelector<StateOfResidencyStrategy>(selectStateOfResidencyStrategy);
    const savedProfileSOR = useAppSelector(selectProfile).primaryContact.stateAbbr;

    const [stateOfResidencyDropdownInputInitialValue, setStateOfResidencyDropdownInputInitialValue] = useState<string | undefined>(undefined);
    const [listOfStates, setListOfStates] = useState([] as StateInputDto[]);
    const [description, setDescription] = useState(stateOfResidencyStrategy.description);
    const [selectedState, setSelectedState] = useState<string | undefined>(undefined);
    const [estimatedImpact, setEstimatedImpact] = useState<EstimatedImpact>(initialEstimatedImpact);
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
        description: undefined,
        selectedState: undefined
    })
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);
    const [failedApiCall, setFailedApiCall] = useState<FailedAPICall>();

    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined)

    const setPrepopulatedDropdownValue = (stateInputDTOs: StateInputDto[], selectedStateOfResidency: string) => {
        const dropdownDefaultSelectedState = getStateNameForStateOfResidencyId(
            stateInputDTOs,
            selectedStateOfResidency);

        if (dropdownDefaultSelectedState) {
            setStateOfResidencyDropdownInputInitialValue(dropdownDefaultSelectedState);
            setSelectedState(dropdownDefaultSelectedState);
        }
    }

    const fetchReferenceData = () => {
        wealthPOApiClient.getStateEstateTransferRatesReferenceData(id)
            .then((stateEstateTransferRates) => {
                setListOfStates(stateEstateTransferRates.stateInputsDTOs);

                setPrepopulatedDropdownValue(
                    stateEstateTransferRates.stateInputsDTOs,
                    stateOfResidencyStrategy.selectedStateOfResidency
                )
            })
            .catch(err => {
                openErrorModal(err, FailedAPICall.REFERENCE_DATA)
            });
    }

    const fetchEstimatedImpact = () => {
        if (selectedState) {
            const selectedStateName = selectedState.split(" (")[0];
            const selectedStateDTO = listOfStates.find((stateInputDto) => stateInputDto.name === selectedStateName);
            const selectedStateOfResidency = selectedStateDTO?.id.split("-")[1];

            if (selectedStateOfResidency) {
                estimatedImpactAbortControllerRef.current = new AbortController();
                const {signal} = estimatedImpactAbortControllerRef.current;

                wealthPOApiClient.getEstimatedImpactForSOR(id, selectedStateOfResidency, signal)
                    .then((estimatedImpactResponse) => {
                        setEstimatedImpact({
                            impactToEstEstateTax: estimatedImpactResponse.impactToEstEstateTax,
                            amountToBeneficiaries: undefined
                        });

                        setPrepopulatedDropdownValue(
                            listOfStates,
                            selectedStateOfResidency
                        )
                    })
                    .catch((err) => {
                        openErrorModal(err, FailedAPICall.ESTIMATED_IMPACT);
                    });
            }
        }
    }

    const openErrorModal = (error: Error | any, failedAPI: FailedAPICall) => {
        setFailedApiCall(failedAPI);

        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load ${failedAPI} (${error.status})`,
            operationId: error.headers.get('trace-id')
        });
    };

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();

        if (failedApiCall === FailedAPICall.REFERENCE_DATA) {
            fetchReferenceData();
        } else if (failedApiCall === FailedAPICall.ESTIMATED_IMPACT) {
            estimatedImpactAbortControllerRef.current = undefined;
            fetchEstimatedImpact();
        }
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    const validate = (): boolean => {
        let isValid = true;

        let newValidationErrors: ValidationErrors = {
            description: undefined,
            selectedState: undefined
        };

        if (description === undefined || description.trim() === "") {
            newValidationErrors.description = "Description is required."
            isValid = false;
        }

        if (!selectedState) {
            newValidationErrors.selectedState = "State of Residency is required."
            isValid = false;
        }

        setValidationErrors(newValidationErrors);
        return isValid;
    }

    const handleSave = async (): Promise<HandleSaveResponse> => {
        const valid = validate();

        let response = {
            isSuccess: valid,
            errors: {
                hasValidationError: false,
                hasCommunicationError: false,
                traceId: ""
            }
        } as HandleSaveResponse

        if (!valid) {
            response.isSuccess = false
            response.errors.hasValidationError = true
            return response;
        } else {
            const selectedStateOfResidency = getStateAbbreviationFromSelectedStateValue(listOfStates, selectedState!);
            let requestBody = {
                description,
                selectedStateOfResidency
            } as StateOfResidencyStrategy;

            try {
                if (strategyId) {
                    requestBody = {
                        ...requestBody,
                        id: strategyId,
                    };

                    await wealthPOApiClient.editStateOfResidencyStrategy(id, requestBody)
                } else {
                    await wealthPOApiClient.saveStateOfResidencyStrategy(id, requestBody)
                }
            } catch (saveError: Error | any) {
                response.isSuccess = false;
                response.errors.hasCommunicationError = true;
                response.errors.traceId = saveError.headers.get('trace-id');
            }
        }
        return response;
    };

    const handleCancel = () => {
        return description !== stateOfResidencyStrategy.description
            || getStateAbbreviationFromSelectedStateValue(listOfStates, selectedState) !== stateOfResidencyStrategy.selectedStateOfResidency;
    }

    const estimatedImpactSummary: DataEntrySummaryItem[] = useMemo(() => {
        return [
            {
                label: "Amount to Beneficiaries",
                value: "",
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "impactToEstEstateTax",
            } as DataEntrySummaryItem,
        ]
    }, [estimatedImpact]);

    useEffect(() => {
        fetchReferenceData();
    }, [id]);

    useEffect(() => {
        estimatedImpactAbortControllerRef.current = undefined;

        fetchEstimatedImpact();

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [id, selectedState]);

    const memoizedStateOfResidencyInput = useMemo(() => {
        return <StateOfResidencyInput className="layout-data-entry-form__field"
                                      listOfStates={getStateNamesForStateOfResidencyDropdown(listOfStates)}
                                      onSelected={event => setSelectedState(event.itemText)}
                                      error={validationErrors.selectedState}
                                      required={true}
                                      value={stateOfResidencyDropdownInputInitialValue}
        />;
    }, [listOfStates, stateOfResidencyDropdownInputInitialValue, validationErrors.selectedState]);

    if (genericError.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        );
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        );
    }

    return (
        <ScrollableContainer id={"add-edit-residency-state-scroll-container"} className="wealth-plan-optimizer">
            <StrategyHeader
                header={header}
                handleSave={handleSave}
                handleCancel={handleCancel}
                refreshStrategySummary={refreshStrategiesSummary}
            />

            {<div className="__alert-banner-container">
                <AlertBanner showAlert={!savedProfileSOR}
                             className="marginbottom-lg"
                             type={"warning"}
                             detail={<span><b>No State of Residency in Profile.</b> Please update State of Residency in Family Tree to accurately depict strategy impact.</span>}>
                </AlertBanner>
            </div>}

            <div className="wealthpo-strategy__form">
                <article>
                    <UnderlinedHeader
                        className={"wealthpo-strategy__underlined-header"}
                        primaryText="Strategy Details"
                        rightAlignedContent={<RequiredFieldsSubheader/>}
                    />
                    <div className="layout-data-entry-form__field">
                        <label data-testid={"descriptionLabel"} className={"h5"}>Description<RedAsterisk/></label>
                        <Input
                            name="descriptionField"
                            aria-label="description"
                            aria-labelledby="descriptionFieldInput-label"
                            id="descriptionFieldInput"
                            removeMarginTop
                            size="medium"
                            type="text"
                            value={description}
                            disabled={false}
                            readOnly={isProfileWithProposalsOrArchived}
                            error={validationErrors.description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                        />
                    </div>
                    {memoizedStateOfResidencyInput}
                </article>
                <StrategySidebar
                    strategySummary={[]}
                    estimatedImpact={estimatedImpactSummary}
                />
            </div>
        </ScrollableContainer>
    )
}

export default AddEditStateOfResidency;